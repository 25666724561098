import React, { useContext, useEffect, useState, useRef } from "react";
import { observer } from "mobx-react";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import {
  MenuItem,
  Button,
  Divider,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  makeStyles,
  Typography,
  Hidden,
  Box
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import RootStoreContext from "../../../contexts/RootStoreContext";
import { useTranslation } from "react-i18next";
import WorkgroupSelect from "./WorkgroupSelect";

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0
  },
  button: {
    [theme.breakpoints.up("sm")]: {
      minWidth: 150
    },
    color: "#a3a3a3",
    "& span": {
      flexGrow: 1
    }
  },
  dropDown: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1.5),
    minWidth: 250
  },
  subMenuTitle: {
    padding: theme.spacing(1)
  },
  buttonIcon: {
    flexGrow: 1
  }
}));

const UserDropdown: React.FC = () => {
  const classes = useStyles();
  const { authStore, userStore } = useContext(RootStoreContext);

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const [userName, setUserName] = useState("");
  const [t] = useTranslation();

  useEffect(() => {
    let active = true;
    (async () => {
      if (authStore.isInitialised) {
        try {
          const userResponse = await userStore.getActiveUser();
          if (active && userResponse && userResponse.name) {
            if (
              userResponse.currentWorkgroup &&
              userResponse.currentWorkgroup.name
            ) {
              setUserName(userResponse.currentWorkgroup.name);
            } else {
              setUserName(userResponse.name);
            }
          }
        } catch (err) {
          console.error(err.message);
          setUserName(t("nav.unknownUser"));
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [userStore, authStore.isInitialised, t]);

  const handleToggle = () => {
    if (authStore.isLoggedIn) {
      setOpen(prevOpen => !prevOpen);
    } else {
      authStore.login();
    }
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const startIcon = authStore.isLoggedIn ? <PersonOutlineIcon /> : null;
  const endIcon = authStore.isLoggedIn ? <ArrowDropDownIcon /> : null;

  return (
    <div className={classes.root}>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        classes={{ root: classes.button, label: classes.buttonIcon }}
        startIcon={startIcon}
        endIcon={endIcon}
      >
        <Hidden xsDown>{authStore.isLoggedIn ? userName : "Sign In"}</Hidden>
      </Button>

      {authStore.isLoggedIn && (
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "center top" : "center bottom"
              }}
            >
              <Paper className={classes.dropDown}>
                <ClickAwayListener onClickAway={handleClose}>
                  <Box>
                    <Paper className={classes.subMenuTitle} elevation={0}>
                      <Typography variant="h6">
                        {t("nav.workgroupSelect")}
                      </Typography>
                    </Paper>
                    <Divider />
                    <WorkgroupSelect closer={() => setOpen(false)} />
                    <Divider />
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem onClick={authStore.logOut}>
                        {t("nav.signout")}
                      </MenuItem>
                    </MenuList>
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </div>
  );
};

export default observer(UserDropdown);
