import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { LandingCardComponentProp } from "../../../types";
import LandingCardIcon from "./LandingCardIcon";
import LandingCardLink from "./LandingCardLink";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up("xs")]: {
      height: "320px"
    },
    width: "255px",
    boxShadow: "none",
    backgroundColor: "transparent"
  },
  name: {
    fontSize: "18px",
    color: "#4F5A67",
    fontWeight: 900
  },
  intro: {
    fontSize: "13px",
    lineHeight: "20px",
    color: "#69788E",
    fontWeight: 300
  }
}));

const LandingCard: React.FC<{
  landingCardProp: LandingCardComponentProp;
  overrideStyle?: any;
}> = ({ landingCardProp, overrideStyle }) => {
  const classes = useStyles();
  const style = overrideStyle ? { ...classes, ...overrideStyle } : classes;
  return (
    <Card raised className={style.root}>
      <CardContent className={style.content}>
        <LandingCardIcon iconKey={landingCardProp.key} />
        <Typography variant="h2" gutterBottom className={style.name}>
          {landingCardProp.name}
        </Typography>
        <Typography className={style.intro}>{landingCardProp.intro}</Typography>
      </CardContent>
      <LandingCardLink
        link={{
          cardKey: landingCardProp.key,
          linkName: landingCardProp.link,
          overrideStyle: overrideStyle
        }}
      />
    </Card>
  );
};
export default LandingCard;
