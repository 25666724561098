import React from "react";
import LandingBanner from "../components/landing/banner/LandingBanner";
import { useTranslation } from "react-i18next";
import ApiContainer from "../components/landing/ApiContainer";
import DragenContainer from "../components/landing/DragenContainer";
import ResourceContainer from "../components/landing/ResourceContainer";
import Footer from "../components/landing/Footer";
import { ContainerComponentProp } from "../types";

const LandingView: React.FC = () => {
  const [t] = useTranslation();
  const containers: ContainerComponentProp[] = t("landing.containers", {
    returnObjects: true
  });
  const readyToRender =
    Array.isArray(containers) && containers[0] && containers.length === 3;
  if (!readyToRender) return null;

  let containerList = readyToRender && (
    <>
      <ApiContainer container={containers[0]} />
      <DragenContainer container={containers[1]} />
      <ResourceContainer container={containers[2]} />
    </>
  );

  return (
    <>
      <LandingBanner />
      {containerList}
      <Footer />
    </>
  );
};

export default LandingView;
