import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    display: "flex",
    minHeight: "70vh",
    justifyContent: "center",
    alignItems: "center"
  },
  inner: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center"
  }
});

/**
 * RouteErrorBoundary is a generic fallback UI for unhandled
 * errors thrown by page routes
 */
const RouteErrorBoundary: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  return (
    <>
      <Grid container className={classes.root}>
        <Grid item className={classes.inner}>
          <ErrorOutlineIcon color="primary" />
          <Typography variant="h2">
            {t("errors.general.somethingWrong")}
          </Typography>
          <Typography variant="body2">
            {t("errors.general.tryAgain")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default RouteErrorBoundary;
