import prettyBytes from "pretty-bytes";
import i18next from "i18next";
import { saveAs } from "file-saver";

import { productType } from "../types";
import { ProductUsage, PeriodUsageSummary } from "@stratus/console";
import { storageTypes, computeTypes, transferTypes } from "../constants";

type transform = (n: number) => string | number;

/**
 * usageDataGetter extracts and formats usage for
 * a specific product usage
 * @param usages
 * @param type – type of product used, e.g. storage / compute
 * @param subtype – more specific product classification, e.g. FPGA
 * @param transform - optional function to format the resulting value
 * @param icredits - return billing amount rather than usage
 */
export const usageDataGetter = (
  usages: ProductUsage[] | undefined,
  type: productType,
  subtype: string,
  transform?: transform,
  icredits: boolean = false
) => {
  if (!usages || usages === []) {
    return transformValue(0, type, transform);
  }
  const used = usages.filter(product => {
    let parts = product.type.split(".");
    parts = [parts[1], parts.slice(2).join(".")];
    return parts[0] === type && parts[1] === subtype;
  });
  if (used.length) {
    if (!icredits) {
      return transformValue(used[0].amount, type, transform);
    } else {
      return transformValue(
        used[0].iCredit || 0,
        type,
        transform || icreditTransform
      );
    }
  }
  return 0;
};

export const usageTotalGetter = (
  usages: ProductUsage[] | undefined,
  type: productType,
  icredits: boolean = false
) => {
  if (!usages || usages === []) {
    return transformValue(0, type);
  }
  const values = usages.filter(product => product.type.split(".")[1] === type);
  let total = 0;
  if (icredits) {
    total = values.reduce((total, usage) => total + (usage.iCredit || 0), 0);
    return icreditTransform(total);
  } else {
    total = values.reduce((total, usage) => total + usage.amount, 0);
    return transformValue(total, type);
  }
};

const transformValue = (
  value: number,
  product: productType,
  transform?: transform
) => {
  if (transform) {
    return transform(value);
  }
  switch (product) {
    case "Storage":
      return storageTransform(value);
    case "Transfer":
      return storageTransform(value);
    case "Compute":
      return computeTransform(value);
    default:
      return value;
  }
};

const icreditTransform = (icredits: number, round: boolean = false) => {
  const formatter = new Intl.NumberFormat();
  if (round) {
    return formatter.format(Math.round(icredits * 10) / 10);
  } else {
    return formatter.format(icredits);
  }
};

const storageTransform = (storageBytes: number) => {
  return prettyBytes(storageBytes, { locale: true });
};

export const computeTransform = (computeMins: number, memory?: boolean) => {
  const hours = computeMinsToHours(computeMins);
  if (memory) {
    return `${hours} GB ${i18next.t("usage.units.hours", { count: hours })}`;
  }
  return `${hours} ${i18next.t("usage.units.hours", { count: hours })}`;
};

/** convert a period to a CSV string */
export const usagePeriodToCSV = (
  period: PeriodUsageSummary,
  type: productType
): string => {
  const CSVError = new Error("failed creating CSV for this period!");

  if (!period.userAggregatedUsages) {
    throw CSVError;
  }

  // set up CSV header row
  let productHeaders: string[] = [];
  if (type === "Storage" || type === "Transfer") {
    storageTypes.forEach(t =>
      productHeaders.push(`storage_${t.toLowerCase()}_bytes`)
    );
    transferTypes.forEach(t =>
      productHeaders.push(`transfer_${t.toLowerCase()}_bytes`)
    );
  }
  if (type === "Compute") {
    computeTypes.forEach(t =>
      productHeaders.push(`compute_${t.toLowerCase()}_mins`)
    );
  }
  const headers = [
    "period_start",
    "period_end",
    "user_name",
    ...productHeaders
  ];

  const userRows: string[][] = [headers];

  // iterate over users + pull in their usage
  period.userAggregatedUsages.forEach(user => {
    const name = (user.user && user.user.fullName) || "";

    let usageData: string[] = [];

    if (user.usages) {
      if (type === "Storage" || type === "Transfer") {
        storageTypes.forEach(storage =>
          usageData.push(usageDataGetter(user.usages!, "Storage", storage, s =>
            s.toString()
          ) as string)
        );
        transferTypes.forEach(transfer =>
          usageData.push(usageDataGetter(
            user.usages!,
            "Transfer",
            transfer,
            s => s.toString()
          ) as string)
        );
      }

      if (type === "Compute") {
        computeTypes.forEach(compute =>
          usageData.push(usageDataGetter(user.usages!, "Compute", compute, s =>
            s.toString()
          ) as string)
        );
      }
    }

    const row = ["PeriodName", name, ...usageData];
    if (row.length !== headers.length) {
      throw CSVError;
    }

    userRows.push(row);
  });

  const csv = userRows.map(row => row.join(",")).join("\n");
  return csv;
};

const computeMinsToHours = (mins: number) =>
  Math.round(((mins / 60) * 100) / 100);

/**
 * Downloads a given data string as a CSV with a supplied filename
 */
export const csvDownload = (data: string, filename: string) => {
  const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
  saveAs(blob, filename);
};
