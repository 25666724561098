import React, { useContext } from "react";
import NavDrawer from "../nav/NavDrawer";
import ContentContainer from "./ContentContainer";
import RootStoreContext from "../../contexts/RootStoreContext";
import { observer } from "mobx-react";
import PublicContainer from "./PublicContainer";
import { Hidden } from "@material-ui/core";

/**
 * PageLayout is a smart, it will display either logged-in layout or the public layout depending
 * on whether you have logged in
 * @param param0
 */

const WithDrawerLayout: React.FC = ({ children }) => {
  return (
    <>
      <NavDrawer />
      <ContentContainer>{children}</ContentContainer>
    </>
  );
};
const NoDrawerLayout: React.FC = ({ children }) => {
  return (
    <>
      <Hidden smUp>
        <div style={{ position: "absolute" }}>
          <NavDrawer />
        </div>
      </Hidden>
      <PublicContainer>{children}</PublicContainer>
    </>
  );
};

const PageLayout: React.FC = ({ children }) => {
  const { authStore, uiStore } = useContext(RootStoreContext);

  //drawer if loggedin and (not mobile || not on root)
  return (
    <>
      {authStore.isLoggedIn && uiStore.currentRoute !== "/" ? (
        <WithDrawerLayout>{children}</WithDrawerLayout>
      ) : (
        <NoDrawerLayout>{children}</NoDrawerLayout>
      )}
    </>
  );
};

export default observer(PageLayout);
