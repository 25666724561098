import TokenView from "./views/TokenView";
import TaskView from "./views/TaskView";
import UsageView from "./views/UsageView";
import HealthView from "./views/HealthView";
import DeviceView from "./views/DeviceView";

import { API_DOCS } from "./constants";
import WorkflowView from "./views/WorkflowView";
import DashboardView from "./views/DashboardView";

// sub-headers in nav drawer for a route collection
type routeGroup = "Apps" | "Reference";

export interface route {
  path: string;
  component: React.FunctionComponent;
  ref: pageRef;
  protected: boolean;
  displayInNav: boolean;
}

type routeList = { [key in routeGroup]: route[] };

/** internal ref to identify a page route */

// export type pageRef = 'token' | 'usage' | 'health' | 'docs'
export enum pageRef {
  landing = "",
  dashboard = "dashboard",
  token = "token",
  usage = "usage",
  workflows = "workflows",
  tasks = "tasks",
  device = "device",
  health = "health",
  docs = "docs"
}

// Routes added to this array are rendered in the
// nav drawer and used with react-router
const Routes: routeList = {
  Apps: [
    {
      path: "/dashboard",
      component: DashboardView,
      ref: pageRef.dashboard,
      protected: true,
      displayInNav: true
    },
    {
      path: "/tokens",
      component: TokenView,
      ref: pageRef.token,
      protected: true,
      displayInNav: true
    },
    {
      path: "/workflows",
      component: WorkflowView,
      ref: pageRef.workflows,
      protected: true,
      displayInNav: true
    },
    {
      path: "/tasks",
      component: TaskView,
      ref: pageRef.tasks,
      protected: true,
      displayInNav: true
    },
    {
      path: "/usage",
      component: UsageView,
      ref: pageRef.usage,
      protected: true,
      displayInNav: true
    },
    {
      path: "/oauth/device",
      component: DeviceView,
      ref: pageRef.device,
      protected: true,
      displayInNav: false
    }
  ],
  Reference: [
    {
      path: "/health",
      component: HealthView,
      ref: pageRef.health,
      protected: false,
      displayInNav: true
    },
    {
      path: "/docs",
      component: () => {
        // window.location.replace(API_DOCS);
        window.open(API_DOCS);
        return null;
      },
      ref: pageRef.docs,
      protected: false,
      displayInNav: true
    }
  ]
};

export default Routes;

const allRoutes: route[] = [];
Object.keys(Routes).forEach(key =>
  allRoutes.push(...((Routes as any)[key] as route[]))
);

// const dynamicRoutes: route[] = [{

// }]

export { allRoutes };
