import React from "react";
import { observer } from "mobx-react";
import { TaskRunSummary } from "@stratus/tes";

import TaskRunsTable from "./TaskRunTable";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import TaskRunFiltersContainer from "./filters/TaskRunFiltersContainer";

interface TaskGridProps {
  taskRuns: TaskRunSummary[];
}

const TaskGrid: React.FC<TaskGridProps> = ({ taskRuns }) => {
  const [t] = useTranslation();
  return (
    <Paper>
      <Typography variant="body2">{t("tasks.intro")}</Typography>
      <TaskRunFiltersContainer />
      <TaskRunsTable taskRuns={taskRuns} />
    </Paper>
  );
};

export default observer(TaskGrid);
