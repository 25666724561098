import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslation } from "react-i18next";

import WorkflowRunDetailsList from "./WorkflowRunDetailsList";
import WorkflowVersionDetailsList from "./WorkflowVersionDetailsList";
import { WorkflowRun } from "@stratus/wes";

interface WorkflowRunMetadataPanelProps {
  workflowRun: WorkflowRun;
}

/**
 * WorkflowRunMetadataPanel renders an expandable panel
 * of workflow metadata including details of the workflow
 * run and workflow version.
 */
const WorkflowRunMetadataPanel: React.FC<WorkflowRunMetadataPanelProps> = ({
  workflowRun
}) => {
  const [t] = useTranslation();
  return (
    <ExpansionPanel defaultExpanded={true}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="workflow-metadata-content"
        id="workflow-metadata-header"
      >
        <Typography variant="h3">{t("workflows.details.metadata")}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container>
          <Grid item xs={12} md={6}>
            <WorkflowRunDetailsList workflowRun={workflowRun} />
          </Grid>
          {workflowRun.workflowVersion && (
            <Grid item xs={12} md={6}>
              <WorkflowVersionDetailsList
                workflowVersion={workflowRun.workflowVersion}
              />
            </Grid>
          )}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default WorkflowRunMetadataPanel;
