import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";

import { WorkflowRunStatus } from "@stratus/wes";

interface SelectOption {
  label: string;
  value: string;
}

interface TaskRunFilterFormProps {
  nameSetter: React.Dispatch<React.SetStateAction<string | undefined>>;
  currentNameFilter?: string;
  statusSetter: React.Dispatch<React.SetStateAction<WorkflowRunStatus[]>>;
  currentStatusFilter?: WorkflowRunStatus[];
  onSubmit: () => void;
}

/**
 * TaskRunFilterForm builds a set of filters which can be
 * applied to a listing of task runs.
 */
const TaskRunFilterForm: React.FC<TaskRunFilterFormProps> = ({
  nameSetter,
  statusSetter,
  onSubmit,
  currentNameFilter,
  currentStatusFilter
}) => {
  // here the declared enum does not match the compiled js which is
  // a string enum, e.g. Aborted = aborted. hence need to filter out
  // the lowercase keys
  const knownStatuses = Object.keys(WorkflowRunStatus)
    .filter(s => isNaN(s as any) && s[0] === s[0].toUpperCase())
    .sort();
  const workflowRunStatuses: SelectOption[] = knownStatuses.map(status => {
    return { label: status, value: status };
  });

  // this is for no filtering by status
  const defaultStatus = { label: "Any", value: "" };
  workflowRunStatuses.unshift(defaultStatus);

  // init to any already applied status as passed in by the parent
  const [selectedStatus, setSelectedStatus] = useState<WorkflowRunStatus[]>(
    currentStatusFilter || []
  );
  statusSetter(selectedStatus);
  const [enteredName, setEnteredName] = useState<string>(
    currentNameFilter || ""
  );
  nameSetter(enteredName);

  const [t] = useTranslation();

  const handleNameFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const name = event.target.value;
    setEnteredName(name);
    nameSetter(name);
  };

  const handleStatusSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const status = event.target.value;
    setSelectedStatus([(status as unknown) as WorkflowRunStatus]);
    statusSetter([(status as unknown) as WorkflowRunStatus]);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onSubmit();
    }
  };

  return (
    <Grid container spacing={1}>
      {/* Name filter */}
      <Grid item xs={6} sm={6}>
        <FormControl fullWidth>
          <TextField
            id="workflow-run-name-filter"
            label={t("workflows.filters.name.label")}
            variant="outlined"
            helperText={t("workflows.filters.name.help")}
            onChange={handleNameFilterChange}
            onKeyPress={handleKeyPress}
            value={enteredName}
            fullWidth
            type="search"
          />
        </FormControl>
      </Grid>

      {/* Status filter */}
      <Grid item xs={6} sm={4}>
        <FormControl fullWidth>
          <TextField
            id="workflow-run-status-filter"
            select
            label={t("tasks.filters.status.label")}
            value={selectedStatus}
            variant="outlined"
            onChange={handleStatusSelect}
            helperText={t("tasks.filters.status.help")}
          >
            {workflowRunStatuses.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default TaskRunFilterForm;
