import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import RootStoreContext from "../../../contexts/RootStoreContext";
import { observer } from "mobx-react";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";

const useStyles = makeStyles({
  signIn: {
    fontWeight: 500,
    backgroundColor: "#FFF",
    color: "#328ED1",
    border: "1px solid #328ED1",
    width: "100%"
  }
});

const SignInButton: React.FC = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const { authStore } = useContext(RootStoreContext);

  const handleLogin = async () => {
    await authStore.login();
  };
  if (authStore.isLoggedIn) return null;
  return (
    <Button
      className={classes.signIn}
      variant="contained"
      onClick={handleLogin}
      endIcon={<PersonOutlineIcon />}
    >
      {t("landing.banner.button.signIn")}
    </Button>
  );
};

export default observer(SignInButton);
