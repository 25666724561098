import React from "react";
import { Box } from "@material-ui/core";
import LandingContainer from "./common/LandingContainer";
import { ContainerComponentProp, LandingCardComponentProp } from "../../types";
import LandingCard from "./common/LandingCard";

const ApiContainer: React.FC<{ container: ContainerComponentProp }> = ({
  container
}) => {
  const landingItems = container.items.map((x: LandingCardComponentProp) => (
    <LandingCard key={x.name} landingCardProp={x} />
  ));
  return (
    <Box key={container.title} mt={6.25} mx={5}>
      <LandingContainer container={container}>{landingItems}</LandingContainer>
    </Box>
  );
};
export default ApiContainer;
