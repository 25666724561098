import React from "react";
import {
  Tooltip,
  PieChart,
  Pie,
  Cell,
  Legend,
  ResponsiveContainer
} from "recharts";

import { dataItem } from "../../../types";

const emptyData: dataItem[] = [{ name: "No usage", value: 0.1 }];
const emptyCol = "#ddd";

/**
 * EmptyGraph is a summary graph shown when there's
 * no data avilable (e.g. no usage has occured yet)
 */
const EmptyGraph = (
  <ResponsiveContainer width={"100%"} height={200}>
    <PieChart width={180} height={200}>
      <Pie
        dataKey="value"
        nameKey="name"
        data={emptyData}
        innerRadius={80}
        outerRadius={100}
        fill="#8884d8"
        paddingAngle={0}
        isAnimationActive={false}
      >
        <Cell fill={emptyCol} />
      </Pie>
      <Tooltip formatter={() => ["No usage", ""]} separator="" />
    </PieChart>
  </ResponsiveContainer>
);

export default EmptyGraph;

export const EmptyGraphKey = (
  <Legend
    payload={[
      {
        id: "empty",
        type: "circle",
        value: emptyData[0].name,
        color: emptyCol
      }
    ]}
    layout="horizontal"
    verticalAlign="middle"
    align="center"
    wrapperStyle={{
      position: "inherit",
      maxWidth: "100%",
      marginTop: "1.5rem"
    }}
  />
);
