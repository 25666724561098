import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

import useGlobalStyles from "../../../styles/classes";
import { JWT } from "../../../util";
import ExtendedFieldDisplay from "../../layout/ExtendedFieldDisplay";
import TokenDisplay from "../display/TokenDisplay";

const useStyles = makeStyles({
  table: {
    tableLayout: "fixed"
  },
  tokenCell: {
    maxWidth: "40%"
  },
  scopeCell: {
    maxWidth: "20%"
  },
  aclCell: {
    maxWidth: "30%",
    overflow: "hidden"
  }
});

interface ExistingTokensTableProps {
  tokens: JWT[];
}

/**
 * ExistingTokensTable renders tokens which have previously
 * been generated through the UI (and saved in the store).
 * Clearing from browser storage results in an empty list.
 * @param tokens — list of JWT token strings
 */
const ExistingTokensTable: React.FC<ExistingTokensTableProps> = ({
  tokens
}) => {
  const [t] = useTranslation();
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tokenCell}>
            {t("tokens.management.table.header.token")}
          </TableCell>
          <TableCell className={classes.scopeCell}>
            {t("tokens.management.table.header.scopes")}
          </TableCell>
          <TableCell className={classes.aclCell}>
            {t("tokens.management.table.header.acl")}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tokens.map((jwt, index) => {
          return (
            <TableRow key={index}>
              <TableCell
                className={clsx(globalClasses.tokenDisplay, classes.tokenCell)}
              >
                <TokenDisplay token={jwt.toString()} minimal />
                <Typography variant="body2">
                  {t("tokens.management.table.expires.label")}:&nbsp;
                  {t("tokens.management.table.expires.date", {
                    expiresDate: jwt.expiresDate
                  })}
                </Typography>
              </TableCell>
              <TableCell className={classes.scopeCell}>
                <Typography variant="body2">
                  <ExtendedFieldDisplay
                    field={jwt.scopes.split(",")}
                    limit={3}
                  />
                </Typography>
              </TableCell>
              <TableCell className={classes.aclCell}>
                <Typography variant="body2">
                  <ExtendedFieldDisplay field={jwt.data.acl} limit={3} />
                </Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default observer(ExistingTokensTable);
