import React, { useContext } from "react";
import { ProductUsage } from "@stratus/console";
import { useTranslation } from "react-i18next";
import TableCell from "@material-ui/core/TableCell";
import Hidden from "@material-ui/core/Hidden";
import Zoom from "@material-ui/core/Zoom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import { storageTypes, transferTypes } from "../../../constants";
import { usageDataGetter, usageTotalGetter } from "../../../util/usage";
import RootStoreContext from "../../../contexts/RootStoreContext";
import { observer } from "mobx-react";

interface TotalUsageCellsProps {
  periodTotal: ProductUsage[];
}

/**
 * TotalUsageCells renders table cells for totals per product
 * type and sub-type
 * @param periodTotal - the aggregate usage for a given billing period
 */
const TotalStorageUsageCells: React.FC<TotalUsageCellsProps> = ({
  periodTotal
}) => {
  const [t] = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const { usageStore } = useContext(RootStoreContext);

  return (
    <>
      <TableCell>{t("usage.history.table.subheader.totals")}</TableCell>

      {storageTypes.map((storage, index) => (
        <Hidden mdDown key={index}>
          <Zoom in={matches}>
            <TableCell>
              {usageDataGetter(
                periodTotal,
                "Storage",
                storage,
                undefined,
                usageStore.billing
              )}
            </TableCell>
          </Zoom>
        </Hidden>
      ))}
      <TableCell>
        {usageTotalGetter(periodTotal, "Storage", usageStore.billing)}
      </TableCell>

      {transferTypes.map((transfer, index) => (
        <Hidden mdDown key={index}>
          <Zoom in={matches}>
            <TableCell>
              {usageDataGetter(
                periodTotal,
                "Transfer",
                transfer,
                undefined,
                usageStore.billing
              )}
            </TableCell>
          </Zoom>
        </Hidden>
      ))}
      <TableCell>
        {usageTotalGetter(periodTotal, "Transfer", usageStore.billing)}
      </TableCell>
    </>
  );
};

export default observer(TotalStorageUsageCells);
