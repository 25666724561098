import React from "react";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";
import { ServiceHealthResponse } from "@stratus/console";
import ServiceHealthTable from "./ServiceHealthTable";

interface HealthDetailsProps {
  health: Array<ServiceHealthResponse>;
}

/**
 * HealthDetails breaks down health status by service
 */
const HealthDetailsContainer: React.FC<HealthDetailsProps> = ({ health }) => {
  const [t] = useTranslation();

  return (
    <>
      <Typography variant="h2">{t("health.details.title")}</Typography>
      <Typography variant="h4">
        {t("common:date", { date: new Date() })}
      </Typography>
      <ServiceHealthTable health={health} />
    </>
  );
};

export default HealthDetailsContainer;
