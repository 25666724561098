import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(({ spacing }: Theme) => ({
  spacer: {
    paddingTop: spacing(4),
    paddingBottom: spacing(4)
  }
}));

/**
 * PaddedDivider is a full-width muiDivider hr with top
 * and bottom padding to space out content
 */
const PaddedDivider: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.spacer}>
      <Divider />
    </Grid>
  );
};

export default PaddedDivider;
