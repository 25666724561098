import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import RootStoreContext from "../../../contexts/RootStoreContext";
import { useTranslation } from "react-i18next";

import ExistingTokensTable from "./ExistingTokensTable";
import { JWT } from "../../../util";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import useGlobalStyles from "../../../styles/classes";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  tabs: {
    marginBottom: ".5rem"
  }
});

/**
 * TokenManagementContainer is a smart store-connected
 * component for the Token Management section of the tokens
 * view
 */
const TokenManagementContainer: React.FC = () => {
  const { authStore } = useContext(RootStoreContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const [t] = useTranslation();
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  // sort tokens into active + inactive
  const active_tokens: JWT[] = [];
  const expired_tokens: JWT[] = [];

  const isExpired = (token: JWT) => {
    if (token.expiresDate < new Date()) {
      return true;
    }
    return false;
  };

  authStore.additionalJWT.forEach(token => {
    let jwt: JWT;
    try {
      jwt = new JWT(token);
    } catch (err) {
      return;
    }

    if (isExpired(jwt)) {
      expired_tokens.push(jwt);
    } else {
      active_tokens.push(jwt);
    }
  });

  const handleClearExpiredClick = () => {
    authStore.clearExpiredJWT();
  };

  // tabs separate active + inactive tokens
  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={6} className={classes.tabs}>
          <Tabs value={selectedTab} onChange={(_, v) => setSelectedTab(v)}>
            <Tab label={t("token.management.active")} />
            <Tab label={t("token.management.expired")} />
          </Tabs>
        </Grid>
        <Grid item xs={6} className={globalClasses.rightFlex}>
          {selectedTab === 1 && expired_tokens.length > 0 && (
            <Button
              variant="text"
              color="primary"
              onClick={handleClearExpiredClick}
            >
              {t("token.management.clear")}
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {selectedTab === 0 && <ExistingTokensTable tokens={active_tokens} />}
        {selectedTab === 1 && <ExistingTokensTable tokens={expired_tokens} />}
      </Grid>
    </>
  );
};

export default observer(TokenManagementContainer);
