import React, { useContext } from "react";
import { ProductUsage } from "@stratus/console";
import { useTranslation } from "react-i18next";
import TableCell from "@material-ui/core/TableCell";

import { computeTypes } from "../../../constants";
import { usageDataGetter, computeTransform } from "../../../util/usage";
import { observer } from "mobx-react";
import RootStoreContext from "../../../contexts/RootStoreContext";

interface TotalComputeUsageCellsProps {
  periodTotal: ProductUsage[];
}

/**
 * TotalComputeUsageCells renders table cells for totals per product
 * type and sub-type for compute
 * @param periodTotal - the aggregate usage for a given billing period
 */
const TotalComputeUsageCells: React.FC<TotalComputeUsageCellsProps> = ({
  periodTotal
}) => {
  const [t] = useTranslation();

  const { usageStore } = useContext(RootStoreContext);

  return (
    <>
      <TableCell>{t("usage.history.table.subheader.totals")}</TableCell>
      {computeTypes.map((compute, index) => (
        <TableCell key={index}>
          {compute === "MemoryGB"
            ? usageDataGetter(
                periodTotal,
                "Compute",
                compute,
                usageStore.billing ? undefined : s => computeTransform(s, true),
                usageStore.billing
              )
            : usageDataGetter(
                periodTotal,
                "Compute",
                compute,
                undefined,
                usageStore.billing
              )}
        </TableCell>
      ))}
    </>
  );
};

export default observer(TotalComputeUsageCells);
