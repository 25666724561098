import React from "react";
import { makeStyles, Theme, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: () => ({
    [theme.breakpoints.up("sm")]: {
      marginTop: 64
    },
    flexGrow: 1,
    display: "flex",
    width: "100%",
    marginTop: 48
  })
}));

/**
 * Properly-spaced container for landing page
 */
const PublicContainer: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" className={classes.root}>
      {children}
    </Grid>
  );
};
export default PublicContainer;
