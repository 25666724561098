import React from "react";

import { Box, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import LandingContainer from "./common/LandingContainer";
import { ContainerComponentProp, LandingCardComponentProp } from "../../types";
import LandingCard from "./common/LandingCard";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#F2F4F4",
    minHeight: "476px"
  }
});

const useOverrideStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: "303px",
    minWidth: "303px",
    minHeight: "325px",
    maxHeight: "325px",
    backgroundColor: "white",
    position: "relative",
    marginTop: theme.spacing(2)
  },
  name: {
    fontSize: "18px",
    color: "#328ED1",
    fontWeight: 900
  },
  intro: {
    fontSize: "13px",
    lineHeight: "20px",
    color: "#69788E",
    fontWeight: 300
  },
  linkButton: {
    padding: theme.spacing(1),
    borderTop: "#F2F4F4 1px solid",
    position: "absolute",
    bottom: "0px",
    fontWeight: 300,
    width: "100%",
    justifyContent: "flex-start"
  },
  linkText: {
    //to match the content margin
    marginLeft: theme.spacing(2),
    fontWeight: 300,
    fontSize: "13px",
    color: "#328ED1"
  },
  linkIcon: {
    color: "#328ED1"
  },
  content: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

const ResourceContainer: React.FC<{ container: ContainerComponentProp }> = ({
  container
}) => {
  const classes = useStyles();
  const overrideStyles = useOverrideStyles();
  const landingCards = container.items.map((x: LandingCardComponentProp) => (
    <LandingCard
      key={x.name}
      landingCardProp={x}
      overrideStyle={overrideStyles}
    />
  ));

  return (
    <Box className={classes.root} key={container.title} p={5} mt={5}>
      <LandingContainer container={container}> {landingCards}</LandingContainer>
    </Box>
  );
};

export default ResourceContainer;
