import React, { useContext } from "react";
import { observer } from "mobx-react";
import RootStoreContext from "../contexts/RootStoreContext";
import { Redirect, RouteProps } from "react-router-dom";

/**
 * If no platform session token set the user is not logged in,
 * redirect to platform for authentication
 */
const LoginRedirect: React.FC = (props: RouteProps) => {
  const { authStore } = useContext(RootStoreContext);
  //go back to original page if already logged in
  if (authStore.isLoggedIn) {
    return <Redirect to="/dashboard" />;
  }
  //log in with redirect to where the client came from
  if (
    props.location &&
    props.location.state &&
    props.location.state.from.pathname
  ) {
    authStore.login(props.location.state.from.pathname);
  } else {
    authStore.login();
  }
  return null;
};

export default observer(LoginRedirect);
