import { ResourceLinksContent } from "../types";

/**
 * @fileoverview App-wide constants are defined here
 *
 * Those that may differ between deployments should
 * be parsed from environment variables and injected
 * at build time.
 */

declare global {
  interface Window {
    config: {
      [key: string]: string | undefined;
    };
  }
}

/**
 * resolveEnvVar uses the create-react-app wrapper
 * around dotenv for parsing env vars from a .env
 * file or runtime env.
 * @param key - variable symbol without REACT_APP_
 * @throws error is variable is unset
 */
const resolveEnvVar = (key: string) => {
  const envVar = `REACT_APP_${key}`;

  if (process.env.NODE_ENV === "development") {
    if (envVar in process.env) {
      return process.env[envVar];
    }
  }

  // for prod, use server-side env.js config
  if (process.env.NODE_ENV === "production") {
    const config = window.config;
    if (config && Object.keys(window.config).includes(envVar)) {
      return window.config[envVar];
    }
  }
};

/** URI for platform auth */
export const PLATFORM = resolveEnvVar("PLATFORM")!;

/** URI where this SPA is deployed */
export const BASE_URL = resolveEnvVar("BASE_URL")!;

/** URI for backend API */
export const API_BASE = resolveEnvVar("API_BASE")!;

/** Location of readme.io / other swagger-based API docs */
export const API_DOCS_ROOT = resolveEnvVar("API_DOCS_ROOT");
export const API_DOCS = resolveEnvVar("API_DOCS_ROOT") + "/docs"!;

/** Domain for setting / removing cookies */
export const COOKIE_DOMAIN = resolveEnvVar("COOKIE_DOMAIN")!;

/** Base folder for console deployment */
export const BASE_FOLDER = process.env.PUBLIC_URL;

/** Platform public home url */
export const PLATFORM_PUBLIC_HOME = resolveEnvVar("PLATFORM_PUBLIC_HOME")!;

/** Colourblind-fiendly palette for data viz */
export const GRAPH_PALETTE = [
  "url(#green-fade)",
  "url(#blue-fade)",
  "url(#yellow-fade)",
  "url(#aqua-fade)",
  "url(#orange-fade)"
];

/** Known types of storage, e.g. to break-out in a usage table */
export const storageTypes = ["Standard", "Archive", "DeepArchive"];

/** Known types of transfer, e.g. to break-out in a usage table */
export const transferTypes = [
  "Archived",
  "Restore.Economy",
  "Restore.DeepArchive.Standard",
  "Restore.Archive.Standard"
];

/** Known types of compute, e.g. to break-out in a usage table */
export const computeTypes = ["vCPU", "FPGA", "MemoryGB"];

/** Usage product set */
export const usageTypes = {
  storage: storageTypes,
  transfer: transferTypes,
  compute: computeTypes
};

/** Currency branding for use in unit labels + controls */
export const creditsLabel = "iCredits";

/** the href links to api docs */
export const navButtonListHref: ResourceLinksContent[] = [
  {
    title: "Getting Started",
    resources: [
      {
        resourceLinks: [
          {
            name: "Create an Account",
            path: API_DOCS + "/overview_create-an-account"
          },
          {
            name: "Configure a Domain",
            path: API_DOCS + "/overview_configure-domain"
          },
          {
            name: "Install the Command Line Interface (CLI)",
            path: API_DOCS + "/cli_installation"
          },
          {
            name: "Configure Command Line Interface (CLI)",
            path: API_DOCS + "/cli_configuration"
          },
          {
            name: "Accessing the API",
            path: API_DOCS + "/overview_accessing-the-api"
          },
          {
            name: "Using Access Control Lists (ACLs)",
            path: API_DOCS + "/overview_using-acls"
          },
          { name: "API Keys", path: API_DOCS + "/api-keys" },
          {
            name: "Token-Based Pagination",
            path: API_DOCS + "/overview_token-based-pagination"
          },
          { name: "IAP Scopes", path: API_DOCS + "/overview_iap-scopes" },
          {
            name: "Postman - Running workflow tutorials",
            path: API_DOCS + "/running-workflow-tutorials-through-postman"
          },
          {
            name: "Swagger - Importing API into Postman",
            path: API_DOCS + "/importing-api-from-swagger-into-postman"
          }
        ]
      }
    ]
  },
  {
    title: "Explore",
    resources: [
      {
        name: "Core Services",
        resourceLinks: [
          { name: "Genomic Data Store", path: API_DOCS + "/gds_introduction" },
          {
            name: "Workflow Execution Service",
            path: API_DOCS + "/wes_introduction"
          },
          {
            name: "Task Execution Service",
            path: API_DOCS + "/tes_introduction"
          },
          {
            name: "Event Notification Service",
            path: API_DOCS + "/ens_introduction"
          }
        ]
      },
      {
        name: "DRAGEN Tutorials",
        resourceLinks: [
          {
            name: "DRAGEN Trio Analysis",
            path: API_DOCS + "/tutorials_dragen-tutorial"
          },
          {
            name: "DRAGEN Tumor-Normal Analysis",
            path: API_DOCS + "/tutorials_dragen-tn"
          },
          {
            name: "DRAGEN Enrichment",
            path:
              API_DOCS +
              "/dragen-enrichment-targeted-panel-alignment-and-variant-calling"
          },
          {
            name: "DRAGEN RNA-Seq + DESeq2",
            path: API_DOCS + "/dragen-rna-seq-deseq2"
          }
        ]
      }
    ]
  },
  {
    title: "Support",
    resources: [
      {
        resourceLinks: [
          { name: "FAQs", path: API_DOCS + "/overview_faqs" },
          { name: "Known Issues", path: API_DOCS + "/overview_known-issues" },
          {
            name: "Early Access Information",
            path: API_DOCS + "/overview_early-access-information"
          },
          { name: "Supported Regions", path: API_DOCS + "/supported-regions" },
          { name: "Pricing", path: API_DOCS + "/pricing" }
        ]
      }
    ]
  }
];
