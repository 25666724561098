import React from "react";

import SummaryCard from "./SummaryCard";
import GenericSVGLoader from "../../loaders";
import { ResponsiveContainer } from "recharts";

/**
 * LoadingCard renders a SummaryCard with loading
 * placeholders while waiting for an async request
 */
const LoadingCard: React.FC = () => {
  // total height must be 200
  const HeadlineLoader = (
    <GenericSVGLoader height={16} width={180}>
      <rect x="0" y="0" rx="4" ry="4" width="90" height="12" />
    </GenericSVGLoader>
  );

  const LegendLoader = (
    <ResponsiveContainer height="40" width="100%">
      <GenericSVGLoader>
        {Array.from(Array(3).keys()).map(i => (
          <React.Fragment key={i}>
            <circle cy="60%" cx={25 + 20 * i + "%"} r="8" />
            <rect
              y="45%"
              x={28 + 20 * i + "%"}
              rx="4"
              ry="4"
              width="12%"
              height="12"
            />
          </React.Fragment>
        ))}
      </GenericSVGLoader>
    </ResponsiveContainer>
  );

  const GraphLoader = (
    <ResponsiveContainer height="200" width="100%">
      <GenericSVGLoader>
        <circle cx="50%" cy="50%" r="100" />
      </GenericSVGLoader>
    </ResponsiveContainer>
  );

  return (
    <SummaryCard
      headline={HeadlineLoader}
      graphKey={LegendLoader}
      graph={GraphLoader}
    />
  );
};

export default LoadingCard;
