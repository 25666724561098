import React, { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import { makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
  error: {
    backgroundColor: palette.error.main
  },
  icon: {
    fontSize: "1rem"
  },
  errIcon: {
    paddingRight: spacing(2)
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
}));

interface ErrorSnackbarProps {
  error: string;
  clearOnClose: React.Dispatch<React.SetStateAction<string>>;
}

/**
 * ErrorSnackbar renders an error as a short-lived snackbar
 * @param error — error response body from SDK
 * @param clearOnClose - the setter from a useState string hook
 */
const ErrorSnackbar: React.FC<ErrorSnackbarProps> = ({
  error,
  clearOnClose
}) => {
  const [open, setOpen] = useState(true);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    clearOnClose("");
  };

  if (!error) {
    return null;
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={open}
        autoHideDuration={9000}
        onClose={handleClose}
      >
        <SnackbarContent
          className={classes.error}
          aria-describedby="error-snackbar"
          message={
            <span id="error-snackbar" className={classes.message}>
              <ErrorIcon className={clsx(classes.icon, classes.errIcon)} />
              {error}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          ]}
        />
      </Snackbar>
    </>
  );
};

export default ErrorSnackbar;
