import React from "react";

import NavDropdown from "./NavDropdown";
import { ResourceLinksContent } from "../../types";
import { navButtonListHref } from "../../constants";

const NavButtonList: React.FC = () => {
  const links: ResourceLinksContent[] = navButtonListHref;

  const dropdowns = links.map(link => (
    <NavDropdown
      key={link.title}
      dropDownTitle={link.title}
      dropDownResources={link.resources}
    />
  ));
  return <>{dropdowns}</>;
};

export default NavButtonList;
