import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import WorkflowRunsTable from "./WorkflowRunsTable";
import WorkflowRunFiltersContainer from "./filters/WorkflowRunFiltersContainer";
import { WorkflowRunCompact } from "@stratus/wes";

interface WorkflowGridProps {
  workflowRuns: WorkflowRunCompact[];
}

const WorkflowGrid: React.FC<WorkflowGridProps> = ({ workflowRuns }) => {
  const [t] = useTranslation();
  return (
    <Paper>
      <Typography variant="body2">{t("workflows.intro")}</Typography>
      <WorkflowRunFiltersContainer />
      <WorkflowRunsTable workflowRuns={workflowRuns} />
    </Paper>
  );
};

export default observer(WorkflowGrid);
