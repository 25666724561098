import React from "react";
import GDS from "../../../assets/genomic-data-store.png";
import WES from "../../../assets/workflow-execution-service.png";
import TES from "../../../assets/task-execution-service.png";
import ENS from "../../../assets/event-notification-service.png";
import Start from "../../../assets/compass-copy-2.png";
import Tutorials from "../../../assets/book.png";
import Community from "../../../assets/users.png";
import Changelog from "../../../assets/Bullhorn.png";
import { makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles({
  largeIconBox: {
    width: "255px",
    height: "105px"
  },
  smallIconBox: {
    minHeight: "38px"
  }
});

const LargeIcon: React.FC<{ icon: string; alt: string }> = ({ icon, alt }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classes.largeIconBox}
      my={2}
    >
      <img src={icon} alt={alt} />
    </Box>
  );
};

const SmallIcon: React.FC<{ icon: string; alt: string }> = ({ icon, alt }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.smallIconBox}
      my={2}
    >
      <img src={icon} alt={alt} />
    </Box>
  );
};

const getIcon = (iconKey: string) => {
  switch (iconKey) {
    case "GDS":
      return <LargeIcon icon={GDS} alt={`${iconKey} key`} />;
    case "WES":
      return <LargeIcon icon={WES} alt={`${iconKey} key`} />;
    case "TES":
      return <LargeIcon icon={TES} alt={`${iconKey} key`} />;
    case "ENS":
      return <LargeIcon icon={ENS} alt={`${iconKey} key`} />;
    case "START":
      return <SmallIcon icon={Start} alt={`${iconKey} key`} />;
    case "TUTORIALS":
      return <SmallIcon icon={Tutorials} alt={`${iconKey} key`} />;
    case "COMMUNITY":
      return <SmallIcon icon={Community} alt={`${iconKey} key`} />;
    case "CHANGELOG":
      return <SmallIcon icon={Changelog} alt={`${iconKey} key`} />;

    default:
      return undefined;
  }
};

const LandingCardIcon: React.FC<{ iconKey: string }> = ({ iconKey }) => {
  const icon = getIcon(iconKey);
  if (icon === undefined) return null;
  return icon;
};

export default LandingCardIcon;
