import React, { useContext } from "react";
import { UserAggregatedUsage } from "@stratus/console";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core/styles";
import { Hidden, Zoom } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { storageTypes, transferTypes } from "../../../constants";
import { usageDataGetter, usageTotalGetter } from "../../../util/usage";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import RootStoreContext from "../../../contexts/RootStoreContext";

const useStyles = makeStyles({
  userCell: {
    fontWeight: 300,
    letterSpacing: 0
  }
});

interface UserUsageCellsProps {
  user: UserAggregatedUsage | undefined;
}

/**
 * StorageUsageCells takes the usage information for a single user in a
 * single period and returns table cells for storage broken down by
 * sub-category.
 * @param user – usage API response for a single user in a given period
 */
const StorageUsageCells: React.FC<UserUsageCellsProps> = ({ user }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [t] = useTranslation();

  const { usageStore } = useContext(RootStoreContext);

  return (
    <>
      <TableCell className={classes.userCell}>
        {user === undefined
          ? t("usage.history.table.row.unknownUser")
          : user.user
          ? user.user.fullName
          : t("usage.history.table.row.unknownUser")}
      </TableCell>

      {storageTypes.map((storage, index) => (
        <Hidden mdDown key={index}>
          <Zoom in={matches}>
            <TableCell className={classes.userCell}>
              {usageDataGetter(
                user && user.usages,
                "Storage",
                storage,
                undefined,
                usageStore.billing
              )}
            </TableCell>
          </Zoom>
        </Hidden>
      ))}
      <TableCell>
        {usageTotalGetter(user && user.usages, "Storage", usageStore.billing)}
      </TableCell>

      {transferTypes.map((transfer, index) => (
        <Hidden mdDown key={index}>
          <Zoom in={matches}>
            <TableCell className={classes.userCell}>
              {usageDataGetter(
                user && user.usages,
                "Transfer",
                transfer,
                undefined,
                usageStore.billing
              )}
            </TableCell>
          </Zoom>
        </Hidden>
      ))}
      <TableCell>
        {usageTotalGetter(user && user.usages, "Transfer", usageStore.billing)}
      </TableCell>
    </>
  );
};

export default observer(StorageUsageCells);
