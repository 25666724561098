import React from "react";
import { pageRef, allRoutes } from "../../routes";
import Button from "@material-ui/core/Button";
import RouteLink from "../nav/RouteLink";

/**
 * GenerateTokenButton is a router link to the
 * tokens view rendered as an MUI button
 * @param children button label
 */
const GenerateTokenButton: React.FC = ({ children }) => {
  // get path to token generation route for linking
  const { path } = allRoutes.find(r => r.ref === pageRef.token)!;

  return (
    <RouteLink path={path}>
      <Button color="primary" variant="contained">
        {children}
      </Button>
    </RouteLink>
  );
};

export default GenerateTokenButton;
