import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Chip from "@material-ui/core/Chip";

import { WorkflowVersionCompact } from "@stratus/wes";

interface WorkflowVersionDetailsListProps {
  workflowVersion: WorkflowVersionCompact;
}

/**
 * WorkflowVersionDetailsList takes a task run and presents a summary list of
 * key fields
 */
const WorkflowVersionDetailsList: React.FC<WorkflowVersionDetailsListProps> = ({
  workflowVersion
}) => {
  const [t] = useTranslation();
  const isISL =
    workflowVersion &&
    workflowVersion.language &&
    workflowVersion.language.name === "ISL";

  return (
    <>
      <Typography variant="h4">{t("workflows.details.version")}</Typography>
      <Typography variant="body2" component="div">
        <List dense>
          <ListItem>
            <strong>{t("task.details.id")}:</strong>&nbsp;{workflowVersion.id}
          </ListItem>
          <ListItem>
            <strong>{t("workflows.details.version")}:</strong>&nbsp;
            {workflowVersion.version}
          </ListItem>

          {workflowVersion.description && (
            <ListItem>
              <strong>{t("workflows.details.description")}:</strong>&nbsp;
              {workflowVersion.description}
            </ListItem>
          )}

          <ListItem>
            <strong>{t("workflows.details.status")}:</strong>&nbsp;
            {workflowVersion.status}
          </ListItem>
          <ListItem>
            <strong>{t("workflows.details.language")}:</strong>&nbsp;
            <Chip
              variant="outlined"
              size="small"
              label={workflowVersion.language!.name}
              color={isISL ? "secondary" : "primary"}
            />
          </ListItem>
          <ListItem>
            <strong>{t("task.details.created")}:</strong>&nbsp;
            {t("common:date", {
              date: new Date((workflowVersion.timeCreated as unknown) as string)
            })}
          </ListItem>
          <ListItem>
            <strong>{t("task.details.modified")}:</strong>&nbsp;
            {t("common:date", {
              date: new Date(
                (workflowVersion.timeModified as unknown) as string
              )
            })}
          </ListItem>
        </List>
      </Typography>
    </>
  );
};

export default WorkflowVersionDetailsList;
