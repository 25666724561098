import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import Grid from "@material-ui/core/Grid";
import InfiniteScroll from "react-infinite-scroll-component";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import LinearProgress from "@material-ui/core/LinearProgress";

import RootStoreContext from "../../contexts/RootStoreContext";
import useGlobalStyles from "../../styles/classes";
import ErrorSnackbar from "../errors/ErrorSnackbar";
import PaddedDivider from "../layout/PaddedDivider";
import WorkflowGrid from "./WorkflowGrid";

/**
 * WorkflowContainer connects the workflow view with the domain
 * store for workflows and workflow runs.
 */
const WorkflowContainer: React.FC = () => {
  const { workflowStore, authStore } = useContext(RootStoreContext);
  const globalClasses = useGlobalStyles();
  const [error, setError] = useState("");
  const [initialLoad, setInitialLoad] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    let active = true;
    if (active && authStore.isInitialised && !workflowStore.exhausted) {
      (async () => {
        try {
          await workflowStore.getWorkflowRuns();

          // initialLoad blocks the first scroll event
          // on initial load when there are no items
          setInitialLoad(true);
        } catch (err) {
          setError(err.message);
        }
      })();
      return () => {
        workflowStore.resetWorkflowRunList(false);
        active = false;
      };
    }
  }, [workflowStore, authStore.isInitialised]);

  const nextTasks = async () => {
    try {
      await workflowStore.getWorkflowRuns();
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <Grid item xs={12} className={globalClasses.paddedContainer}>
        <Typography variant="h2">{t("workflows.title")}</Typography>
      </Grid>
      <Grid item xs={12} className={globalClasses.paddedContainer}>
        <InfiniteScroll
          dataLength={workflowStore.workflowRuns.length}
          next={() => {
            initialLoad && nextTasks();
          }}
          hasMore={!workflowStore.exhausted}
          loader={<LinearProgress color="secondary" />}
        >
          <WorkflowGrid workflowRuns={workflowStore.workflowRuns} />
        </InfiniteScroll>
      </Grid>

      <Grid item xs={12} className={globalClasses.paddedContainer}>
        {workflowStore.exhausted && (
          <Typography variant="h4" align="center">
            {workflowStore.workflowRuns.length === 0
              ? t("workflow.runs.none")
              : t("workflow.runs.loaded")}
          </Typography>
        )}
      </Grid>

      <PaddedDivider />

      {error !== "" && <ErrorSnackbar error={error} clearOnClose={setError} />}
    </>
  );
};

export default observer(WorkflowContainer);
