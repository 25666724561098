import React from "react";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { PeriodUsageSummary } from "@stratus/console";

interface SummaryHeaderProps {
  period: PeriodUsageSummary;
}

/**
 * SummaryHeader is the header portion common to usage
 * sub-pages
 */
const SummaryHeader: React.FC<SummaryHeaderProps> = ({ period }) => {
  const [t] = useTranslation();

  return (
    <>
      <Typography variant="h2" gutterBottom>
        {t("usage.summary.title")}
      </Typography>

      <Typography variant="body2">
        {t("usage.summary.current")}&nbsp;
        {period &&
          period.start &&
          t("common:ymd", { date: new Date(period.start as Date) })}
      </Typography>
    </>
  );
};

export default SummaryHeader;
