import React from "react";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

import HealthSummaryText from "./HealthSummaryText";
import SummaryStatus from "./SummaryStatus";
import { SystemHealthResponse } from "@stratus/console";
import { SystemHealthResponseStatus } from "../../constants/enums";
import SVGLoader from "../loaders";

interface HealthSummaryProps {
  health: SystemHealthResponse;
}

const SummaryStatusLoader = () => (
  <SVGLoader height={60} width={200}>
    <circle cx="10" cy="16" r="9" />
    <rect x="28" y="12" rx="5" ry="5" width="120" height="10" />
  </SVGLoader>
);

/**
 * HealthSummary gives an overall view of service
 * health across all services
 */
const HealthSummary: React.FC<HealthSummaryProps> = ({ health }) => {
  const [t] = useTranslation();

  return (
    <>
      <Typography variant="h2">{t("health.summary.title")}</Typography>

      <div style={{ height: "2rem", width: 200 }}>
        {!!health.status ? (
          <SummaryStatus
            status={(health.status as unknown) as SystemHealthResponseStatus}
          />
        ) : (
          <SummaryStatusLoader />
        )}
      </div>

      <HealthSummaryText />
    </>
  );
};

export default HealthSummary;
