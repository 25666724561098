import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, Hidden } from "@material-ui/core";
import LandingContainerIcon from "./LandingContainerIcon";
import { ContainerComponentProp } from "../../../types";

const useStyles = makeStyles(({ spacing }: Theme) => ({
  title: {
    color: "#4F5A67",
    fontSize: "24px",
    fontWeight: 900,
    marginBottom: spacing(1)
  },
  subtitle: {
    color: "#4F5A67",
    fontSize: "20px",
    fontWeight: 500
  }
}));

const LandingContainer: React.FC<{
  container: ContainerComponentProp;
  children: React.ReactNode;
}> = ({ container, children }) => {
  const classes = useStyles();
  const style = container.overrideStyles
    ? { ...classes, ...container.overrideStyles }
    : classes;
  return (
    <Grid
      container
      className={style.root}
      direction="column"
      justify="center"
      alignItems="center"
      spacing={4}
    >
      <Hidden xsDown>
        <Grid item>
          <LandingContainerIcon iconName={container.key} />
        </Grid>
      </Hidden>
      <Grid item>
        <Typography className={style.title} align="center">
          {container.title}
        </Typography>
        <Typography className={style.subtitle} align="center">
          {container.subtitle}
        </Typography>
      </Grid>

      <Grid container justify="space-around" alignItems="center">
        {children}
      </Grid>
    </Grid>
  );
};

export default LandingContainer;
