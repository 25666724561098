import React from "react";
import ContentLoader from "react-content-loader";
import Fade from "@material-ui/core/Fade";

interface GenericSVGLoaderProps {
  height?: number;
  width?: number;
}

/**
 * GenericSVGLoader is a react-content-loader
 * @param height - height in px for SVG canvas
 * @param width - width in px for SVG canvas
 */
const GenericSVGLoader: React.FC<GenericSVGLoaderProps> = ({
  height,
  width,
  children
}) => {
  return (
    <ContentLoader
      height={height}
      width={width}
      speed={1}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
      preserveAspectRatio="xMidYMid meet"
      style={{ height, width }}
    >
      {children}
    </ContentLoader>
  );
};

export default GenericSVGLoader;

interface FadeInProps {
  on: boolean;
}
export const FadeIn: React.FC<FadeInProps> = ({ children, on }) => (
  <Fade in={on} timeout={500}>
    <div>{children}</div>
  </Fade>
);
