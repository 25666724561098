import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";

import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";

import RootStoreContext from "../../contexts/RootStoreContext";
import NavDrawerLinks from "./NavDrawerLinks";
import routes from "../../routes";
import { useTranslation } from "react-i18next";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    marginTop: 64,
    backgroundColor: "#f8f9fb",
    textAlign: "left"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: 64,
    backgroundColor: "#f8f9fb"
  },
  smallDrawerPaper: {
    width: drawerWidth,
    marginTop: 48,
    backgroundColor: "#f8f9fb"
  },
  subtitle: {
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginBottom: -theme.spacing(1)
  },
  navList: {
    paddingTop: theme.spacing(2)
  }
}));

const ResponsiveDrawer: React.FC = () => {
  const classes = useStyles();
  const { uiStore } = useContext(RootStoreContext);

  const [t] = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  // sync drawer state with
  useEffect(() => {
    if (!matches) {
      uiStore.closeDrawer();
      uiStore.setTempDrawer(true);
    } else {
      uiStore.openDrawer();
      uiStore.setTempDrawer(false);
    }
  }, [matches, uiStore]);

  function handleDrawerClose() {
    uiStore.closeDrawer();
  }

  const drawer = (
    <div className={classes.navList}>
      <Typography variant="h6" className={classes.subtitle}>
        {t("nav.linkgroup.apps")}
      </Typography>
      <NavDrawerLinks renderRoutes={routes.Apps} />
      <Typography variant="h6" className={classes.subtitle}>
        {t("nav.linkgroup.reference")}
      </Typography>
      <NavDrawerLinks renderRoutes={routes.Reference} />
    </div>
  );

  return (
    <div className={classes.root}>
      <nav className={classes.drawer}>
        <Hidden smUp>
          <Drawer
            transitionDuration={0}
            variant="temporary"
            open={!matches && uiStore.drawerIsOpen}
            onClose={() => handleDrawerClose()}
            classes={{
              paper: classes.smallDrawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown>
          <Drawer
            transitionDuration={0}
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
};

export default observer(ResponsiveDrawer);
