import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
// import Collapse from "@material-ui/core/Collapse";
import clsx from "clsx";

import RootStoreContext from "../../contexts/RootStoreContext";
import { TableSortLabel, Hidden } from "@material-ui/core";
import { WorkflowRunCompact, WorkflowRunStatus } from "@stratus/wes";
import WorkflowStatusChip from "./status/WorkflowStatusChip";
import RouteLink from "../nav/RouteLink";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  table: {
    tableLayout: "fixed"
  },
  truncatedColumn: {
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  taskCell: {
    borderBottom: "none"
  }
});

interface WorkflowRunsTableProps {
  workflowRuns: WorkflowRunCompact[];
}

/**
 * WorkflowRunsTable renders a page of task runs
 * @param tokens — list of task runs
 */
const WorkflowRunsTable: React.FC<WorkflowRunsTableProps> = ({
  workflowRuns
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [, setUncollapsedRow] = useState([] as number[]);
  const { workflowStore, uiStore } = useContext(RootStoreContext);
  const history = useHistory();

  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSortKeyChange = (newKey: string) => {
    if (workflowStore.sortKey === newKey) {
      workflowStore.toggleSortDirection();
    } else {
      workflowStore.updateSortKey(newKey);
    }
    setUncollapsedRow([]);
  };

  const handleRowClick = (workflowRunId: string) => {
    uiStore.changeRoute(`/workflows/${workflowRunId}`);
    history.push(`/workflows/${workflowRunId}`);
  };

  return (
    <>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            {/* These aren't created in a loop so
            that i18n-parser to pick up the keys */}
            <TableCell>
              <TableSortLabel
                direction={workflowStore.sortDirection}
                active={workflowStore.sortKey === "name"}
                onClick={() => handleSortKeyChange("name")}
              >
                {t("workflow.runs.table.header.workflow")}
              </TableSortLabel>
            </TableCell>
            <TableCell style={{ width: "90px" }}>
              <TableSortLabel
                direction={workflowStore.sortDirection}
                active={workflowStore.sortKey === "status"}
                onClick={() => handleSortKeyChange("status")}
              >
                {t("workflow.runs.table.header.status")}
              </TableSortLabel>
            </TableCell>

            <Hidden smDown>
              <TableCell>
                <TableSortLabel
                  direction={workflowStore.sortDirection}
                  active={workflowStore.sortKey === "timeCreated"}
                  onClick={() => handleSortKeyChange("timeCreated")}
                >
                  {t("workflow.runs.table.header.created")}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  direction={workflowStore.sortDirection}
                  active={workflowStore.sortKey === "timeModified"}
                  onClick={() => handleSortKeyChange("timeModified")}
                >
                  {t("workflow.runs.table.header.modified")}
                </TableSortLabel>
              </TableCell>
            </Hidden>
          </TableRow>
        </TableHead>
        <TableBody>
          {workflowRuns.map((workflowRun, index) => {
            return (
              <React.Fragment key={index}>
                <TableRow hover onClick={() => handleRowClick(workflowRun.id!)}>
                  <TableCell
                    className={clsx(classes.truncatedColumn, classes.taskCell)}
                  >
                    <RouteLink path={`/workflows/${workflowRun.id}`}>
                      {workflowRun.name || t("workflows.details.noname")}
                    </RouteLink>
                  </TableCell>
                  <TableCell className={classes.taskCell}>
                    <WorkflowStatusChip
                      status={
                        (workflowRun.status as unknown) as WorkflowRunStatus
                      }
                    />
                  </TableCell>

                  <Hidden smDown>
                    <TableCell className={classes.taskCell}>
                      {t("common:datetime", {
                        date: new Date(workflowRun.timeCreated as Date)
                      })}
                    </TableCell>
                    <TableCell className={classes.taskCell}>
                      {t("common:datetime", {
                        date: new Date(workflowRun.timeModified as Date)
                      })}
                    </TableCell>
                  </Hidden>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default observer(WorkflowRunsTable);
