import React from "react";
import { PeriodUsageSummary } from "@stratus/console";
import { makeStyles } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";

import { usagePeriodToCSV, csvDownload } from "../../../util/usage";
import StorageUsageCells from "./StorageUsageCells";
import TotalStorageUsageCells from "./TotalStorageUsageCells";
import UserTableRowHeader from "./UserTableRowHeader";

const useStyles = makeStyles({
  totalRow: {
    background: "rgba(50, 142,209,.14)"
  }
});

interface HistoryPeriodRowProps {
  period: PeriodUsageSummary;
}

/**
 * HistoryPeriodRow is a table row render of usage history
 * for a given period
 */
const StorageHistoryRow: React.FC<HistoryPeriodRowProps> = ({ period }) => {
  const classes = useStyles();

  const userUsage = period.userAggregatedUsages!;
  // 1 additional row for totals
  const nRows = Math.max(userUsage.length + 1, 2);

  const handleCSVDownloadClick = () => {
    const downloadName = `${new Date(period.end!)
      .toISOString()
      .replace(/[ :,.]/g, "_") || "unnamed-usage-period"}-storage-transfer.csv`;
    csvDownload(usagePeriodToCSV(period, "Storage"), downloadName);
  };

  return (
    <>
      <TableRow>
        <UserTableRowHeader
          rows={nRows}
          date={new Date(period.end!)}
          csvClick={handleCSVDownloadClick}
        />

        {/* first user goes on this row */
        userUsage && <StorageUsageCells user={userUsage[0]} />}
      </TableRow>

      {/* subsequent users go on their own row (all spanned by period) */}
      {userUsage &&
        userUsage.map(
          (user, index) =>
            index > 0 && (
              <TableRow key={index}>
                <StorageUsageCells user={user} />
              </TableRow>
            )
        )}

      {/* total for this period goes here */}
      <TableRow className={classes.totalRow}>
        <TotalStorageUsageCells periodTotal={period.totalUsages!} />
      </TableRow>
    </>
  );
};

export default StorageHistoryRow;
