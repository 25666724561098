import React from "react";
import { observer } from "mobx-react";
import { PeriodUsageSummary } from "@stratus/console";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  TableBody,
  Hidden
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ComputeHistoryRow from "../table/ComputeHistoryRow";

const useStyles = makeStyles({
  filledHeader: {
    backgroundColor: "#D8D8D8",
    padding: "0.1rem .75rem",
    color: "#000000",
    border: "2px solid #ffffff"
  },
  subheader: {
    padding: "0.1rem .75rem",
    textTransform: "uppercase",
    fontWeight: 500,
    textAlign: "right"
  }
});

interface ComputeHistoryTableProps {
  usages: PeriodUsageSummary[];
}

/**
 * ComputeHistoryTable shows a system usage breakdown over time and
 * per user
 * @param usages period usage items returned by the developer console API
 */
const ComputeHistoryTable: React.FC<ComputeHistoryTableProps> = ({
  usages
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const rows = usages.map((usage, index) => (
    <ComputeHistoryRow period={usage} key={index} />
  ));

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell className={classes.filledHeader}>
            {t("usage.history.table.header.periodEnding")}
          </TableCell>
          <TableCell className={classes.filledHeader}>
            {t("usage.history.table.header.user")}
          </TableCell>
          <TableCell
            colSpan={2}
            className={classes.filledHeader}
            style={{ textAlign: "right" }}
          >
            {t("usage.history.table.header.compute")}
          </TableCell>
          <TableCell
            colSpan={matches ? 0 : 1}
            className={classes.filledHeader}
            style={{ textAlign: "right" }}
          >
            {t("usage.history.table.header.computeMemory")}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableHead className={classes.subheader}>
        <TableRow>
          <TableCell />
          <TableCell />
          {/* Compute sub-headers */}

          <TableCell>
            {t("usage.history.table.subheader.compute.vcpu")}
          </TableCell>
          <TableCell>
            {t("usage.history.table.subheader.compute.fpga")}
          </TableCell>

          <Hidden mdDown>
            <TableCell>
              {t("usage.history.table.subheader.compute.memgb")}
            </TableCell>
          </Hidden>
        </TableRow>
      </TableHead>
      <TableBody>{rows}</TableBody>
    </Table>
  );
};

export default observer(ComputeHistoryTable);
