import React from "react";

import LandingView from "../../views/LandingView";
import SmartRoute from "./SmartRoute";

/**
 * DefaultRoute is rendered at '/' and may not be
 * reachable again via nav drawer
 */
const LandingRoute = (
  <SmartRoute
    key="landing"
    exact
    path="/"
    component={LandingView}
    isProtected={false}
  />
);

export default LandingRoute;
