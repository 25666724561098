import React, { useContext } from "react";
import { observer } from "mobx-react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";

import useGlobalStyles from "../../styles/classes";
import TokenGeneratorIntro from "./TokenGeneratorIntro";
import TokenGeneratorContainer from "./TokenGeneratorContainer";
import PaddedDivider from "../layout/PaddedDivider";
import TokenManagementContainer from "./management/TokenManagementContainer";
import RootStoreContext from "../../contexts/RootStoreContext";

/**
 * TokenContainer is a high-level composition of the tokens
 * view, including new token generation and existing token
 * management
 */
const TokenContainer: React.FC = () => {
  const [t] = useTranslation();
  const globalClasses = useGlobalStyles();
  const { authStore } = useContext(RootStoreContext);
  return (
    <>
      <Grid item xs={12} className={globalClasses.paddedContainer}>
        <Typography variant="h2">{t("tokens.title")}</Typography>
      </Grid>
      <Grid
        item
        md={4}
        sm={6}
        xs={12}
        className={globalClasses.paddedContainer}
      >
        <TokenGeneratorIntro />
      </Grid>

      <Grid
        item
        md={8}
        sm={6}
        xs={12}
        className={globalClasses.paddedContainer}
      >
        <TokenGeneratorContainer />
      </Grid>

      <PaddedDivider />

      {// only show token management when extra tokens are present
      authStore.additionalJWT.length > 0 && (
        <>
          <Grid item xs={12} className={globalClasses.paddedContainer}>
            <Typography variant="h2">{t("tokens.management.title")}</Typography>
          </Grid>

          <Grid item xs={12} className={globalClasses.paddedContainer}>
            <TokenManagementContainer />
          </Grid>
        </>
      )}
    </>
  );
};

export default observer(TokenContainer);
