import React from "react";
import Card from "@material-ui/core/Card";
import { CardContent, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { JWT } from "../../../util";
import TokenDisplay from "../display/TokenDisplay";

const useStyles = makeStyles({
  heading: {
    paddingTop: "1rem",
    fontSize: "smaller"
  },
  card: {
    borderLeft: "8px solid #BAC1CB",
    paddingLeft: ".8rem"
  },
  token: {
    paddingBottom: ".8rem",
    color: "#4F5A67",
    fontWeight: 500
  }
});

interface NewTokenCardProps {
  token: string;
}

/**
 * NewTokenCard is a material card for presenting a JWT
 * @param token — a valid JWT string
 */
const NewTokenCard: React.FC<NewTokenCardProps> = ({ token }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const data = new JWT(token);
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h3" component="h5" className={classes.heading}>
          {t("tokens.new.heading")}
        </Typography>
        <Typography
          variant="body1"
          component="section"
          className={classes.token}
          gutterBottom
        >
          <TokenDisplay token={token} />
        </Typography>
        <Typography variant="body2" component="p">
          {t("tokens.new.createdLabel")}:&nbsp;
          {t("tokens.new.createdDate", { createdDate: data.validFromDate })}
        </Typography>
        <Typography variant="body2" component="p">
          {t("tokens.new.expiresLabel")}:&nbsp;
          {t("tokens.new.expiresDate", { expiresDate: data.expiresDate })}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default NewTokenCard;
