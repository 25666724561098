import { JWTData } from "../types";
import jwt_decode from "jwt-decode";
import { ServiceError } from "../types";

/**
 * Reformat simple CamelCaseString to Spaced Out String
 * NB. won't work well for acronyms, other edge cases.
 */
export const camelSpace = (s: string) =>
  s && s.replace(/([a-z])([A-Z])/g, "$1 $2");

interface NetworkError extends Error {
  httpStatusCode: number;
}

const networkError = (response: Response) => {
  const err = new Error(
    `Network error: ${response.statusText} (${response.status})`
  ) as NetworkError;
  err.httpStatusCode = response.status;
  return err;
};

/**
 * handleAPIError attempts to impose type safety on errors
 * being thrown by APIs as called by their SDK. Fallback is
 * to throw a generic unknown error.
 * @param err an error
 */
export const handleAPIError = async (err: Response | unknown) => {
  // try here catches e.g. JSON parsing fails
  if (err instanceof Response) {
    const responseError = err;
    let json: object;
    const statusCode = responseError.status;
    try {
      json = await err.json();
    } catch (err) {
      if (responseError.status >= 300) {
        throw networkError(responseError);
      }
      throw new Error("Error: Malformed JSON response");
    }

    if ("code" in json && "message" in json) {
      const e = json as ServiceError;
      // well-formed errors from an API
      console.error(JSON.stringify(e));
      const serviceError = new Error(`${e.code}: ${e.message}`);
      (serviceError as NetworkError).httpStatusCode = statusCode;
      throw serviceError;
    }
    // more general errors, e.g. 404
    throw networkError(responseError);
  }

  // re-raise existing errors
  if (err instanceof Error) {
    throw new Error(`Error: ${err.message}`);
  }

  // this could be anything
  console.error(err);
  throw new Error(`Something went wrong!`);
};

/**
 * timeout waits for a promise to evaluate and times
 * it out if it takes too long
 * @param promise - any promise, such as the result of a fetch
 * @param seconds - seconds to wait for promise to be resolved / rejected
 */
export const timeout = (fn: Promise<any>, seconds = 30) => {
  return Promise.race([
    fn,
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Request timed out")), seconds * 1000)
    )
  ]);
};

/** JWT class for working with JSON web
 * tokens and their data.
 */
export class JWT {
  readonly data: JWTData;
  private readonly token: string;

  constructor(JWT: string) {
    try {
      this.token = JWT;
      this.data = jwt_decode(JWT);
    } catch (e) {
      throw new Error(`Token is not a valid JWT: ${JWT}`);
    }
  }

  toString(): string {
    return this.token;
  }

  get scopes(): string {
    if (this.data.scope) {
      return this.data.scope;
    }
    return "";
  }

  get expiresDate() {
    return new Date(this.data.exp * 1000);
  }

  get validFromDate() {
    return new Date(this.data.iat * 1000);
  }

  get isExpired() {
    if (this.expiresDate.getTime() < new Date().getTime()) {
      return true;
    }
    return false;
  }
}

/** Expo round to avoid obscure floating point issue with Math.round */
export const smartRound = (value: number, places = 2) => {
  return Number(
    Math.round((`${value}e${places}` as unknown) as number) + "e-" + places
  );
};
