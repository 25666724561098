import React from "react";
import { useTranslation } from "react-i18next";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { WorkflowRun, WorkflowVersion } from "@stratus/wes";
import { RunLog } from "@stratus/ga4gh";
import CodeBlock from "../../layout/CodeBlock";
import ISLGraph from "../graph/ISLGraph";
import CardHeader from "@material-ui/core/CardHeader";

interface WorkflowDetailsCardProps {
  workflowRun: WorkflowRun;
  workflowVersion: WorkflowVersion;
  runLogs: RunLog;
}

const WorkflowDetailsCard: React.FC<WorkflowDetailsCardProps> = ({
  workflowRun,
  workflowVersion,
  runLogs
}) => {
  const [t] = useTranslation();

  if (!workflowVersion.language || workflowVersion.language.name !== "ISL") {
    return null;
  }

  return (
    <Card style={{ padding: 8 }}>
      <CardHeader title={<Typography variant="h3">Run Status</Typography>} />
      <CardContent style={{ padding: "16px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {/* Workflow definition, if available */}
            <Typography variant="h4">{t("workflows.graph.header")}</Typography>

            {workflowVersion.definition ? (
              <ISLGraph
                definition={workflowVersion.definition}
                taskRuns={runLogs.task_logs || []}
              />
            ) : (
              "No workflow definition available"
            )}
          </Grid>

          {/* Task grid */}
          {/* <Grid item xs={12} md={6}>
            <Typography component="h3">
              {t("workflows.tasks.header")}
            </Typography>
            <WorkflowTaskRunTable taskLogs={runLogs.task_logs || []} />
          </Grid> */}
          {/* Workflow run output */}

          <Grid item xs={12} md={6}>
            <Typography variant="h4">{t("workflows.output.header")}</Typography>

            {workflowRun.output ? (
              // dimensions set to sit neatly beside ISL graph
              <CodeBlock minHeight={352} maxHeight={"93.5%"}>
                {JSON.stringify(workflowRun.output, null, 2)}
              </CodeBlock>
            ) : (
              "No workflow output available"
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default WorkflowDetailsCard;
