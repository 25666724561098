import React from "react";

import { TaskMetric } from "./PodMetricsType";
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  AreaChart,
  Brush
} from "recharts";
import { DateTime } from "luxon";
import { Typography } from "@material-ui/core";

import { smartRound } from "../../../util";

interface CPUGraphProps {
  cpuMetrics: TaskMetric[];
}

const graphDateFormatter = (unixTime: number | string) => {
  if (typeof unixTime === "string") {
    unixTime = parseInt(unixTime);
  }
  return DateTime.fromMillis(unixTime).toLocaleString(
    DateTime.TIME_24_WITH_SECONDS
  );
};

const CPUGraph: React.FC<CPUGraphProps> = ({ cpuMetrics }) => (
  <>
    {console.log(cpuMetrics)}
    <Typography variant="h4">CPUs</Typography>
    <ResponsiveContainer width="95%" height={118}>
      <AreaChart data={cpuMetrics} syncId="taskGraphs">
        <XAxis
          dataKey="time"
          domain={["dataMin", "dataMax"]}
          name="Time"
          tickFormatter={graphDateFormatter}
          type="number"
        />
        <YAxis dataKey="value" name="CPUs" type="number" />
        <Area
          type="monotone"
          dataKey="value"
          stroke="#14D4D1"
          fill="#14D4D1"
          name="CPUs"
        />
        <Tooltip
          formatter={(value: unknown) => smartRound(value as number)}
          labelFormatter={value => `Time : ${graphDateFormatter(value)}`}
          labelStyle={{ color: "#67788E" }}
        />
        <Brush
          dataKey="time"
          height={18}
          stroke="#67788E"
          tickFormatter={value => graphDateFormatter(value)}
        />
      </AreaChart>
    </ResponsiveContainer>
  </>
);

export default CPUGraph;
