import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";

import ExpandableOptionSet from "./ExpandableOptionSet";
import RootStoreContext from "../../../contexts/RootStoreContext";

/**
 * ScopesOptionContainer is a smart store-connected component
 * for choosing scopes to be applied to a token generation
 * request.
 */
const ScopesOptionContainer: React.FC = () => {
  const [t] = useTranslation();
  const { tokenStore } = useContext(RootStoreContext);

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {t("tokens.advanced.scopes.header")}
      </Typography>
      <Typography variant="body2">
        {t("tokens.advanced.scopes.description")}
      </Typography>

      <ExpandableOptionSet
        max={10}
        collector={tokenStore.setScopes}
        label="scope"
      />
    </>
  );
};

export default observer(ScopesOptionContainer);
