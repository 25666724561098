import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Collapse from "@material-ui/core/Collapse";

import RootStoreContext from "../../contexts/RootStoreContext";
import NewTokenCard from "./generation/NewTokenCard";
import AdvancedOptionForm from "./generation/AdvancedOptionForm";
import ErrorSnackbar from "../errors/ErrorSnackbar";

const useStyles = makeStyles(({ spacing }: Theme) => ({
  buttonRow: {
    paddingBottom: spacing(5)
  }
}));

/**
 * TokenGeneratorContainer is a smart store-connected component
 * that wraps token generation logic.
 */
const TokenGeneratorContainer: React.FC = () => {
  const { authStore, tokenStore } = useContext(RootStoreContext);
  const classes = useStyles();
  const [t] = useTranslation();

  // local component state
  const [generatingJWT, setGeneratingJWT] = useState(false);
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const [advancedVisible, setAdvancedVisible] = useState(false);

  const handleGenerateTokenClick = async () => {
    setGeneratingJWT(true);
    try {
      const newToken = await authStore.getJWT(
        false,
        tokenStore.apiKey,
        tokenStore.scopes,
        tokenStore.ACLs,
        tokenStore.mem,
        tokenStore.selectedWorkgroupID
      );
      setToken(newToken!);
    } catch (err) {
      setError(err.message);
      console.error(err);
    }
    setGeneratingJWT(false);
  };

  const handleAdvancedOptionsClick = () => {
    setAdvancedVisible(current => !current);
  };

  return (
    <>
      <Grid item xs={12}>
        <Collapse in={advancedVisible}>
          <AdvancedOptionForm />
        </Collapse>
      </Grid>
      <Grid item xs={12} className={classes.buttonRow}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleGenerateTokenClick()}
          disabled={generatingJWT}
        >
          {t("tokens.button.generate")}
        </Button>
        <Button
          variant="text"
          color="primary"
          onClick={handleAdvancedOptionsClick}
        >
          {advancedVisible
            ? t("tokens.button.hideAdvanced")
            : t("tokens.button.showAdvanced")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        {!!token && <NewTokenCard token={token} />}
        {error !== "" && (
          <ErrorSnackbar error={error} clearOnClose={setError} />
        )}
      </Grid>
    </>
  );
};

export default observer(TokenGeneratorContainer);
