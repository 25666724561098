import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import useGlobalStyles from "../../styles/classes";
import RootStoreContext from "../../contexts/RootStoreContext";
import PaddedDivider from "../layout/PaddedDivider";
import UsageSummary from "./UsageSummary";
import UsageHistory from "./UsageHistory";
import ErrorSnackbar from "../errors/ErrorSnackbar";
import { useTranslation } from "react-i18next";
import ComputeSummary from "./summary/ComputeSummary";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

/**
 * UsageContainer is the smart store-connecter parent
 * of usage components.
 */
const UsageContainer: React.FC = () => {
  const globalClasses = useGlobalStyles();
  const { usageStore, authStore } = useContext(RootStoreContext);
  const [error, setError] = useState("");
  const [periods, setPeriods] = useState(1);
  const [isLoadingSummary, setIsLoadingSummary] = useState(true);
  const [isLoadingHistory, setIsLoadingHistory] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [t] = useTranslation();

  useEffect(() => {
    let active = true;
    if (active && authStore.isInitialised) {
      (async () => {
        setIsLoadingSummary(true);
        setIsLoadingHistory(true);
        try {
          await usageStore.getUsage(periods);
        } catch (err) {
          setError(err.message);
        } finally {
          if (active) {
            setIsLoadingHistory(false);
            setIsLoadingSummary(false);
          }
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [usageStore, authStore.isInitialised, periods]);

  return (
    <>
      <Grid container className={globalClasses.tabBar} justify="space-between">
        <Grid item>
          <Tabs value={selectedTab} onChange={(_, v) => setSelectedTab(v)}>
            <Tab label={t("usage.summary.storage")} />
            <Tab label={t("usage.summary.compute")} />
          </Tabs>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={usageStore.billing}
                onChange={usageStore.toggleBilling}
              />
            }
            label="iCredits"
          />
        </Grid>
      </Grid>

      <Grid item xs={12} className={globalClasses.paddedContainer}>
        {selectedTab === 0 && (
          <UsageSummary
            currentUsage={usageStore.currentPeriodUsage!}
            totalStorage={usageStore.storageTotal}
            totalTransfer={usageStore.transferTotal}
            isLoading={isLoadingSummary}
            billing={usageStore.billing}
          />
        )}
        {selectedTab === 1 && (
          <ComputeSummary
            currentUsage={usageStore.currentPeriodUsage!}
            billing={usageStore.billing}
          />
        )}
      </Grid>

      <PaddedDivider />

      <Grid item xs={12} className={globalClasses.paddedContainer}>
        {selectedTab === 0 && (
          <UsageHistory
            title={t("usage.history.storageHistory")}
            type={"Storage"}
            periods={periods}
            usagePeriods={usageStore.usage}
            periodSetter={setPeriods}
            loading={isLoadingHistory}
          />
        )}
        {selectedTab === 1 && (
          <UsageHistory
            title={t("usage.history.computeHistory")}
            type={"Compute"}
            usagePeriods={usageStore.usage}
            periods={periods}
            periodSetter={setPeriods}
            loading={isLoadingHistory}
          />
        )}
      </Grid>

      {error !== "" && <ErrorSnackbar error={error} clearOnClose={setError} />}
    </>
  );
};

export default observer(UsageContainer);
