import React from "react";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
// import Link from "@material-ui/core/Link";

import { useTranslation } from "react-i18next";
// import { makeStyles } from "@material-ui/core/styles";

// const useStyles = makeStyles({
//   textLink: {
//     verticalAlign: "baseline"
//   }
// });

interface UserTableRowHeaderProps {
  rows: number;
  date: Date;
  csvClick: () => void;
}

/**
 * UserTableRowHeader renders the first row + cell of a usage history
 * table.
 */
const UserTableRowHeader: React.FC<UserTableRowHeaderProps> = ({
  rows,
  date,
  csvClick
}) => {
  const [t] = useTranslation();
  // const classes = useStyles();
  return (
    <TableCell
      rowSpan={rows}
      style={{ whiteSpace: "nowrap", verticalAlign: "baseline" }}
    >
      <Typography>{t("common:ymd", { date })}</Typography>
      {/* <Typography variant="body2" component="p">
        {t("usage.history.table.header.download")}:&nbsp;
        <Link
          component="button"
          className={classes.textLink}
          onClick={csvClick}
        >
          CSV
        </Link>
      </Typography> */}
    </TableCell>
  );
};

export default UserTableRowHeader;
