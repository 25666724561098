import { observable, action, runInAction, computed } from "mobx";
import { Cookies } from "react-cookie";
import { create, persist } from "mobx-persist";
import localForage from "localforage";
import decode from "jwt-decode";

import {
  COOKIE_DOMAIN,
  API_BASE,
  PLATFORM_PUBLIC_HOME,
  PLATFORM,
  BASE_URL
} from "../../constants";
import { TokenResponse, TokensApi } from "@stratus/console";
import { JWTData } from "../../types";
import { timeout, JWT } from "../../util";

import { handleAPIError } from "../../util";

/**
 * AuthStore is a MobX domain store for authentication
 * data including session tokens and JWTs. Store actions
 * use the developer console API to generate, refresh
 * and revoke these tokens.
 * @class
 */
class AuthStore {
  /** Platform session token */
  @observable psToken = "";
  @observable isLoggedIn = false;

  /** Active JWT being used to call APIs */
  @observable JWT: string | undefined = undefined;
  /** Decoded active JWT */
  @observable JWTFields: JWTData | undefined = undefined;

  /** Is this auth store fully initialised */
  @observable isInitialised = false;

  /** Active workgroup context, if any */
  @observable activeWorkgroupContext: string | undefined = undefined;

  /** Additional JWTs generated by the current session */
  @persist("list") @observable additionalJWT: string[] = [];

  private cookies: Cookies;
  private readonly tokenCookieName = "psToken";
  private readonly domainCookieName = "currentDomain";
  private readonly workgroupStorageName = "workgroupContext";
  private jwtCookieName = "JWT";
  private api: TokensApi;

  constructor() {
    this.cookies = new Cookies();
    this.api = new TokensApi({ basePath: API_BASE });
    this.psToken = this.cookies.get(this.tokenCookieName) || "";
    this.JWT = localStorage.getItem(this.jwtCookieName) || "";
    this.activeWorkgroupContext =
      localStorage.getItem(this.workgroupStorageName) || undefined;
    this.isLoggedIn = !!this.psToken;
    if (this.isLoggedIn) {
      this.setDomainCookie(this.psTokenDomain);
    }
  }

  @action("refresh psToken and get new JWT")
  initialiseJWT = () => {
    // NB this can't happen in the constructor because
    // we need to hydrate the store first
    if (this.isLoggedIn) {
      this.refreshToken().then(ok => {
        if (ok) {
          this.getJWT()
            .then(() => {
              runInAction(() => {
                this.isInitialised = true;
              });
            })
            .catch(err => {
              console.error(err);
              this.reset("failed generating JWT");
            });
        }
      });
    }
  };

  @computed
  get psTokenDomain() {
    if (!this.psToken) {
      return this.cookies.get(this.domainCookieName);
    }

    // atob not available in node
    const decoded = Buffer.from(this.psToken, "base64").toString();
    if (!decoded.includes(",")) {
      //clean out the cookie session before throw
      this.reset("invalid psToken");
      return "";
    }
    const domain = decoded.split(",")[0];

    return domain;
  }

  // set cookie for sticky domain platform auth
  private setDomainCookie = (domain: string) => {
    this.cookies.set(this.domainCookieName, domain);
  };

  @action("reset authentication state to null")
  private reset = (reason?: string) => {
    if (reason) {
      console.log(`resetting auth state to logged out, reason: ${reason}`);
    }

    this.psToken = "";
    this.JWT = undefined;

    // browsers want path AND domain to delete clear these
    this.cookies.remove(this.tokenCookieName, {
      domain: COOKIE_DOMAIN,
      path: "/"
    });

    localStorage.removeItem(this.jwtCookieName);
    localStorage.removeItem(this.workgroupStorageName);

    // isLoggedIn observable triggers redirect, should be set last
    this.isLoggedIn = false;
  };

  /**
   * refreshToken posts the current active token with the :refresh action
   */
  refreshToken = async (): Promise<boolean> => {
    try {
      await timeout(this.api.refreshToken({ access_token: this.psToken }));
      return true;
    } catch (errResponse) {
      console.error(errResponse);
      this.reset("refresh failed");
      return false;
    }
  };

  /**
   * logOut revokes active token and deletes the psToken cookie
   */
  @action("log out authenticated user")
  logOut = async () => {
    this.reset("user logged out");

    //send to logout URL with callback
    let platformLoginBaseUrl = PLATFORM;
    window.location.replace(
      `${platformLoginBaseUrl}/Session/Logout?callback=${window.location.href}`
    );
  };

  /**
   * logIn redirects end user to platform login
   */
  @action("log in")
  login = async (location?: string) => {
    //platform base login URL
    let platformLoginBaseUrl = PLATFORM;

    //placeholder url, the actual redirect, used to know which domain we are in
    let rURL = PLATFORM_PUBLIC_HOME;
    const domain = this.psTokenDomain;
    if (domain) {
      rURL = rURL.replace("accounts", domain);
    }
    //actual redirect path for app
    const redirectionPath = location ? `${location}` : `/dashboard`;
    const encodedUrl = Buffer.from(
      BASE_URL + "/console/#" + redirectionPath
    ).toString("base64");
    let loginUrl = `${platformLoginBaseUrl}?rURL=${rURL}&redirectMethod=GET&clientId=ps-home&clientVars=${encodedUrl}#/`;
    // in development (e.g. yarn start) we're redirecting to
    // platform with a direct return URL to the localhost hostname
    // but for prod (yarn build) login goes to a multi-app domain
    // landing page
    if (!this.isLoggedIn) {
      if (typeof window !== "undefined") {
        window.location.replace(loginUrl);
      }
    }
  };

  /**
   * Revoke a JWT using the developer console API
   * @param {string} token - an active JWT
   */
  revokeToken = async (token?: string) => {
    const revokePayload = {
      access_token: token ? token : this.JWT
    };
    try {
      await this.api.revokeToken(revokePayload);
    } catch (errResponse) {
      console.error(`failed revoking JWT: ${errResponse}`);
    }
  };

  /**
   * Get current active JWT or generate a new one
   */
  @action("Get current JWT or generate and get if expired")
  getJwtBearer = async () => {
    if (this.JWT === undefined || this.isJwtExpired(this.JWT)) {
      const jwtResp = await this.getJWT();
      return `Bearer ${jwtResp}`;
    }
    return `Bearer ${this.JWT}`;
  };

  /**
   * changeContext takes a CWID string and generates a new
   * JWT. It adds the ID to the store so that future JWT
   * refreshes stay in the same workgroup.
   * @param {newContextID} string - IAP workgroup GUUID or
   *     undefined to reset
   */
  @action("change active workgroup context")
  changeContext = async (newContextID?: string) => {
    console.log(`changing workgroup context to: ${newContextID}`);
    runInAction(() => {
      this.activeWorkgroupContext = newContextID;
      this.isInitialised = false;
    });
    await this.getJWT();
    if (newContextID) {
      localStorage.setItem(this.workgroupStorageName, newContextID);
    } else {
      localStorage.removeItem(this.workgroupStorageName);
    }
    runInAction(() => {
      this.isInitialised = true;
    });
  };

  /**
   * Convert a platform session token to a JWT
   * @param {boolean} store - keep this token as the active
   *     JWT for making API calls to other platform services
   */
  @action("convert psToken to JWT")
  getJWT = async (
    store: boolean = true,
    apiKey?: string,
    scopes?: string[],
    ACLs?: string[],
    mem?: string[],
    CurrentWorkgroup?: string
  ): Promise<string | undefined> => {
    // custom api instance with
    const psTokenApi = new TokensApi({
      basePath: API_BASE,
      apiKey: `Bearer ${this.psToken}`
    });
    try {
      console.log(`active workgroup: ${this.activeWorkgroupContext}`);
      const response = (await timeout(
        psTokenApi.createToken(
          apiKey || undefined,
          undefined,
          apiKey ? undefined : this.psToken,
          scopes || undefined,
          ACLs || undefined,
          mem || undefined,
          CurrentWorkgroup || this.activeWorkgroupContext
        ),
        14 // can be slow
      )) as TokenResponse;
      if (response.access_token === undefined) {
        throw new Error("Authorization failed");
      }
      if (store) {
        runInAction(() => {
          this.JWT = response.access_token!;
          localStorage.setItem(this.jwtCookieName, this.JWT);
          try {
            this.JWTFields = response.access_token
              ? decode(response.access_token)
              : undefined;
          } catch (e) {
            throw new Error("invalid JWT");
          }
        });
      } else {
        runInAction(() => {
          this.additionalJWT.push(response.access_token!);
        });
      }
      return response.access_token;
    } catch (err) {
      await handleAPIError(err);
    }
  };

  @action("drop a JWT as specified by its index")
  removeAdditionalJWT = async (index: number) => {
    const revokeToken = this.additionalJWT[index];
    console.log(`revoking selected token: ${revokeToken} (number ${index})`);
    try {
      await this.revokeToken(revokeToken);
      runInAction(() => {
        this.additionalJWT.splice(index, 1);
      });
    } catch (errResponse) {
      console.error(`error revoking token: ${revokeToken}`);
    }
  };

  @action("clear expired JWTs")
  clearExpiredJWT = () => {
    if (!this.additionalJWT.length) {
      return;
    }
    this.additionalJWT = this.additionalJWT.filter(tokenString => {
      return !this.isJwtExpired(tokenString);
    });
  };

  isJwtExpired = (tokenString: string) => {
    let jwt: JWT;
    try {
      jwt = new JWT(tokenString);
    } catch (err) {
      return false;
    }
    return jwt.isExpired;
  };
}

const store = new AuthStore();

const hydrate = create({
  storage: localForage,
  jsonify: false
});

(async () => {
  await hydrate("auth", store).then(() => {
    console.log(`hydrated active workgroup ${store.activeWorkgroupContext}`);

    // these methods rely on potentially persisted data
    store.initialiseJWT();
  });
})();

export default store;
export type AuthStoreModel = typeof store;
