import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { PeriodUsageSummary } from "@stratus/console";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";

import ComputeHistoryTable from "./history/ComputeHistoryTable";
import { productType } from "../../types";
import StorageHistoryTable from "./history/StorageHistoryTable";
import useGlobalStyles from "../../styles/classes";
import { observer } from "mobx-react";

const useStyles = makeStyles(({ spacing }: Theme) => ({
  paddedTable: {
    marginBottom: spacing(4)
  },
  select: {
    maxWidth: "15rem"
  },
  headerRow: {
    marginBottom: spacing(2)
  }
}));

interface UsageHistoryProps {
  title: string;
  usagePeriods: PeriodUsageSummary[];
  periods: number;
  periodSetter: React.Dispatch<React.SetStateAction<number>>;
  type: productType;
  loading: boolean;
}

/**
 * UsageHistory presents a multi-period data-dense overview
 * of system usage for recent periods
 */
const UsageHistory: React.FC<UsageHistoryProps> = ({
  title,
  usagePeriods,
  periods,
  periodSetter,
  type,
  loading
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const periodOptions = [1, 3, 6];
  const [selectedPeriods, setSelectedPeriods] = useState(periods);

  const handlePeriodSelectChange = (event: React.ChangeEvent<any>) => {
    const selected = event.target.value as number;
    setSelectedPeriods(selected);
    periodSetter(selected);
  };

  return (
    <>
      <Grid container className={classes.headerRow}>
        <Grid item xs={6}>
          <Typography variant="h2" gutterBottom>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={6} className={globalClasses.rightFlex}>
          <FormControl variant="outlined" className={classes.select}>
            <Select
              onChange={handlePeriodSelectChange}
              variant="outlined"
              input={<Input name="periods" id="select-billing" />}
              value={selectedPeriods}
            >
              {periodOptions.map(i => (
                <MenuItem value={i} key={i}>
                  {i === 1
                    ? t("usage.history.showLastBillingPeriod")
                    : t("usage.history.showBillingPeriods", { count: i })}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {!loading && (
        <Grid item xs={12} className={classes.paddedTable}>
          {type === "Compute" && <ComputeHistoryTable usages={usagePeriods} />}
          {type === "Storage" && <StorageHistoryTable usages={usagePeriods} />}
        </Grid>
      )}
    </>
  );
};

export default observer(UsageHistory);
