import React from "react";
import { Chip, makeStyles } from "@material-ui/core";

import { SystemHealthResponseStatus } from "../../constants/enums";
import HealthStatusMap from "./HealthStatusMap";
import clsx from "clsx";

interface StyleProps {
  status: SystemHealthResponseStatus;
}

interface HealthStatusChipProps {
  status: SystemHealthResponseStatus;
}

const useStyles = makeStyles({
  root: {
    textTransform: "uppercase"
  },
  chip: ({ status }: StyleProps) => {
    switch (status) {
      case SystemHealthResponseStatus.Healthy:
        return {
          color: "#186621",
          background: "#DDF8E0"
        };
      case SystemHealthResponseStatus.Degraded:
        return {
          background: "#F5B500",
          color: "#FCEEEE"
        };
      case SystemHealthResponseStatus.Unhealthy:
        return { background: "#AE1B1F", color: "#FCEEEE" };
      default:
        return {};
    }
  }
});

/**
 * HealthStatusChip displays a service health status in a styled
 * Chip component for use in a summary table
 * @param status health status
 */
const HealthStatusChip: React.FC<HealthStatusChipProps> = ({ status }) => {
  const classes = useStyles({ status });

  return (
    <Chip
      label={<HealthStatusMap status={status} />}
      className={clsx(classes.root, classes.chip)}
    />
  );
};

export default HealthStatusChip;
