import { makeStyles, Theme } from "@material-ui/core/styles";

import React from "react";
import { Box, Typography } from "@material-ui/core";
import logo from "../../assets/illumina-logo.svg";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  dashLink: {
    textDecoration: "none",
    display: "flex",
    flexShrink: 0,
    alignItems: "center"
  },
  title: {
    fontSize: 22,
    fontWeight: 100,
    color: "#a3a3a3",
    marginLeft: theme.spacing(1)
  },
  logo: {
    minHeight: 32,
    maxHeight: "100%",
    maxWidth: "100%",
    minWidth: 96,
    marginTop: 5
  }
}));

const IlmnLogo: React.FC = () => {
  const classes = useStyles();
  return (
    <Link to="/" className={classes.dashLink}>
      <Box className={classes.logo}>
        <img src={logo} alt="logo" />
      </Box>
      <Typography className={classes.title}>Analytics Platform</Typography>
    </Link>
  );
};
export default IlmnLogo;
