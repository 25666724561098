import React, { useEffect, useState, useContext } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useParams, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FileResponse } from "@stratus/gds";
import { TaskRun } from "@stratus/tes";

import RootStoreContext from "../../contexts/RootStoreContext";
import TaskRunDetailsCard from "./TaskRunDetailsCard";
import useGlobalStyles from "../../styles/classes";
import PaddedDivider from "../layout/PaddedDivider";

/**
 * TaskRunDetailsContainer fetches task run details on load, parameterised
 * by the current URI. It presents details for a given task run including
 * a logs listing and resource usage metrics, if available.
 */
const TaskRunDetailsContainer: React.FC = () => {
  const { taskStore } = useContext(RootStoreContext);
  const [loaded, setIsLoaded] = useState(false);
  const [, setError] = useState("");
  const [taskDetails, setTaskDetails] = useState({} as TaskRun);
  const [taskLogs, setTaskLogs] = useState([] as FileResponse[]);
  const [is404, setIs404] = useState(false);
  const [t] = useTranslation();

  const globalClasses = useGlobalStyles();

  const { id } = useParams();

  useEffect(() => {
    let active = true;
    (async () => {
      setIsLoaded(false);
      try {
        const details = await taskStore.getTaskRunDetails(id!);
        active && setTaskDetails(details!);
        let logs = await taskStore.getTaskRunLogs(details!);
        if (logs && logs.length) {
          // sort logs so that the most recent retry (by -N) comes first
          // NB assumes log file output format: log-file-name-RETRY where
          // RETRY is an int
          logs.sort((a, b) => {
            // some names not suffixed
            let aString = a.name || "";
            let aRetry = 0;
            let bString = b.name || "";
            let bRetry = 0;

            if (a.name && a.name.includes("-")) {
              const aParts = a.name.split("-");
              aRetry = parseInt(aParts.pop() as string);
              aString = aParts.join().toLocaleLowerCase();
            }

            if (b.name && b.name.includes("-")) {
              const bParts = b.name.split("-");
              bRetry = parseInt(bParts.pop() as string);
              bString = bParts.join().toLocaleLowerCase();
            }

            if (aString !== bString) {
              return aString.localeCompare(bString);
            }

            if (aRetry > bRetry) {
              return -1;
            }
            if (bRetry > aRetry) {
              return 1;
            }
            return 0;
          });

          // split off task stdout / err to the front
          const front = logs.filter(log =>
            log.name!.toLowerCase().startsWith("task-")
          );

          const back = logs.filter(
            log => !log.name!.toLowerCase().startsWith("task-")
          );
          logs = [...front, ...back];
        }

        if (active) {
          setTaskLogs(logs || []);
          setIsLoaded(true);
        }
      } catch (err) {
        // show 404 route where the workflow run is not found
        if ("httpStatusCode" in err && err.httpStatusCode === 404) {
          setIs404(true);
        } else {
          setError(err.message);
        }
        active && setError(err.message);
      }
    })();

    return () => {
      active = false;
    };
  }, [taskStore, id]);

  if (is404) {
    return <Redirect to={"/404"} />;
  }

  return (
    <>
      {loaded ? (
        <>
          <Grid item xs={12} className={globalClasses.paddedContainer}>
            <Typography variant="h2">
              {taskDetails.name || t("task.details.noname")}
            </Typography>
          </Grid>
          <Grid item xs={12} className={globalClasses.paddedContainer}>
            <TaskRunDetailsCard
              taskDetails={taskDetails}
              logs={taskLogs.filter(log => log.sizeInBytes !== 0)}
            />
          </Grid>
          <PaddedDivider />
        </>
      ) : (
        <LinearProgress
          color="secondary"
          className={globalClasses.underAppBar}
          // needs to be inlined as globalClasses can get compiled to unspecific selector
          style={{ position: "absolute", minWidth: "100%" }}
        />
      )}
    </>
  );
};

export default TaskRunDetailsContainer;
