/**
 * SystemHealthResponseStatus contains known service statuses
 * returned by the console system health endpoint
 */
export enum SystemHealthResponseStatus {
  Unknown = "Unknown",
  Unhealthy = "Unhealthy",
  Degraded = "Degraded",
  Healthy = "Healthy"
}
