import React from "react";
import {
  Link,
  List,
  ListItem,
  makeStyles,
  Theme,
  Typography,
  Box
} from "@material-ui/core";
import { ResourceComponentProp } from "../../../types";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: "13px",
    fontWeight: 500,
    padding: 0,
    marginTop: "0.4rem",
    color: "#4F5A67"
  },
  item: {
    fontSize: "13px",
    margin: 0,
    padding: "0.2rem 0.5rem",
    color: theme.palette.primary.main,
    fontWeight: 300,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));

const ResourceLinks: React.FC<{ resources: ResourceComponentProp[] }> = ({
  resources
}) => {
  const classes = useStyles();
  const links = resources.map((resource, index) => (
    <List key={index} disablePadding={true}>
      {resource.name && (
        <Typography className={classes.title}>{resource.name}</Typography>
      )}
      {resource.resourceLinks.map(link => (
        <ListItem key={`${link.name}`} className={classes.item}>
          <Link underline="none" href={link.path}>
            {link.name}
          </Link>
        </ListItem>
      ))}
    </List>
  ));
  return <Box>{links}</Box>;
};

export default ResourceLinks;
