import React from "react";
import { makeStyles } from "@material-ui/styles";
import Dragen from "../../../assets/DRAGEN logo.png";
import { Box } from "@material-ui/core";

const useStyles = makeStyles({
  image: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "block"
  }
});

const getIcon = (iconName: String) => {
  if (iconName === "DRAGEN") {
    return Dragen;
  }
  return undefined;
};

const LandingContainerIcon: React.FC<{ iconName: string }> = ({ iconName }) => {
  const classes = useStyles();
  const icon = getIcon(iconName);
  if (icon === undefined) return null;
  return (
    <Box mb={5}>
      <img className={classes.image} src={icon} alt="" />
    </Box>
  );
};

export default LandingContainerIcon;
