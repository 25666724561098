import React, { useContext } from "react";
import { UserAggregatedUsage } from "@stratus/console";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/styles";

import { computeTypes } from "../../../constants";
import { usageDataGetter, computeTransform } from "../../../util/usage";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import RootStoreContext from "../../../contexts/RootStoreContext";

const useStyles = makeStyles({
  userCell: {
    fontWeight: 300,
    letterSpacing: 0
  }
});

interface ComputeUsageCellsProps {
  user: UserAggregatedUsage | undefined;
}

/**
 * ComputeUsageCells takes the usage information for a single user in a
 * single period and returns table cells for compute broken down by
 * sub-category.
 * @param user – usage API response for a single user in a given period
 */
const ComputeUsageCells: React.FC<ComputeUsageCellsProps> = ({ user }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const { usageStore } = useContext(RootStoreContext);

  return (
    <>
      <TableCell className={classes.userCell}>
        {user === undefined
          ? t("usage.history.table.row.unknownUser")
          : user.user
          ? user.user.fullName
          : t("usage.history.table.row.unknownUser")}
      </TableCell>

      {computeTypes.map((compute, index) => (
        <TableCell className={classes.userCell} key={index}>
          {compute === "MemoryGB"
            ? usageDataGetter(
                user && user.usages,
                "Compute",
                compute,
                usageStore.billing ? undefined : s => computeTransform(s, true),
                usageStore.billing
              )
            : usageDataGetter(
                user && user.usages,
                "Compute",
                compute,
                undefined,
                usageStore.billing
              )}
        </TableCell>
      ))}
    </>
  );
};

export default observer(ComputeUsageCells);
