import { action } from "mobx";
import { handleAPIError } from "../../util";
import rootStore from "../../stores/RootStore";

/**
 * DeviceStore is a MobX domain store for the device auth flow
 */
class DeviceStore {
  psToken: string = "";
  localPort: string | null = "";

  /**
   * Gets psToken and redirects to the callback
   */
  @action("Redirect user to localhost server for device flow auth with psToken")
  setPsTokenAndRedirect = async () => {
    try {
      this.psToken = rootStore.authStore.psToken;
      var hashArgs = window.location.hash.split("?");
      if (hashArgs.length > 1) {
        const urlParams = new URLSearchParams("?" + hashArgs[1]);
        this.localPort = urlParams.get("localPort");
      }
      var redirectUrl =
        "http://localhost:" +
        this.localPort +
        "/oauth/callback?&psToken=" +
        rootStore.authStore.psToken;
      window.location.href = redirectUrl;
    } catch (errResponse) {
      await handleAPIError(errResponse);
    }
  };
}

const store = new DeviceStore();
export default store;
