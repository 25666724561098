import React, { useContext } from "react";
import { observer } from "mobx-react";

import { makeStyles, Theme, Grid } from "@material-ui/core";

import RootStoreContext from "../../contexts/RootStoreContext";
import ScrollToTopButton from "./ScrollToTopButton";

interface styleProps {
  drawerWidth: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: styleProps) => ({
    flexGrow: 1,
    display: "flex",
    marginLeft: props.drawerWidth,
    paddingTop: theme.spacing(2),
    maxWidth: "100%",
    width: "inherit"
  })
}));

/**
 * Properly-spaced container for page contents
 */
const ContentContainer: React.FC = ({ children }) => {
  const { uiStore } = useContext(RootStoreContext);
  const { drawerWidth } = uiStore;
  const classes = useStyles({ drawerWidth });

  return (
    <Grid container className={classes.root}>
      {children}
      <ScrollToTopButton />
    </Grid>
  );
};

export default observer(ContentContainer);
