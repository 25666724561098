import React from "react";
import { useTranslation } from "react-i18next";

import { TaskRunSummary, TaskRun } from "@stratus/tes";

interface TaskRunStatusMapProps {
  status: TaskRunSummary.StatusEnum;
}

/**
 * TaskRunStatusMap is a small component for taking the reported
 * task run status from the TES and returning a UI-friendly +
 * localised string.
 * @param status task run status as returned by the API
 */
const TaskRunStatusMap: React.FC<TaskRunStatusMapProps> = ({ status }) => {
  const [t] = useTranslation();

  const statusMap = (status: TaskRun.StatusEnum) => {
    switch (status) {
      case TaskRun.StatusEnum.Completed:
        return t("task.status.completed");
      case TaskRun.StatusEnum.Aborted:
        return t("task.status.aborted");
      case TaskRun.StatusEnum.Failed:
        return t("task.status.failed");
      case TaskRun.StatusEnum.Running:
        return t("task.status.running");
      case TaskRun.StatusEnum.Pending:
        return t("task.status.pending");
      case TaskRun.StatusEnum.TimedOut:
        return t("task.status.timedout");
      default:
        return t("task.status.unknown");
    }
  };
  return <>{statusMap(status)}</>;
};

export default TaskRunStatusMap;
