import React, { useContext } from "react";
import RootStoreContext from "../contexts/RootStoreContext";

/**
 * DeviceView just kicks off auto redirect for device store
 */
const DeviceView: React.FC = () => {
  const { deviceStore } = useContext(RootStoreContext);
  deviceStore.setPsTokenAndRedirect();

  return <></>;
};

export default DeviceView;
