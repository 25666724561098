import { observable, action, runInAction } from "mobx";
import {
  TaskRunsApiFp,
  PagedItemsTaskRunSummary,
  TaskRunSummary,
  TaskRun
} from "@stratus/tes";

import { FilesApiFp, FileListResponse, FileResponse } from "@stratus/gds";

import authStore from "./AuthStore";
import { API_BASE } from "../../constants";
import { timeout, handleAPIError } from "../../util";

/**
 * TaskStore is a MobX domain store for task execution
 * service (TES) tasks and task runs
 */
class TaskStore {
  @observable taskRuns: Array<TaskRunSummary> = [];
  @observable sortKey: string = "timeModified";
  @observable sortDirection: "desc" | "asc" = "desc";

  // initalLoad specifies whether the first task run load
  // has completed, e.g. to enable infinite scrolling only
  // after the first request has completed
  @observable initialLoadComplete: boolean = false;
  @observable exhausted: boolean = false;

  // filters
  @observable filterStatus: string | undefined = undefined;
  @observable filterName: string | undefined = undefined;

  private readonly authStore = authStore;
  private nextPage: string | undefined = undefined;

  /**
   * getTaskRuns gathers a set of task runs
   */
  @action("call task runs endpoint and store result")
  getTaskRuns = async (status?: string, isFirst = false, pageSize = 100) => {
    let jwt = await this.authStore.getJwtBearer();
    if (jwt !== undefined) {
      try {
        const response = (await timeout(
          TaskRunsApiFp({
            basePath: API_BASE,
            apiKey: jwt
          }).listTaskRuns(
            `${this.sortKey} ${this.sortDirection}`,
            this.filterName,
            status || this.filterStatus,
            undefined,
            undefined,
            pageSize,
            this.nextPage
          )(undefined, API_BASE),
          20
        )) as PagedItemsTaskRunSummary;

        if (response && response.items && response.items.length > 0) {
          this.updateTaskRuns(response.items!);
          runInAction(() => {
            response.nextPageToken
              ? (this.nextPage = response.nextPageToken)
              : (this.exhausted = true);
          });
        } else {
          runInAction(() => {
            this.exhausted = true;
          });

          // no error for 0 available task runs, not an error
          if (response.itemCount === undefined || response.itemCount > 0) {
            throw new Error("Unexpected API response");
          }
        }
      } catch (err) {
        await handleAPIError(err);
      }

      runInAction(() => {
        if (!this.initialLoadComplete) {
          this.initialLoadComplete = true;
        }
      });
    }
  };

  @action
  updateTaskRuns = (runs: TaskRunSummary[]) => {
    this.taskRuns.push(...runs);
  };

  @action
  setFilterStatus = (status: string | undefined) => {
    if (status === "") {
      this.filterStatus = undefined;
    } else {
      this.filterStatus = status;
    }
  };

  @action
  setFilterTaskName = (nameFilter: string | undefined) => {
    if (nameFilter === "") {
      this.filterName = undefined;
    } else {
      this.filterName = nameFilter;
    }
  };

  /**
   * getTaskRunDetails returns details for a single task run
   */
  getTaskRunDetails = async (taskRunId: string) => {
    let jwt = await this.authStore.getJwtBearer();
    if (jwt !== undefined) {
      try {
        const response = (await timeout(
          TaskRunsApiFp({
            basePath: API_BASE,
            apiKey: jwt
          }).getTaskRun(taskRunId)(undefined, API_BASE),
          20
        )) as TaskRun;
        return response;
      } catch (err) {
        await handleAPIError(err);
      }
    }
  };

  @action("toggle sort direction for task runs")
  toggleSortDirection = () => {
    if (this.sortDirection === "asc") {
      this.sortDirection = "desc";
    } else {
      this.sortDirection = "asc";
    }
    this.resetTaskLists();
  };

  @action("update sort key for task runs")
  updateSortKey = (newKey: string) => {
    this.sortKey = newKey;
    this.resetTaskLists();
  };

  @action("reset tasks")
  resetTaskLists = (restore: boolean = true) => {
    this.nextPage = undefined;
    this.taskRuns = [];
    this.exhausted = false;
    this.initialLoadComplete = false;
    // this.sortDirection = "desc";
    if (restore) this.getTaskRuns();
  };

  getTaskRunLogs = async (
    task: TaskRun,
    logPath = "/var/log/tessystemlogs"
  ): Promise<FileResponse[] | undefined> => {
    let jwt = await this.authStore.getJwtBearer();
    if (jwt !== undefined) {
      if (task.execution && task.execution.outputs) {
        const logDir = task.execution.outputs.find(r => r.path === logPath);
        if (!logDir) {
          return undefined;
        }

        if (logDir.url && logDir.url.startsWith("gds://")) {
          const parts = logDir.url.split("/");
          const volumeName = parts[2];
          const path = parts.slice(3).join("/");
          const response = (await timeout(
            FilesApiFp({
              basePath: API_BASE,
              apiKey: jwt
            }).listFiles(
              100,
              undefined,
              [volumeName],
              ["/" + path + "/*"],
              true,
              undefined,
              undefined,
              "preSignedUrl"
            )(undefined, API_BASE)
          )) as FileListResponse;

          return response.items;
        }
      }
    }
  };
}

const store = new TaskStore();
export default store;

export type TaskStoreModel = typeof store;
