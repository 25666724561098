import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import UsageContainer from "../components/usage/UsageContainer";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start"
  }
});

/**
 * UsageView wraps the usage page
 */
const UsageView: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <UsageContainer />
    </Grid>
  );
};

export default UsageView;
