import React from "react";
import { makeStyles, Theme, Slide, Fab } from "@material-ui/core";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    position: "fixed",
    bottom: spacing(2),
    right: spacing(2)
  }
}));

interface ScrollToTopButtonProps {
  selector?: string;
}

/**
 * ScrollToTopButton is a floating action button which appears
 * by scroll trigger and scrolls back to either the root element
 * or a provided selector.
 * Adapted from material-ui docs example:
 *   https://material-ui.com/components/app-bar/#BackToTop.tsx
 *
 * @param selector a selector to scroll into view on click
 */
const ScrollToTopButton: React.FC<ScrollToTopButtonProps> = ({ selector }) => {
  const classes = useStyles();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100
  });

  const handleClick = (_: React.MouseEvent<HTMLDivElement>) => {
    const anchor = document.querySelector(selector || "#root");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <Slide direction="up" in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        <Fab color="secondary" size="small" aria-label="scroll to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </div>
    </Slide>
  );
};

export default ScrollToTopButton;
