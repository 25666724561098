import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";

import RootStoreContext from "../../../contexts/RootStoreContext";
import { useTranslation } from "react-i18next";

interface SelectOption {
  label: string;
  value?: string;
}

const useStyles = makeStyles({
  select: {
    maxHeight: "12rem",
    overflowY: "auto",
    // this applies to the Personal workgroup, first in the select
    "& ul > li:first-child": {
      fontWeight: 500
      // borderBottom: "1px solid #ddd"
    }
  },
  text: {
    fontSize: ".85rem"
  }
});

interface WorkgroupSelectProps {
  closer: () => void;
}

/**
 * WorkgroupSelect renders available workgroups and allows
 * changing the active workgroup context.
 */
const WorkgroupSelect: React.FC<WorkgroupSelectProps> = ({ closer }) => {
  const { tokenStore, authStore } = useContext(RootStoreContext);

  const [options, setOptions] = useState([] as SelectOption[]);
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const [t] = useTranslation();

  useEffect(() => {
    let active = true;
    const personalSelectOption: SelectOption = {
      label: t("nav.personalWorkgroup")
    };
    active &&
      (async () => {
        try {
          setIsLoading(true);
          await tokenStore.getWorkgroupIDs();
          const opts = tokenStore.workgroups
            .map(wg => {
              return {
                value: wg.id || "",
                label: wg.name || "Unnamed workgroup"
              };
            })
            .sort((a, b) => a.label.localeCompare(b.label));
          setOptions([personalSelectOption, ...opts]);
          setIsLoading(false);
        } catch (err) {
          console.error(err);
          setOptions([]);
        }
      })();
    return () => {
      setOptions([]);
      active = false;
    };
  }, [tokenStore, t]);

  const handleWorkflowSelectClick = async (workgroupId?: string) => {
    await authStore.changeContext(workgroupId);
    closer();
  };

  return (
    <Paper className={classes.select} elevation={0}>
      <Collapse in={!isLoading}>
        <MenuList id="workgroup-switcher">
          {options.map(option => (
            <MenuItem
              key={option.value || "personal"}
              className={classes.text}
              onClick={() => handleWorkflowSelectClick(option.value)}
            >
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </Collapse>
    </Paper>
  );
};

export default observer(WorkgroupSelect);
