import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { makeStyles, Theme } from "@material-ui/core";

import { ComponentWithID } from "../../../types";
import InputOptionForm from "./InputOptionForm";

const useStyles = makeStyles(({ spacing }: Theme) => ({
  input: {
    minWidth: "50%",
    maxWidth: "90%",
    display: "flex"
  },
  removeButton: {
    padding: "0px",
    minWidth: 30,
    minHeight: 30,
    marginTop: 4,
    marginLeft: spacing(1)
  },
  addButton: {
    marginBottom: spacing(3)
  },
  form: {
    display: "flex",
    alignItems: "center"
  },
  optionRow: {
    display: "flex"
  }
}));

interface ExpandableOptionSetProps {
  max: number;
  collector: (array: any[]) => void;
  label?: string;
}

interface ValueComponentWithID extends ComponentWithID {
  value: string;
}

/**
 * ExpandableOptionSet renders a vertical set of inputs with add
 * and remove controls, up to a maximum number of inputs
 * @param max – maximum number of input rows to allow (0 for unlimited)
 */
const ExpandableOptionSet: React.FC<ExpandableOptionSetProps> = ({
  max,
  collector,
  label
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [items, setItems] = useState([] as ValueComponentWithID[]);
  const [nextID, setNextID] = useState(1);

  // pass up active inputs to collector prop callback
  const storeSelected = (items: ValueComponentWithID[]) => {
    collector(items.map(i => i.value));
  };

  // remove row and adjust active inputs accordingly
  const removeOptionRow = (id: number) => () => {
    setItems(current => {
      const newList = current.filter(item => item.id !== id);
      storeSelected(newList);
      return newList;
    });
  };

  // gather inputs for all text fields
  const setInputValue = (id: number) => (
    newValue: React.ChangeEvent<HTMLInputElement>
  ) => {
    setItems(current => {
      const thisItem = current.findIndex(item => item.id === id);
      const newItem = current[thisItem];
      newItem.value = newValue.target.value;
      const newItems = [
        ...current.slice(0, thisItem),
        newItem,
        ...current.slice(thisItem + 1)
      ];
      storeSelected(newItems);
      return newItems;
    });
  };

  const handleAddItemClick = () => {
    setItems(current => {
      setNextID(current => current + 1);
      return current.concat({
        id: nextID,
        value: "",
        component: (
          <InputOptionForm
            key={nextID}
            removeInputHandler={removeOptionRow(nextID)}
            handleInput={setInputValue(nextID)}
            label={`${label}-${nextID}`}
          />
        )
      });
    });
  };

  return (
    <>
      {items.map(item => item.component)}

      <Button
        variant="text"
        color="primary"
        className={classes.addButton}
        onClick={() => handleAddItemClick()}
        disabled={max > 0 ? items.length >= max : false}
        id={`${label}-add-item`}
      >
        {t("tokens.advanced.addItem")}
      </Button>
    </>
  );
};

export default ExpandableOptionSet;
