import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";

import RootStoreContext from "../../../contexts/RootStoreContext";
import { makeStyles, TextField, Theme } from "@material-ui/core";

const useStyles = makeStyles(({ spacing }: Theme) => ({
  input: {
    minWidth: "50%",
    maxWidth: "90%",
    display: "flex"
  },
  form: {
    display: "flex",
    alignItems: "center",
    marginBottom: spacing(3)
  }
}));

const ApiKeyOptionContainer: React.FC = () => {
  const [t] = useTranslation();
  const { tokenStore } = useContext(RootStoreContext);
  const classes = useStyles();

  // pass input onChange up to parent component
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    tokenStore.setApiKey(e.target.value);
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {t("tokens.advanced.apiKey.header")}
      </Typography>
      <Typography variant="body2">
        {t("tokens.advanced.apiKey.description")}
      </Typography>

      <form noValidate autoComplete="off" className={classes.form}>
        <TextField
          id="api-key-input"
          placeholder="string"
          margin="dense"
          variant="outlined"
          className={classes.input}
          onChange={handleInputChange}
        />
      </form>
    </>
  );
};

export default observer(ApiKeyOptionContainer);
