import { observable, action } from "mobx";

import authStore from "./AuthStore";
import {
  WorkgroupsApiFp,
  Workgroup,
  WorkgroupResponse
} from "@stratus/console";
import { API_BASE } from "../../constants";
import { handleAPIError, timeout } from "../../util";

/**
 * TokenOptionStore is a MobX domain store for advanced
 * token generation options as set by multiple controls
 */
class TokenOptionStore {
  @observable scopes: string[] = [];
  @observable ACLs: string[] = [];
  @observable mem: string[] = [];
  @observable workgroups: Workgroup[] = [];
  @observable apiKey: string = "";
  @observable selectedWorkgroupID: string = "";

  private readonly authStore = authStore;

  /**
   * setScopes stores scopes for a request
   */
  @action
  setScopes = (scopeList: string[]) => {
    this.scopes = scopeList;
  };

  /**
   * setACLs stores ACL options for a request
   */
  @action
  setACLs = (aclList: string[]) => {
    this.ACLs = aclList;
  };

  /**
   * setMembership sets the mem IDs for a JWT
   */
  @action
  setMembership = (memList: string[]) => {
    this.mem = memList;
  };

  @action
  setApiKey = (apiKey: string) => {
    this.apiKey = apiKey;
  };

  /**
   * getWorkgroups returns a list of available
   * workgroup IDs for a given token
   */
  @action("get workgroup IDs")
  getWorkgroupIDs = async () => {
    const token = this.authStore.psToken;
    if (!token) return;

    try {
      const response: WorkgroupResponse = await timeout(
        WorkgroupsApiFp({
          basePath: API_BASE,
          apiKey: `Bearer ${token}`
        }).listWorkgroups()(undefined, API_BASE)
      );

      if (response.items) {
        // if no workgroup membership, will be empty array
        this.workgroups = response.items;
      } else {
        throw new Error("Unexpected API response");
      }
    } catch (err) {
      await handleAPIError(err);
    }
  };

  @action
  setWorkgroup = (workgroupID: string) => {
    this.selectedWorkgroupID = workgroupID;
  };
}

const store = new TokenOptionStore();
export default store;

export type TokenOptionStoreModel = typeof store;
