import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";

import ExpandableOptionSet from "./ExpandableOptionSet";
import RootStoreContext from "../../../contexts/RootStoreContext";

const MembershipOptionForm: React.FC = () => {
  const [t] = useTranslation();
  const { tokenStore } = useContext(RootStoreContext);

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {t("tokens.advanced.mem.header")}
      </Typography>
      <Typography variant="body2">
        {t("tokens.advanced.mem.description")}
      </Typography>

      <ExpandableOptionSet
        max={5}
        collector={tokenStore.setMembership}
        label="mem"
      />
    </>
  );
};

export default observer(MembershipOptionForm);
