import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import WorkflowDetailsContainer from "../components/workflows/details/WorkflowDetailsContainer";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start"
  }
});

/**
 * WorkflowDetailsView lays out the view for an individual workflow run
 */
const WorkflowDetailsView: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <WorkflowDetailsContainer />
    </Grid>
  );
};

export default WorkflowDetailsView;
