import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  code: {
    padding: theme.spacing(1),
    maxWidth: "100%",
    backgroundColor: "#f5f5f5",
    overflow: "auto",
    margin: 0
  }
}));

interface CodeBlockProps {
  maxHeight?: string | number;
  minHeight?: string | number;
  isLoaded?: boolean;
}

/**
 * CodeBlock renders child text as fixed-width code
 */
const CodeBlock: React.FC<CodeBlockProps> = ({
  minHeight,
  maxHeight,
  children,
  isLoaded = true
}) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const max = maxHeight ? maxHeight : 350;
  const min = minHeight ? minHeight : max;

  return (
    <pre
      className={classes.code}
      style={{ maxHeight: max, minHeight: min, fontSize: "0.9rem" }}
    >
      {isLoaded ? children : t("task.details.loading")}
    </pre>
  );
};

export default CodeBlock;
