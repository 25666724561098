import React from "react";
import { PolarViewBox } from "recharts";
import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  mainLabel: {
    fontSize: "2rem"
  },
  units: {
    fontSize: "1rem"
  },
  subLabel: {
    fill: (theme.overrides!.MuiTypography!.h3! as any).color,
    fontWeight: "bold",
    fontSize: "1rem"
  }
}));

interface CustomLabelProps {
  viewBox?: PolarViewBox;
  amount: string;
  label: string;
}

/**
 * Middle text label for donut charts
 */
const DonutLabel: React.FC<CustomLabelProps> = ({ viewBox, amount, label }) => {
  const { cx, cy } = viewBox!; // parent component supplies this
  const classes = useStyles();
  const [value, unit] = amount.split(" ");

  return (
    <text
      x={cx}
      y={cy}
      fill="#3d405c"
      className="recharts-text recharts-label"
      textAnchor="middle"
      dominantBaseline="center"
    >
      <tspan dy="-.5rem" className={classes.mainLabel}>
        {value}
      </tspan>
      {unit && <tspan className={classes.units}>{" " + unit}</tspan>}
      <tspan dy="1.5rem" x="50%" className={classes.subLabel}>
        {label}
      </tspan>
    </text>
  );
};

export default DonutLabel;
