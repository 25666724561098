import React from "react";

import { Box, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import GeneticBanner from "../../assets/Genetic-Banner-Opacity.png";
import LandingContainer from "./common/LandingContainer";
import { ContainerComponentProp, LandingCardComponentProp } from "../../types";
import LandingCard from "./common/LandingCard";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.between("md", "xl")]: {
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${GeneticBanner})`
    }
  }
}));
const DragenContainer: React.FC<{ container: ContainerComponentProp }> = ({
  container
}) => {
  const landingItems = container.items.map((x: LandingCardComponentProp) => (
    <LandingCard key={x.name} landingCardProp={x} />
  ));
  return (
    <Box key={container.title} mt={6.25} mx={5}>
      <LandingContainer
        container={{ ...container, overrideStyles: useStyles() }}
      >
        {landingItems}
      </LandingContainer>
    </Box>
  );
};
export default DragenContainer;
