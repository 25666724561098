import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import TokenContainer from "../components/tokens/TokenContainer";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start"
  }
});

const TokenView: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.root}>
        <TokenContainer />
      </Grid>
    </>
  );
};

export default TokenView;
