import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import { TaskRun } from "@stratus/tes";
import TaskStatusChip from "./status/TaskStatusChip";
import RootStoreContext from "../../contexts/RootStoreContext";
import ErrorSnackbar from "../errors/ErrorSnackbar";

interface TaskRunDetailsListProps {
  task: TaskRun;
}

/**
 * TaskRunDetailsList takes a task run and presents a summary list of
 * key fields
 */
const TaskRunDetailsList: React.FC<TaskRunDetailsListProps> = ({ task }) => {
  const [t] = useTranslation();

  const { userStore } = useContext(RootStoreContext);
  const [userName, setUserName] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    let active = true;
    (async () => {
      try {
        if (active && task && task.createdBy) {
          const userResponse = await userStore.resolveUserId(task.createdBy);
          if (active && userResponse && userResponse.name) {
            setUserName(userResponse.name);
          }
        }
      } catch (err) {
        setError(err.message);
      }
    })();

    return () => {
      active = false;
    };
  }, [userStore, task]);

  return (
    <>
      <Typography variant="body1">{t("task.details.details")}</Typography>
      <Typography variant="body2" component="div">
        <List dense>
          <ListItem>
            <strong>{t("task.details.status")}:</strong>&nbsp;
            <TaskStatusChip status={task.status!} />
          </ListItem>

          <ListItem>
            <strong>{t("task.details.created")}:</strong>&nbsp;
            {t("common:date", {
              date: new Date((task.timeCreated as unknown) as string)
            })}
          </ListItem>
          <ListItem>
            <strong>{t("task.details.createdBy").replace(" ", `\xa0`)}:</strong>
            &nbsp;
            {userName ? userName : task.createdBy}
          </ListItem>
          <ListItem>
            <strong>{t("task.details.modified")}:</strong>&nbsp;
            {t("common:date", {
              date: new Date((task.timeModified as unknown) as string)
            })}
          </ListItem>
        </List>
      </Typography>

      {/* Execution details for the tasl */}
      {task.execution && (
        <>
          <Typography variant="body1">{t("task.details.execution")}</Typography>
          <Typography variant="body2" component="div">
            <List dense>
              {/* docker image */}
              {task.execution.image && (
                <ListItem>
                  <strong>{t("task.details.container")}:</strong>&nbsp;
                  <code>
                    {task.execution.image.name}
                    {task.execution.image.tag && `:${task.execution.image.tag}`}
                  </code>
                </ListItem>
              )}

              {/* instance */}
              {task.execution.environment &&
                task.execution.environment.resources && (
                  <>
                    {task.execution.environment.resources.type && (
                      <ListItem>
                        <strong>{t("task.details.instanceType")}:</strong>&nbsp;
                        <Chip
                          variant="outlined"
                          color="secondary"
                          label={task.execution.environment.resources.type}
                        />
                      </ListItem>
                    )}
                    {task.execution.environment.resources.size && (
                      <ListItem>
                        <strong>{t("task.details.instanceSize")}:</strong>&nbsp;
                        <Chip
                          variant="outlined"
                          color="primary"
                          label={task.execution.environment.resources.size}
                        />
                      </ListItem>
                    )}

                    {/* Currently cores and memory are always present as 0  */}
                    {task.execution.environment.resources.cpuCores !== 0 && (
                      <ListItem>
                        <strong>{t("task.details.cores")}:</strong>&nbsp;
                        <Chip
                          variant="outlined"
                          color="secondary"
                          label={task.execution.environment.resources.cpuCores}
                        />
                      </ListItem>
                    )}
                    {task.execution.environment.resources.memoryGb !== 0 && (
                      <ListItem>
                        <strong>{t("task.details.memory")}:</strong>&nbsp;
                        <Chip
                          variant="outlined"
                          color="primary"
                          label={`${task.execution.environment.resources.memoryGb} GiB`}
                        />
                      </ListItem>
                    )}
                  </>
                )}
            </List>
          </Typography>
        </>
      )}

      {error !== "" && <ErrorSnackbar error={error} clearOnClose={setError} />}
    </>
  );
};

export default observer(TaskRunDetailsList);
