import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Link, LinkProps } from "react-router-dom";
import RootStoreContext from "../../contexts/RootStoreContext";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  link: {
    textDecoration: "none",
    color: "inherit"
  }
});

interface RouteLinkProps extends Partial<LinkProps> {
  path: string;
}

/**
 * RouteLink wraps a link to an app view and notifies the UI
 * store when the route is changed
 */
const RouteLink: React.FC<RouteLinkProps> = ({ path, children, ...props }) => {
  const { uiStore } = useContext(RootStoreContext);
  const classes = useStyles();
  return (
    <Link
      to={path}
      onClick={() => uiStore.changeRoute(path)}
      className={classes.link}
      {...props}
    >
      {children}
    </Link>
  );
};

export default observer(RouteLink);
