import React from "react";
import { PeriodUsageSummary } from "@stratus/console";
import { TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import ComputeUsageCells from "./ComputeUsageCells";
import TotalComputeUsageCells from "./TotalComputeUsageCells";
import UserTableRowHeader from "./UserTableRowHeader";
import { usagePeriodToCSV, csvDownload } from "../../../util/usage";

const useStyles = makeStyles({
  totalRow: {
    background: "rgba(50, 142,209,.14)"
  }
});

interface HistoryPeriodRowProps {
  period: PeriodUsageSummary;
}

/**
 * ComputeHistoryRow is a table row render of usage history
 * for a given period
 */
const ComputeHistoryRow: React.FC<HistoryPeriodRowProps> = ({ period }) => {
  const classes = useStyles();

  const userUsage = period.userAggregatedUsages || [];
  const nRows = Math.max(userUsage.length + 1, 2);

  const handleCSVDownloadClick = () => {
    const downloadName = `${new Date(period.end!)
      .toISOString()
      .replace(/[ :,.]/g, "_") || "unnamed-usage-period"}-compute.csv`;
    csvDownload(usagePeriodToCSV(period, "Compute"), downloadName);
  };

  return (
    <>
      <TableRow>
        <UserTableRowHeader
          rows={nRows}
          date={new Date(period.end!)}
          csvClick={handleCSVDownloadClick}
        />

        {/* first user goes on this row */}
        <ComputeUsageCells
          user={userUsage && userUsage.length > 0 ? userUsage[0] : {}}
        />
      </TableRow>

      {/* subsequent users go on their own row (all spanned by period) */}
      {userUsage &&
        userUsage.map(
          (user, index) =>
            index > 0 && (
              <TableRow key={index}>
                <ComputeUsageCells user={user} />
              </TableRow>
            )
        )}

      <TableRow className={classes.totalRow}>
        <TotalComputeUsageCells periodTotal={period.totalUsages!} />
      </TableRow>
    </>
  );
};

export default ComputeHistoryRow;
