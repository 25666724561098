import React from "react";

import { DateTime } from "luxon";
import Typography from "@material-ui/core/Typography";

import { PodMetrics, TaskMetric } from "./PodMetricsType";
import CPUGraph from "./CPUGraph";
import MemoryGraph from "./MemGraph";

interface TaskMetricsProps {
  metrics: PodMetrics[];
}

/**
 * TaskMetrics wraps presentational components for visualising the
 * pod-metrics log file which contains e.g. CPU + memory usage stats
 * @param metrics - an array of pod metrics
 */
const TaskMetrics: React.FC<TaskMetricsProps> = ({ metrics }) => {
  const noMetricsMessage = (
    <Typography variant="h4">
      No metrics available for this task run.
    </Typography>
  );

  if (
    metrics == null ||
    (metrics.length === 1 && metrics[0].containers.length === 0)
  ) {
    return noMetricsMessage;
  }

  const taskMetrics: TaskMetric[] = [];

  metrics.forEach(timeWindow => {
    timeWindow &&
      timeWindow.containers.forEach(metricsContainer => {
        if (metricsContainer.name === "task") {
          const timestamp = DateTime.fromISO(timeWindow.timestamp).toMillis();

          // for cpu, convert nanoCPUs to CPUs
          taskMetrics.push({
            time: timestamp,
            type: "cpu",
            value:
              parseInt(metricsContainer.usage.cpu.replace(/n$/, "")) / 10 ** 9
          });

          // for memory, convert KiB to GiB
          taskMetrics.push({
            time: timestamp,
            type: "mem",
            value:
              parseInt(metricsContainer.usage.memory.replace(/Ki$/, "")) *
              10 ** 3
          });
        }
      });
  });
  console.log(JSON.stringify(taskMetrics));

  // metrics might exist but not include any for "task"
  if (taskMetrics.length === 0) {
    return noMetricsMessage;
  }

  return (
    <>
      <CPUGraph cpuMetrics={taskMetrics.filter(task => task.type === "cpu")} />
      <MemoryGraph
        memoryMetrics={taskMetrics.filter(task => task.type === "mem")}
      />
    </>
  );
};

export default TaskMetrics;
