import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  subheader: {
    paddingBottom: "0.3rem"
  }
});

/**
 * TokenGeneratorIntro is a presentational component
 * for explanatory text around token generation.
 */
const TokenGeneratorIntro: React.FC = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <>
      <Typography variant="h5" className={classes.subheader}>
        {t("tokens.headline")}
      </Typography>
      <Typography variant="body2" component="p">
        {t("tokens.intro")}
      </Typography>
      <Typography variant="body2" component="p">
        {t("tokens.detail")}
      </Typography>
    </>
  );
};

export default TokenGeneratorIntro;
