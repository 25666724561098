import { observable, action, runInAction } from "mobx";

import {
  WorkflowRunsApiFp,
  WorkflowRunCompact,
  WorkflowRunList,
  WorkflowRun,
  WorkflowVersionsApiFp,
  WorkflowVersion,
  WorkflowRunStatus
} from "@stratus/wes";
import { WorkflowExecutionServiceApiFp, RunLog } from "@stratus/ga4gh";

import authStore from "./AuthStore";
import { API_BASE } from "../../constants";
import { timeout, handleAPIError } from "../../util";

/**
 * WorkflowStore is a MobX domain store for workflow
 * execution service (WES) workflows and workflow runs
 */
class WorkflowStore {
  @observable workflowRuns: Array<WorkflowRunCompact> = [];
  @observable sortKey: string = "timeModified";
  @observable sortDirection: "desc" | "asc" = "desc";

  @observable exhausted: boolean = false;
  @observable workflowRunDetails: WorkflowRun = {};

  // filters
  @observable filterStatus: WorkflowRunStatus[] | undefined = undefined;
  @observable filterName: string | undefined = undefined;

  private readonly authStore = authStore;
  private nextPage: string | undefined = undefined;

  /**
   * getWorkflowRuns gathers a set of workflow executions
   */
  @action("call workflows list endpoint and store result")
  getWorkflowRuns = async (pageSize = 100) => {
    let jwt = await this.authStore.getJwtBearer();
    if (jwt !== undefined) {
      try {
        const response = (await timeout(
          WorkflowRunsApiFp({
            basePath: API_BASE,
            apiKey: jwt
          }).listWorkflowRuns(
            this.filterStatus,
            undefined,
            this.filterName,
            undefined,
            pageSize,
            this.nextPage,
            `${this.sortKey} ${this.sortDirection}`
          )(undefined, API_BASE),
          20
        )) as WorkflowRunList;

        if (response && response.items) {
          if (response.items.length > 0) {
            runInAction(() => {
              this.workflowRuns.push(...response.items!);
              response.nextPageToken
                ? (this.nextPage = response.nextPageToken)
                : (this.exhausted = true);
            });
          } else {
            runInAction(() => {
              this.exhausted = true;
            });
          }
        } else {
          throw new Error("Unexpected API response");
        }
      } catch (err) {
        await handleAPIError(err);
      }
    }
  };

  @action
  updateTaskRuns = (runs: WorkflowRunCompact[]) => {
    this.workflowRuns.push(...runs);
  };

  @action
  setFilterStatus = (status: WorkflowRunStatus[]) => {
    if (status.length > 0) {
      if (status.length === 1 && ((status[0] as unknown) as string) === "") {
        this.filterStatus = undefined;
      } else {
        this.filterStatus = status;
      }
    }
  };

  @action
  setFilterTaskName = (nameFilter: string | undefined) => {
    if (nameFilter === "") {
      this.filterName = undefined;
    } else {
      this.filterName = nameFilter;
    }
  };

  @action("toggle sort direction for workflow runs")
  toggleSortDirection = () => {
    if (this.sortDirection === "asc") {
      this.sortDirection = "desc";
    } else {
      this.sortDirection = "asc";
    }
    this.resetWorkflowRunList();
  };

  @action("update sort key for workflow runs")
  updateSortKey = (newKey: string) => {
    this.sortKey = newKey;
    this.resetWorkflowRunList();
  };

  @action("reset workflow runs")
  resetWorkflowRunList = (restore = true) => {
    this.nextPage = undefined;
    this.workflowRuns = [];
    this.exhausted = false;
    // this.sortDirection = "desc";
    if (restore) this.getWorkflowRuns();
  };

  /**
   * getWorkflowRunDetails gets details for a single
   * workflow run
   */
  getWorkflowRunDetails = async (id: string) => {
    let jwt = await this.authStore.getJwtBearer();
    if (jwt !== undefined) {
      try {
        const response = (await timeout(
          WorkflowRunsApiFp({
            basePath: API_BASE,
            apiKey: jwt
          }).getWorkflowRun(id)(undefined, API_BASE),
          20
        )) as WorkflowRun;

        if (response) {
          return response;
        } else {
          throw new Error("Unexpected API response");
        }
      } catch (err) {
        await handleAPIError(err);
      }
    }
  };

  /**
   * geTWorkflowVersionDetails returns a specific version of a
   * workflow, including its definition
   */
  getWorkflowVersionDetails = async (
    workflowId: string,
    versionName: string
  ) => {
    let jwt = await this.authStore.getJwtBearer();
    if (jwt !== undefined) {
      try {
        const response = (await timeout(
          WorkflowVersionsApiFp({
            basePath: API_BASE,
            apiKey: jwt
          }).getWorkflowVersion(workflowId, versionName)(undefined, API_BASE)
        )) as WorkflowVersion;

        if (response) {
          return response;
        }
        throw new Error("Unexpected API response");
      } catch (err) {
        await handleAPIError(err);
      }
    }
  };

  /**
   * getWorkflowExecLog uses the ga4gh endpoint to pull
   * extra workflow for a workflow run such as task logs
   */
  getWorkflowExecLog = async (id: string): Promise<RunLog | undefined> => {
    let jwt = await this.authStore.getJwtBearer();
    if (jwt !== undefined) {
      try {
        const response = (await timeout(
          WorkflowExecutionServiceApiFp({
            basePath: API_BASE,
            apiKey: jwt
          }).getRunLog(id)(undefined, API_BASE)
        )) as RunLog;

        if (response) {
          return response;
        }
        throw new Error("Unexpected API response");
      } catch (err) {
        await handleAPIError(err);
      }
    }
  };
}

const store = new WorkflowStore();
export default store;

export type WorkflowStoreModel = typeof store;
