import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  ExpansionPanelActions,
  Button
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import WorkflowRunFilterForm from "./WorkflowRunFilterForm";
import RootStoreContext from "../../../contexts/RootStoreContext";
import { WorkflowRunStatus } from "@stratus/wes";

/**
 * WorkflowRunFiltersContainer wraps a the expanding set of filters
 * which can optionally be applied to the workflow run table.
 */
const WorkflowRunFiltersContainer: React.FC = () => {
  const { workflowStore } = useContext(RootStoreContext);
  const [nameFilter, setNameFilter] = useState<string | undefined>(undefined);
  const [statusFilter, setStatusFilter] = useState<WorkflowRunStatus[]>([]);

  const [t] = useTranslation();

  const handleApplyFiltersClick = (_?: React.MouseEvent) => {
    workflowStore.setFilterStatus(statusFilter);
    workflowStore.setFilterTaskName(nameFilter);
    workflowStore.resetWorkflowRunList();
  };

  return (
    <ExpansionPanel style={{ marginBottom: "2rem" }}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="workflow-run-filter"
        id="workflow-filters"
      >
        {/* re-use headers from tasks (TODO: change namespace) */}
        <Typography variant="h4">{t("tasks.filters.heading")}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ paddingBottom: 0 }}>
        <WorkflowRunFilterForm
          currentNameFilter={workflowStore.filterName}
          nameSetter={setNameFilter}
          currentStatusFilter={workflowStore.filterStatus}
          statusSetter={setStatusFilter}
          onSubmit={() => handleApplyFiltersClick()}
        />
      </ExpansionPanelDetails>
      <ExpansionPanelActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleApplyFiltersClick}
        >
          {t("tasks.filters.apply")}
        </Button>
      </ExpansionPanelActions>
    </ExpansionPanel>
  );
};

export default observer(WorkflowRunFiltersContainer);
