import React from "react";
import { Chip, makeStyles } from "@material-ui/core";

import { TaskRunSummary } from "@stratus/tes";
import TaskStatusMap from "./TaskStatusMap";
import clsx from "clsx";

interface StyleProps {
  status: TaskRunSummary.StatusEnum;
}

interface TaskStatusChipProps {
  status: TaskRunSummary.StatusEnum;
}

// maps a task run status to CSS styles
const statusStyle = (status: TaskRunSummary.StatusEnum) => {
  switch (status) {
    case TaskRunSummary.StatusEnum.Completed:
      return {
        color: "#186621",
        background: "#DDF8E0"
      };
    case TaskRunSummary.StatusEnum.Running:
      return {
        background: "#F5B500",
        color: "#FCEEEE"
      };
    case TaskRunSummary.StatusEnum.Aborted:
    case TaskRunSummary.StatusEnum.Failed:
      return { background: "#AE1B1F", color: "#FCEEEE" };
    default:
      return {};
  }
};
export { statusStyle };

const useStyles = makeStyles({
  root: {
    textTransform: "uppercase"
  },
  chip: ({ status }: StyleProps) => statusStyle(status)
});

/**
 * TaskStatusChip displays a task tatus in a styled Chip component
 * @param status task status
 */
const TaskStatusChip: React.FC<TaskStatusChipProps> = ({ status }) => {
  const classes = useStyles({ status });

  return (
    <Chip
      label={<TaskStatusMap status={status} />}
      className={clsx(classes.root, classes.chip)}
    />
  );
};

export default TaskStatusChip;
