import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useParams, Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, Theme } from "@material-ui/core";

import { WorkflowRun, WorkflowVersion } from "@stratus/wes";
import { RunLog } from "@stratus/ga4gh";
import RootStoreContext from "../../../contexts/RootStoreContext";
import useGlobalStyles from "../../../styles/classes";
import ErrorSnackbar from "../../errors/ErrorSnackbar";
import PaddedDivider from "../../layout/PaddedDivider";
import WorkflowDetailsCard from "./WorkflowDetailsCard";
import WorkflowRunMetadataPanel from "./WorkflowRunMetadataPanel";
import clsx from "clsx";

const useStyles = makeStyles(({ spacing }: Theme) => ({
  topLoader: {
    minWidth: "100%"
  }
}));

/**
 * WorkflowDetailsContainer connects the workflow view with the domain
 * store for workflows and workflow runs.
 * TODO: break down this component
 */
const WorkflowDetailsContainer: React.FC = () => {
  const { workflowStore, authStore } = useContext(RootStoreContext);
  const { id } = useParams();
  const [t] = useTranslation();
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  // errors
  const [error, setError] = useState("");
  const [is404, setIs404] = useState(false);

  // loading status
  const [gotWorkflowVersion, setGotWorkflowVersion] = useState(false);
  const [gotWorkflowRun, setGotWorkflowRun] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  // setters
  const [workflowVersion, setWorkflowVersion] = useState({} as WorkflowVersion);
  const [workflowRun, setWorkflowRun] = useState({} as WorkflowRun);
  const [runLogs, setRunLogs] = useState({} as RunLog);

  useEffect(() => {
    let active = true;
    if (active && authStore.isInitialised && id !== undefined) {
      (async () => {
        try {
          // get workflow details here
          const details = await workflowStore.getWorkflowRunDetails(id);
          if (details !== undefined) {
            setWorkflowRun(details);
          }
          const runLog = await workflowStore.getWorkflowExecLog(id);
          if (runLog) {
            setRunLogs(runLog);
          }
          setGotWorkflowRun(true);
        } catch (err) {
          // show 404 route where the workflow run is not found
          if ("httpStatusCode" in err && err.httpStatusCode === 404) {
            setIs404(true);
          } else {
            setError(err.message);
          }
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [workflowStore, authStore.isInitialised, id]);

  useEffect(() => {
    let active = true;
    if (
      active &&
      authStore.isInitialised &&
      workflowRun &&
      workflowRun.workflowVersion
    ) {
      (async () => {
        try {
          // hack: parse from href
          const workflowId = workflowRun!
            .workflowVersion!.href!.split("/")
            .find(p => p.startsWith("wfl."));

          let workflowVersion: WorkflowVersion | undefined = undefined;
          if (workflowId) {
            workflowVersion = await workflowStore.getWorkflowVersionDetails(
              workflowId,
              workflowRun.workflowVersion!.version!
            );
          }
          if (workflowVersion) {
            setWorkflowVersion(workflowVersion);
          }
          setGotWorkflowVersion(true);
        } catch (err) {
          setError(err.message);
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [workflowStore, authStore.isInitialised, workflowRun]);

  useEffect(() => {
    if (gotWorkflowRun && gotWorkflowVersion) {
      setHasLoaded(true);
    }
  }, [gotWorkflowRun, gotWorkflowVersion]);

  if (is404) {
    return <Redirect to={"/404"} />;
  }

  return (
    <>
      {!hasLoaded ? (
        <LinearProgress
          color="secondary"
          className={clsx(globalClasses.underAppBar, classes.topLoader)}
          // needs to be inlined as globalClasses can get compiled to unspecific selector
          style={{ position: "absolute" }}
        />
      ) : (
        <>
          <Grid item xs={12} className={globalClasses.paddedContainer}>
            <Typography variant="h2">
              {workflowRun.name || t("workflows.details.noname")}
            </Typography>
          </Grid>
          <Grid item xs={12} className={globalClasses.paddedContainer}>
            <WorkflowRunMetadataPanel workflowRun={workflowRun} />

            <WorkflowDetailsCard
              workflowRun={workflowRun}
              workflowVersion={workflowVersion}
              runLogs={runLogs}
            />
          </Grid>
          <PaddedDivider />
        </>
      )}
      {error !== "" && <ErrorSnackbar error={error} clearOnClose={setError} />}
    </>
  );
};

export default observer(WorkflowDetailsContainer);
