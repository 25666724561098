import React from "react";
import { PeriodUsageSummary } from "@stratus/console";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  TableBody,
  Hidden
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import HistoryPeriodRow from "../table/StorageHistoryRow";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles({
  filledHeader: {
    backgroundColor: "#D8D8D8",
    padding: "0.1rem .75rem",
    color: "#000000",
    border: "2px solid #ffffff"
  },
  subheader: {
    padding: "0.1rem .75rem",
    textTransform: "uppercase",
    fontWeight: 500,
    textAlign: "right"
  }
});

interface HistoryTableProps {
  usages: PeriodUsageSummary[];
}

/**
 * StorageHistoryTable shows a system usage breakdown over time and
 * per user
 * @param usages period usage items returned by the developer console API
 */
const StorageHistoryTable: React.FC<HistoryTableProps> = ({ usages }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const rows = usages.map((usage, index) => (
    <HistoryPeriodRow period={usage} key={index} />
  ));

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell className={classes.filledHeader}>
            {t("usage.history.table.header.periodEnding")}
          </TableCell>
          <TableCell className={classes.filledHeader}>
            {t("usage.history.table.header.user")}
          </TableCell>
          <TableCell
            colSpan={matches ? 1 : 4}
            className={classes.filledHeader}
            style={{ textAlign: "right" }}
          >
            {t("usage.history.table.header.storage")}
          </TableCell>
          <TableCell
            colSpan={matches ? 1 : 5}
            className={classes.filledHeader}
            style={{ textAlign: "right" }}
          >
            {t("usage.history.table.header.transfer")}
          </TableCell>
        </TableRow>
      </TableHead>

      {/* Filled sub-header row */}
      <Hidden mdDown>
        <TableHead>
          <TableRow>
            <TableCell colSpan={matches ? 3 : 6} />

            <TableCell
              className={classes.filledHeader}
              style={{ textAlign: "right" }}
            >
              {t("usage.history.table.header.archive")}
            </TableCell>

            <TableCell
              colSpan={matches ? 1 : 3}
              className={classes.filledHeader}
              style={{ textAlign: "right" }}
            >
              {t("usage.history.table.header.restore")}
            </TableCell>
          </TableRow>
        </TableHead>
      </Hidden>

      <TableHead className={classes.subheader}>
        <TableRow>
          <TableCell colSpan={2} />
          {/* Storage sub-headers */}
          <Hidden mdDown>
            <TableCell>
              {t("usage.history.table.subheader.storage.standard")}
            </TableCell>
            <TableCell>
              {t("usage.history.table.subheader.storage.archive")}
            </TableCell>
            <TableCell>
              {t("usage.history.table.subheader.storage.deepArchive")}
            </TableCell>
          </Hidden>
          <TableCell>{t("usage.history.table.subheader.totals")}</TableCell>

          {/* Transfer sub-headers */}
          <Hidden mdDown>
            <TableCell />
            <TableCell>
              {t("usage.history.table.subheader.transfer.restore.economy")}
            </TableCell>
            <TableCell>
              {t("usage.history.table.subheader.transfer.restore.standard")}
            </TableCell>
            <TableCell>
              {t("usage.history.table.subheader.transfer.restore.deepStandard")}
            </TableCell>
          </Hidden>
          <TableCell>{t("usage.history.table.subheader.totals")}</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>{rows}</TableBody>
    </Table>
  );
};

export default StorageHistoryTable;
