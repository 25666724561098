import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import Grid from "@material-ui/core/Grid";
import InfiniteScroll from "react-infinite-scroll-component";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import LinearProgress from "@material-ui/core/LinearProgress";

import RootStoreContext from "../../contexts/RootStoreContext";
import useGlobalStyles from "../../styles/classes";
import ErrorSnackbar from "../errors/ErrorSnackbar";
import PaddedDivider from "../layout/PaddedDivider";
import TaskGrid from "./TaskGrid";

/**
 * TaskContainer connects the task view with the domain
 * store for tasks and task runs.
 */
const TaskContainer: React.FC = () => {
  const { taskStore, authStore } = useContext(RootStoreContext);
  const globalClasses = useGlobalStyles();
  const [error, setError] = useState("");
  const [t] = useTranslation();

  useEffect(() => {
    let active = true;
    if (active && authStore.isInitialised && !taskStore.exhausted) {
      (async () => {
        try {
          await taskStore.getTaskRuns();
        } catch (err) {
          setError(err.message);
        }
      })();
      return () => {
        taskStore.resetTaskLists(false);
        active = false;
      };
    }
  }, [taskStore, authStore.isInitialised]);

  // Infinite scroll uses this to page over additional tasks
  const nextTasks = async () => {
    await taskStore.getTaskRuns();
  };

  return (
    <>
      <Grid item xs={12} className={globalClasses.paddedContainer}>
        <Typography variant="h2">{t("tasks.title")}</Typography>
      </Grid>
      <Grid item xs={12} className={globalClasses.paddedContainer}>
        <InfiniteScroll
          dataLength={taskStore.taskRuns.length}
          next={() => {
            taskStore.initialLoadComplete && nextTasks();
          }}
          hasMore={!taskStore.exhausted}
          loader={<LinearProgress color="secondary" />}
        >
          <TaskGrid taskRuns={taskStore.taskRuns} />
        </InfiniteScroll>
      </Grid>

      <Grid item xs={12} className={globalClasses.paddedContainer}>
        {taskStore.exhausted && (
          <Typography variant="h4" align="center">
            {taskStore.taskRuns.length === 0
              ? t("tasks.runs.none")
              : t("tasks.runs.loaded")}
          </Typography>
        )}
      </Grid>

      <PaddedDivider />

      {error !== "" && <ErrorSnackbar error={error} clearOnClose={setError} />}
    </>
  );
};

export default observer(TaskContainer);
