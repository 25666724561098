import React from "react";
import { observer } from "mobx-react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme } from "@material-ui/core/styles";
import GenerateTokenButton from "./GenerateTokenButton";
import { useTranslation } from "react-i18next";
import graphic from "../../assets/console-graphic.png";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(({ spacing }: Theme) => ({
  spacedHeader: {
    paddingTop: spacing(3),
    paddingBottom: spacing(2)
  },
  spacedUnderline: {
    paddingBottom: spacing(2)
  },
  graphic: {
    margin: "auto"
  }
}));

/**
 * Default is a smart component that renders the default
 * page following redirection of a logged-in user.
 */
const DashboardContainer: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <>
      <Box className={classes.graphic}>
        <img src={graphic} alt="console logo" />
      </Box>

      <Typography variant="h2" className={classes.spacedHeader}>
        {t("default.welcome")}
      </Typography>

      <Typography
        variant="body2"
        component="p"
        className={classes.spacedUnderline}
      >
        {t("default.getstarted")}
      </Typography>
      <GenerateTokenButton>{t("default.button.generate")}</GenerateTokenButton>
    </>
  );
};

export default observer(DashboardContainer);
