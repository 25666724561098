import React, { useRef, useState, useEffect } from "react";
import dagreD3 from "dagre-d3";
import * as d3 from "d3";

import buildGraph, { Node } from "./parser";
import ErrorSnackbar from "../../errors/ErrorSnackbar";
import { Log } from "@stratus/ga4gh";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme } from "@material-ui/core";

const minGraphHeight = 350;

const useStyles = makeStyles(({ spacing }: Theme) => ({
  graph: {
    border: "1px solid #ddd",
    minHeight: minGraphHeight,
    padding: spacing(1)
  }
}));

interface ISLGraphProps {
  definition: string;
  taskRuns: Log[];
}

/**
 * ISLGraph uses dagre-d3 to lay out an ISL state machine
 */
const ISLGraph: React.FC<ISLGraphProps> = ({ definition, taskRuns }) => {
  const graphContainer = useRef(null);
  const [error, setError] = useState("");
  const [t] = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    if (definition && taskRuns) {
      let graph;
      try {
        graph = buildGraph(definition);
      } catch (err) {
        setError(err.message);
        return undefined;
      }

      let g = new dagreD3.graphlib.Graph({ directed: true })
        .setGraph({
          edgesep: 30,
          ranksep: 20,
          nodesep: 20
        })
        .setDefaultEdgeLabel(() => "next");

      let workflowHasFailed = false;
      const nodeNames = graph.nodes.map((n: Node) => n.Name);
      for (let node of graph.nodes) {
        let fillStyle: string;
        const defaultLabelStyle = "fill: black; stroke: none";
        let labelStyle = defaultLabelStyle;
        const nodeStyles = "stroke-width: 1";

        switch (node.Type) {
          case "Start":
            fillStyle = "fill: #fff; stroke: #13511A";
            labelStyle = "fill: #13511A; stroke: none";
            break;
          case "End":
            fillStyle = "fill: #fff; stroke: #8B1518";
            labelStyle = "fill: #8B1518; stroke: none";
            break;
          case "Wait":
            fillStyle = "fill: #B1BEF7; stroke: #4D6CEE";
            break;
          case "Choice":
            fillStyle = "fill: #CAB9E3; stroke: #8A64C1";
            break;
          // case "Fail":
          // case "Action.Fail":
          //   fillStyle = "fill: #F2AFB1; stroke: #DE3439";
          //   break;
          // case "Succeed":
          //   fillStyle = "fill: #B8EEBE; stroke: #20882C";
          //   break;
          default:
            fillStyle = "fill: #FFDE7F; stroke: #D39C00";
            labelStyle = defaultLabelStyle;
            break;
        }

        // get last matching task run for cases where there are >1
        const stateStatus = taskRuns
          .slice()
          .reverse()
          .find(task => task.name === node.Name);

        // exit code can be null when initialising
        if (
          stateStatus &&
          stateStatus.exit_code !== undefined &&
          stateStatus.exit_code !== null
        ) {
          if (stateStatus.exit_code === 0) {
            fillStyle = "fill: #B8EEBE; stroke: #20882C";
          } else {
            fillStyle = "fill: #F2AFB1; stroke: #DE3439";
            workflowHasFailed = true;
          }
        }

        // build out SVG label
        let nodeDetailsLabel = "";
        if (stateStatus) {
          if (stateStatus.start_time) {
            nodeDetailsLabel += `<br /><strong>${t(
              "workflows.details.started"
            )}</strong>: ${t("common:datetime-short", {
              date: new Date(stateStatus.start_time)
            })}`;
          }
          if (stateStatus.end_time) {
            nodeDetailsLabel += `<br /><strong>${t(
              "workflows.details.finished"
            )}</strong>: ${t("common:datetime-short", {
              date: new Date(stateStatus.end_time)
            })}`;
          }
          if (
            stateStatus.exit_code !== undefined &&
            stateStatus.exit_code !== 0
          ) {
            nodeDetailsLabel += `<br /><strong>${t(
              "workflows.details.exit"
            )}</strong>: ${stateStatus.exit_code}`;
          }
        }

        const nodeLabel = `
                <span class='nodeHeader'>
                    ${node.Name}
                </span><br />
                <span class='nodeDetails'>
                    ${node.Type !== node.Name ? node.Type : ""}
                    ${nodeDetailsLabel}
                </span>
                `;

        g.setNode(node.Name, {
          labelType: "html",
          label: nodeLabel,
          rx: 5,
          ry: 5,
          style: `${fillStyle};${nodeStyles}`,
          labelStyle: labelStyle
        });
      }

      graph.edges.sort((a, b) => (a.to > b.to ? -1 : 1));
      for (let edge of graph.edges) {
        let edgeStyle = "stroke: black; fill: none";
        if (edge.from === "Start" || edge.to === "End") {
          edgeStyle = "stroke: black; stroke-dasharray: 4; fill: none";
        }

        if (nodeNames.includes(edge.from) && nodeNames.includes(edge.to)) {
          g.setEdge(edge.from, edge.to, {
            arrowhead: "vee",
            style: edgeStyle,
            arrowHeadStyle: workflowHasFailed
              ? "fill: #55555; stroke: #55555;"
              : "fill: black; stroke: black;",
            curve: d3.curveBasis
          });
        } else {
          if (!nodeNames.includes(edge.from)) {
            console.log(
              `from node ${edge.from} not present in ${nodeNames.join(", ")}`
            );
          }
          if (!nodeNames.includes(edge.to)) {
            console.log(
              `to node ${edge.to} not present in ${nodeNames.join(", ")}`
            );
          }
          console.log(`not adding edge: ${JSON.stringify(edge)}`);
        }
      }

      let render = new dagreD3.render();

      if (graphContainer.current) {
        d3.select(graphContainer.current)
          .select("svg")
          .remove();
        let svg = d3
          .select(graphContainer.current)
          .append("svg")
          .attr("id", "graphView")
          .attr("xmlns", "http://www.w3.org/2000/svg")
          .attr("width", "100%");
        let inner: any = svg.append("g");

        // Set up zoom support
        let zoom: any = d3.zoom().on("zoom", function() {
          inner.attr("transform", d3.event.transform);
        });
        svg.call(zoom);

        render(inner, g);

        // heuristic for ensmallening big graphs
        const initialScale = Math.sqrt(1 / (g.graph().width / 200));

        // centering
        const container = d3.select(graphContainer.current).node();
        const outerWidth = ((container as unknown) as Element).getBoundingClientRect()
          .width;
        svg.call(
          zoom.transform,
          d3.zoomIdentity
            .translate((outerWidth - g.graph().width * initialScale) / 2, 40)
            .scale(initialScale)
        );

        svg.attr(
          "height",
          Math.max(g.graph().height * initialScale + 100, minGraphHeight)
        );
      }
    }
  }, [graphContainer, definition, taskRuns, t]);

  return (
    <>
      <div ref={graphContainer} className={classes.graph} />
      {error !== "" && <ErrorSnackbar error={error} clearOnClose={setError} />}
    </>
  );
};

export default ISLGraph;
