import React, { useRef } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(({ spacing }: Theme) => ({
  input: {
    minWidth: "50%",
    maxWidth: "90%",
    display: "flex"
  },
  removeButton: {
    padding: "0px",
    minWidth: 30,
    minHeight: 30,
    marginTop: 4,
    marginLeft: spacing(1)
  },
  addButton: {
    marginBottom: spacing(3)
  },
  form: {
    display: "flex",
    alignItems: "center"
  }
}));

interface InputOptionFormProps {
  removeInputHandler: () => void;
  handleInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
}

const InputOptionForm: React.FC<InputOptionFormProps> = ({
  removeInputHandler,
  handleInput,
  label
}) => {
  const classes = useStyles();
  const inputRef = useRef(null);

  const handleRemoveClick = () => {
    removeInputHandler();
  };

  // pass input onChange up to parent component
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    handleInput(e);
  };

  return (
    <form noValidate autoComplete="off" className={classes.form}>
      <TextField
        id={label}
        placeholder="string"
        margin="dense"
        variant="outlined"
        className={classes.input}
        onChange={handleInputChange}
        ref={inputRef}
      />
      <Button
        variant="contained"
        color="primary"
        className={classes.removeButton}
        onClick={handleRemoveClick}
      >
        -
      </Button>
    </form>
  );
};

export default InputOptionForm;
