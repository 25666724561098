import React from "react";

import RouteErrorBoundary from "./RouteErrorBoundary";

interface ErrorState {
  hasError: boolean;
}

/**
 * BaseErrorBoundary is a generic 'something went wrong' type
 * fallback. It can also log errors to a senty-like service.
 * This must be a class component as there is currently no
 * hook equivalent of getDerivedStateFromError/componentDidCatch
 */
class BaseErrorBoundary extends React.Component<any, ErrorState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // componentDidCatch(error: Error) {
  //    log to an error reporting service
  //    logErrorToMyService(error, info);
  // }

  render() {
    if (this.state.hasError) {
      return <RouteErrorBoundary />;
    }

    return this.props.children;
  }
}

export default BaseErrorBoundary;
