import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { pageRef } from "../../routes";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  dashboardLink: {
    color: "#a3a3a3",
    textTransform: "uppercase",
    textDecoration: "none"
  }
}));

/**
 * DashboardLink is a router link to the dashboard view
 */
const DashboardLink: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();

  const dashURI = `/${pageRef.dashboard}`;

  return (
    <Button>
      <Link className={classes.dashboardLink} to={dashURI}>
        {t("nav.link.dashboard")}
      </Link>
    </Button>
  );
};
export default DashboardLink;
