import React from "react";
import { Button, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import SignInButton from "./SignInButton";

const useStyles = makeStyles({
  root: {
    minHeight: "275px"
  },
  title: {
    fontSize: "34px",
    lineHeight: "40px",
    color: "#fff",
    fontWeight: 900
  },
  welcome: {
    fontSize: "22px",
    lineHeight: "25px",
    color: "#fff",
    fontWeight: 500
  },
  demo: {
    fontWeight: 500,
    backgroundColor: "#328ED1",
    color: "#FFF",
    width: "100%"
  },
  hide: {
    display: "none"
  }
});

const LandingIntroDialogue: React.FC = () => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      justify="space-around"
      spacing={3}
    >
      <Grid item>
        <Typography className={classes.title}>
          {t("landing.banner.title")}
        </Typography>
        <Typography className={classes.title}>
          {t("landing.banner.subtitle")}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.welcome}>
          {t("landing.banner.welcome")}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container justify="flex-start" spacing={3} wrap="wrap">
          <Grid item md={4} lg={3} className={classes.hide}>
            <Button className={classes.demo} variant="contained">
              {t("landing.banner.button.demo")}
            </Button>
          </Grid>
          <Grid item md={4} lg={3}>
            <SignInButton />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LandingIntroDialogue;
