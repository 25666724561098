import { createMuiTheme } from "@material-ui/core/styles";

import orange from "@material-ui/core/colors/orange";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#328ED1"
    },
    secondary: orange,
    error: {
      main: "#DE3439"
    }
  },

  // shadows: Array(25).fill('none'),

  // override default component CSS
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: "none"
      }
    },
    MuiButton: {
      root: {
        // square corners (default: 4px)
        borderRadius: 0,

        // extra x spacing
        padding: ".25rem 1.25rem",

        // no elevation shadows
        boxShadow: "none",

        // don't uppercase button label
        textTransform: "none",

        // regular weight, not bold
        fontWeight: 400
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none"
        }
      },
      text: {
        padding: ".25rem 1.25rem"
      }
    },
    MuiCardActions: {
      root: {
        // move card action content over to the right
        justifyContent: "flex-end"
      }
    },
    MuiCardContent: {
      root: {
        // even + tighter padding than default
        padding: 8,
        "&:last-child": {
          paddingBottom: 8
        }
      }
    },
    MuiChip: {
      root: {
        // less rounded chips
        borderRadius: 4,

        // tighter spacing
        height: "auto"
      },
      label: {
        paddingLeft: ".25rem",
        paddingRight: ".25rem",
        paddingTop: ".1rem",
        textTransform: "uppercase",
        fontWeight: 500,
        fontSize: ".7rem"
      }
    },
    MuiFormControlLabel: {
      label: {
        fontWeight: 400,
        fontSize: ".9rem",
        color: "#868686",
        lineHeight: "1.5rem"
      }
    },
    MuiListItemText: {
      primary: {
        fontWeight: 300,
        opacity: 0.9,
        lineHeight: 1
      }
    },
    MuiOutlinedInput: {
      inputMarginDense: {
        fontSize: ".8rem",
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 0,
        marginTop: 0,
        marginBottom: 0,
        "&$focused": {
          border: "1px solid #cccccc"
        },
        "& fieldset": {
          borderColor: "1px solid #cccccc"
        },
        "&:hover fieldset": {
          borderColor: "1px solid #cccccc"
        },
        "&.Mui-focused fieldset": {
          borderColor: "1px solid #cccccc"
        }
      }
    },
    MuiTab: {
      root: {
        zIndex: 999
      }
    },
    MuiTableHead: {
      root: {
        textTransform: "uppercase"
      }
    },
    MuiTableCell: {
      head: {
        color: "#9DA7B5",
        paddingTop: "1.3rem",
        paddingBottom: ".5rem"
      },
      body: {
        color: "#3E4752",
        fontWeight: 500,
        paddingTop: "1rem",
        paddingBottom: "1rem"
      }
    },
    MuiTableBody: {
      root: {
        // "& .MuiTableRow-root:nth-of-type(odd)": {
        //   backgroundColor: "rgba(251,251,251,1)"
        // },
      }
    },
    MuiTypography: {
      // e.g. "Developers" in nav
      subtitle1: {
        fontWeight: 400,
        letterSpacing: "-1px"
      },
      subtitle2: {
        color: "#464646",
        fontWeight: 500,
        fontSize: "0.8rem",
        lineHeight: "2rem"
      },
      // bigger text, e.g. card sub-headers
      body1: {
        lineHeight: "1.5rem",
        color: "#4F5A67",
        fontWeight: 500
      },
      // small lighter text
      body2: {
        color: "#868686",
        fontSize: "0.8rem",
        paddingBottom: "1rem"
      },
      // view headers
      h2: {
        fontWeight: 500,
        fontSize: "1.25rem",
        lineHeight: "1.75rem",
        color: "#4F5A67"
      },
      // e.g. card headers
      h3: {
        fontWeight: 500,
        fontSize: "1.2rem",
        color: "#8995A5",
        lineHeight: "1.25rem"
      },
      h4: {
        fontWeight: 400,
        fontSize: ".9rem",
        color: "#868686",
        lineHeight: "1.5rem"
      },
      // e.g. Token OAuth p header
      h5: {
        fontWeight: 500,
        fontSize: ".8rem",
        color: "#4F5A67"
      },
      h6: {
        textTransform: "uppercase",
        color: "#a6a6a6",
        lineHeight: "1.2rem",
        fontSize: "0.8rem",
        fontWeight: 400
      }
    }
  },

  // set default props for components
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },

  transitions: {
    // disable transitions
    // create: () => "none"
  }
});

// disables all elevation shadows, probably not desirable
// theme.shadows = [] as unknown as Theme["shadows"];

export default theme;

// from: http://ui-library.illumina.com/colors/
export const statusColours = {
  success: "#27AA36",
  warning: "#F5B500"
};
