import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { I18nextProvider } from "react-i18next";
import { configure } from "mobx";
import RootStore from "./stores/RootStore";
import RootStoreContext from "./contexts/RootStoreContext";
import ComponentRoutes from "./components/routes/ComponentRoutes";
import theme from "./styles/theme";
import PageLayout from "./components/layout/PageLayout";
import i18n from "./i18n";
import ErrorBoundary from "./components/errors/BaseErrorBoundary";
import NavBar from "./components/nav/NavBar";

// mobX in strict mode: only actions can modify state
configure({
  enforceActions: "observed"
});

const App: React.FC = () => {
  return (
    <RootStoreContext.Provider value={RootStore}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <NavBar />
            <PageLayout>
              <ErrorBoundary>
                <ComponentRoutes />
              </ErrorBoundary>
            </PageLayout>
          </ErrorBoundary>
        </ThemeProvider>
      </I18nextProvider>
    </RootStoreContext.Provider>
  );
};

export default App;
