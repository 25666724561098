import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, Hidden } from "@material-ui/core";
import LandingIntroDialogue from "./LandingIntroDialogue";
import graphic from "../../../assets/console-graphic.svg";
import BannerBackground from "../../../assets/Header-Background-Image.png";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "393px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${BannerBackground})`
  },
  image: {
    width: "100%",
    maxWidth: "200px"
  },
  spacer: {
    flexGrow: 1
  }
}));

const LandingBanner: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.root}
      justify="center"
      alignItems="center"
    >
      <Grid container justify="center" alignItems="center">
        <div className={classes.spacer}></div>
        <Grid item xs={11} sm={8}>
          <LandingIntroDialogue />
        </Grid>
        <div className={classes.spacer}></div>
        <Hidden smDown>
          <Grid item sm={3}>
            <img className={classes.image} src={graphic} alt="console logo" />
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  );
};

export default LandingBanner;
