import { observable, action } from "mobx";

import authStore from "./domain/AuthStore";

const drawerWidthPx = 240;
const appBarHeightPx = 60;

class UIStore {
  @observable drawerIsOpen = false;
  @observable drawerWidth = drawerWidthPx;
  @observable appBarHeight = appBarHeightPx;

  // could init on any route
  @observable currentRoute = (window.location.hash as string).substr(1);

  private authStore = authStore;
  private tempDrawer = false;

  @action("open nav drawer")
  openDrawer = () => {
    this.drawerWidth = drawerWidthPx;
    this.drawerIsOpen = true;
  };

  @action("close nav drawer")
  closeDrawer = () => {
    this.drawerWidth = 0;
    this.drawerIsOpen = false;
  };

  @action("record navigation")
  changeRoute = (newRoute: string) => {
    this.currentRoute = newRoute;

    // keep token alive while app in use
    this.authStore.refreshToken();

    // for temporary drawers, close on nav click
    this.tempDrawer && this.closeDrawer();
  };

  setTempDrawer = (isTemp: boolean) => {
    this.tempDrawer = isTemp;
  };
}

const uiStore = new UIStore();
export default uiStore;

export type UIStoreModel = typeof uiStore;
