import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { DateTime, Settings } from "luxon";

const options = {
  interpolation: {
    escapeValue: false,
    format: (value: string | Date, format?: string, lng?: string) => {
      if (value instanceof Date)
        return DateTime.fromJSDate(value).toFormat(format as string);
      return value;
    }
  },

  cache: {
    enabled: true
  },

  // only show debug messages in dev build
  debug: process.env.NODE_ENV === "development" ? true : false,

  fallbackLng: "en",

  backend: {
    backends: [LocalStorageBackend, XHR],

    // match order of above backends
    backendOptions: [
      {
        expirationTime: 7 * 24 * 60 * 60 * 1000,
        versions: {
          // cache-bust on new build
          en: process.env.REACT_APP_BUILD_ID || "v1"
        }
      },
      {
        loadPath: (lng: string, namespace: string[]) => {
          // content shared between all langs but requiring i18n, e.g. datetimes
          if (namespace[0] === "common") return `./locales/common.json`;

          // real translations broken down into files
          return `./locales/${lng}/${namespace}.json`;
        }
      }
    ]
  },

  ns: ["common", "translation"],
  defaultNS: "translation",

  // seems to help with flash of untranslated content on refresh
  initImmediate: false,

  // en-US, en-GB etc. all resolve to en
  load: "languageOnly" as "languageOnly",

  react: {
    wait: true,
    bindI18n: "languageChanged loaded",
    bindStore: "added removed",
    useSuspense: false
  }
};

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init(options);

i18n.on("languageChanged", function(lng) {
  // set date locale too
  Settings.defaultLocale = lng;
  console.log(`detected locale: ${lng}`);
});

export default i18n;
