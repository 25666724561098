import React from "react";
import { ServiceHealthResponse } from "@stratus/console";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  makeStyles
} from "@material-ui/core";
import HealthStatusChip from "./HealthStatusChip";
import { useTranslation } from "react-i18next";
import SVGLoader from "../loaders";

const useStyles = makeStyles({
  Healthy: {},
  Unhealthy: {
    // red row edge for down services
    borderLeft: "4px solid #AE1B1F"
  },
  Degraded: {
    // orange row edge for degraded services
    borderLeft: "4px solid #F5B500"
  }
});

const ServiceHealthTableCellLoader = () => (
  <div style={{ height: "1.2rem", width: 160 }}>
    <SVGLoader height={18} width={100}>
      <rect x="5" y="2" rx="5" ry="5" width="80" height="7" />
    </SVGLoader>
  </div>
);

interface ServiceHealthTableProps {
  health: ServiceHealthResponse[];
}

/**
 * ServiceHealthTable displays the health of each service as a
 * row in a table. Unhealthy services are highlighted
 * @param health - array of service health
 */
const ServiceHealthTable: React.FC<ServiceHealthTableProps> = ({ health }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("health.table.header.service")}</TableCell>
            <TableCell>{t("health.table.header.status")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {health.length
            ? health.map((status, index) => (
                <TableRow
                  className={(classes as any)[status.status!]}
                  key={index}
                >
                  <TableCell>{status.name}</TableCell>
                  <TableCell>
                    <HealthStatusChip status={status.status! as any} />
                  </TableCell>
                </TableRow>
              ))
            : [...Array(4)].map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <ServiceHealthTableCellLoader />
                  </TableCell>
                  <TableCell>
                    <ServiceHealthTableCellLoader />
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </>
  );
};

export default ServiceHealthTable;
