import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  inlineButton: {
    paddingTop: 0,
    paddingBottom: 0
  }
});

interface ExtendedFieldDisplayProps {
  field: string[];
  limit: number;
}

/**
 * ExtendedFieldDisplay takes a comma-separated string
 * and truncates it beyond a set limit, adding a show
 * more / fewer toggle button where needed.
 * @param field – command-delimited string of fields
 * @param limit – number of fields to show untruncated
 */
const ExtendedFieldDisplay: React.FC<ExtendedFieldDisplayProps> = ({
  field,
  limit
}) => {
  const needsTruncating = field && field.length > limit;

  // if needs truncating, init in truncated state
  const [isTruncated, setIsTruncated] = useState(needsTruncating);
  const classes = useStyles();

  // if no truncation needed, leave field as-is
  if (!needsTruncating) {
    return <>{field.join(" ")}</>;
  }

  return (
    <>
      {isTruncated ? field.slice(0, limit).join(" ") : field.join(" ")}
      <Button
        variant="text"
        color="primary"
        size="small"
        onClick={() => setIsTruncated(current => !current)}
        className={classes.inlineButton}
      >
        ({isTruncated ? `${field.length - limit} more` : "Fewer"})
      </Button>
    </>
  );
};

export default ExtendedFieldDisplay;
