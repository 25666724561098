import React from "react";
import ScopesOptionContainer from "./ScopesOptionContainer";
import ACLOptionContainer from "./ACLOptionContainer";
import WorkgroupOptionContainer from "./WorkgroupOptionContainer";
import ApiKeyOptionContainer from "./ApiKeyOptionContainer";
import MembershipOptionContainer from "./MembershipOptionContainer";

/**
 * AdvancedOptionForm lays out a form which offers advanced
 * options for token generation, such as adding specific
 * auth scopes.
 */
const AdvancedOptionForm: React.FC = () => {
  return (
    <>
      <ScopesOptionContainer />
      <ACLOptionContainer />
      <WorkgroupOptionContainer />
      <MembershipOptionContainer />
      <ApiKeyOptionContainer />
    </>
  );
};

export default AdvancedOptionForm;
