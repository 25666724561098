import React from "react";
import { Route, Switch } from "react-router-dom";
import routes, { route } from "../../routes";
import TaskDetailsView from "../../views/TaskDetailsView";
import MissedView from "../../views/MissedView";
import SmartRoute from "./SmartRoute";
import LandingRoute from "./LandingRoute";
import LoginRedirect from "../../views/LoginRedirect";
import WorkflowDetailsView from "../../views/WorkflowDetailView";

/**
 * ComponentRoutes handles component routing for
 * top-level Drawer navigation
 */
const ComponentRoutes: React.FC = () => {
  const switchRoutes: React.ReactFragment[] = [LandingRoute];

  for (let key in routes) {
    // ts7017 type hacking
    (routes as any)[key].forEach((r: route, index: number) => {
      switchRoutes.push(
        <SmartRoute
          key={index}
          exact
          path={r.path}
          component={r.component}
          isProtected={r.protected}
        />
      );
    });
  }
  switchRoutes.push(
    <Route exact path="/login" component={LoginRedirect} key="Login" />
  );

  // parameterised routes
  switchRoutes.push(
    <Route
      path="/tasks/:id"
      component={TaskDetailsView}
      key="taskDetail"
      exact
    />,
    <Route
      path="/workflows/:id"
      component={WorkflowDetailsView}
      key="workflowDetail"
      exact
    />
  );
  // add component for path misses / 404
  switchRoutes.push(<Route component={MissedView} key={"err404"} />);

  return <Switch>{switchRoutes}</Switch>;
};

export default ComponentRoutes;
