import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: "flex",
    minHeight: "70vh"
  },
  inner: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center"
  }
});

const MissedView: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.root}
      justify="center"
      alignItems="center"
    >
      <Grid item xs={6} className={classes.inner}>
        <Typography variant="h2">404!</Typography>
        <Typography variant="body2">Content not found</Typography>
      </Grid>
    </Grid>
  );
};

export default MissedView;
