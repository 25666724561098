import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    paddingTop: "1rem",
    fontSize: "0.8rem"
  }
});

const HealthSummary: React.FC = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <Typography variant="body2" className={classes.root}>
      {t("health.intro")}
    </Typography>
  );
};

export default HealthSummary;
