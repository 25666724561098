import React from "react";

import { TaskMetric } from "./PodMetricsType";
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  AreaChart
} from "recharts";
import { DateTime } from "luxon";
import { Typography } from "@material-ui/core";
import prettyBytes from "pretty-bytes";

interface MemoryGraphProps {
  memoryMetrics: TaskMetric[];
}

const graphDateFormatter = (unixTime: number | string) => {
  if (typeof unixTime === "string") {
    unixTime = parseInt(unixTime);
  }
  return DateTime.fromMillis(unixTime).toLocaleString(
    DateTime.TIME_24_WITH_SECONDS
  );
};

const MemoryGraph: React.FC<MemoryGraphProps> = ({ memoryMetrics }) => (
  <>
    <Typography variant="h4">Memory</Typography>
    <ResponsiveContainer width="95%" height={100}>
      <AreaChart data={memoryMetrics} syncId="taskGraphs">
        <XAxis
          dataKey="time"
          domain={["dataMin", "dataMax"]}
          name="Time"
          tickFormatter={graphDateFormatter}
          type="number"
        />
        <YAxis
          dataKey="value"
          name="Value"
          type="number"
          tickFormatter={(value: unknown) => prettyBytes(value as number)}
        />
        <Area
          type="monotone"
          dataKey="value"
          stroke="#8FA2F4"
          fill="#8FA2F4"
          name="Memory"
        />
        <Tooltip
          formatter={(value: unknown) => prettyBytes(value as number)}
          labelFormatter={value => `Time : ${graphDateFormatter(value)}`}
          labelStyle={{ color: "#67788E" }}
        />
      </AreaChart>
    </ResponsiveContainer>
  </>
);

export default MemoryGraph;
