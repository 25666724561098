import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import clsx from "clsx";

import { TaskRunSummary } from "@stratus/tes";
import TaskStatusChip from "./status/TaskStatusChip";
import RootStoreContext from "../../contexts/RootStoreContext";
import { Hidden, TableSortLabel } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  table: {
    tableLayout: "fixed"
  },
  truncatedColumn: {
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  taskCell: {
    borderBottom: "none"
  }
});

interface TaskRunsTableProps {
  taskRuns: TaskRunSummary[];
}

/**
 * TaskRunsTable renders a page of task runs
 * @param tokens — list of task runs
 */
const TaskRunsTable: React.FC<TaskRunsTableProps> = ({ taskRuns }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const { taskStore, uiStore } = useContext(RootStoreContext);

  const history = useHistory();

  const handleSortKeyChange = (newKey: string) => {
    if (taskStore.sortKey === newKey) {
      taskStore.toggleSortDirection();
    } else {
      taskStore.updateSortKey(newKey);
    }
  };

  const handleRowClick = (taskRunId: string) => {
    uiStore.changeRoute(`/tasks/${taskRunId}`);
    history.push(`/tasks/${taskRunId}`);
  };

  return (
    <>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            {/* These aren't created in a loop so
            that i18n-parser to pick up the keys */}
            <TableCell>
              <TableSortLabel
                direction={taskStore.sortDirection}
                active={taskStore.sortKey === "name"}
                onClick={() => handleSortKeyChange("name")}
              >
                {t("tasks.runs.table.header.task")}
              </TableSortLabel>
            </TableCell>
            <TableCell style={{ width: "90px" }}>
              <TableSortLabel
                direction={taskStore.sortDirection}
                active={taskStore.sortKey === "status"}
                onClick={() => handleSortKeyChange("status")}
              >
                {t("tasks.runs.table.header.status")}
              </TableSortLabel>
            </TableCell>

            <Hidden smDown>
              <TableCell>
                <TableSortLabel
                  direction={taskStore.sortDirection}
                  active={taskStore.sortKey === "timeCreated"}
                  onClick={() => handleSortKeyChange("timeCreated")}
                >
                  {t("tasks.runs.table.header.created")}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  direction={taskStore.sortDirection}
                  active={taskStore.sortKey === "timeModified"}
                  onClick={() => handleSortKeyChange("timeModified")}
                >
                  {t("tasks.runs.table.header.modified")}
                </TableSortLabel>
              </TableCell>
            </Hidden>
          </TableRow>
        </TableHead>
        <TableBody>
          {taskRuns.map((taskRun, index) => {
            return (
              <React.Fragment key={index}>
                <TableRow
                  hover
                  style={{ cursor: "pointer" }}
                  onClick={() => handleRowClick(taskRun.id!)}
                >
                  <TableCell
                    className={clsx(classes.truncatedColumn, classes.taskCell)}
                  >
                    {taskRun.name}
                  </TableCell>
                  <TableCell className={classes.taskCell}>
                    <TaskStatusChip status={taskRun.status!} />
                  </TableCell>

                  <Hidden smDown>
                    <TableCell className={classes.taskCell}>
                      {t("common:datetime", {
                        date: new Date(taskRun.timeCreated as Date)
                      })}
                    </TableCell>
                    <TableCell className={classes.taskCell}>
                      {t("common:datetime", {
                        date: new Date(taskRun.timeModified as Date)
                      })}
                    </TableCell>
                  </Hidden>
                </TableRow>
                {/* <TableRow>
                  <TableCell
                    colSpan={matches ? 2 : 4}
                    padding="none"
                    style={{ borderTop: "none" }}
                  >
                    <Collapse
                      in={uncollapsedRow.includes(index)}
                      timeout="auto"
                      unmountOnExit
                      style={{ width: "96%", padding: 6 }}
                    >
                      <TaskRunDetailsContainer
                        task={taskRun}
                        close={() => handleRowCollapse(index)}
                      />
                    </Collapse>
                  </TableCell>
                </TableRow> */}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default observer(TaskRunsTable);
