import { observable, action, runInAction } from "mobx";
import {
  ServiceHealthResponse,
  SystemHealthResponse,
  HealthApi
} from "@stratus/console";
import { API_BASE } from "../../constants";
import { timeout, handleAPIError } from "../../util";

/**
 * HealthStore is a MobX domain store for service health
 * data and associated actions, e.g. retreiving and storing
 * reported health status
 */
class HealthStore {
  @observable serviceHealth: Array<ServiceHealthResponse> = [];
  @observable systemHealth: SystemHealthResponse = {};

  private api: HealthApi;

  constructor() {
    this.api = new HealthApi({ basePath: API_BASE });
  }

  /**
   * checkHealth gathers service health for all known services via
   * the developer console /health endpoint
   */
  @action("call health endpoint and store result")
  getHealth = async () => {
    try {
      const healthResponse = await timeout(this.api.serviceHealth());
      runInAction(() => {
        this.systemHealth = healthResponse;
        this.serviceHealth = healthResponse.details || [];
      });
    } catch (errResponse) {
      await handleAPIError(errResponse);
    }
  };
}

const store = new HealthStore();
export default store;

export type HealthStoreModel = typeof store;
