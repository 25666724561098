import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/styles/makeStyles";
import { Theme } from "@material-ui/core/styles";

import { SystemHealthResponseStatus } from "../../constants/enums";
import HealthStatusMap from "./HealthStatusMap";
import clsx from "clsx";

interface StyleProps {
  status: SystemHealthResponseStatus;
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  circle: {
    height: "1rem",
    width: "1rem",
    borderRadius: "50%",
    display: "inline-block",
    marginRight: spacing(1),
    marginBottom: "-0.15rem"
  },
  filledCircle: ({ status }: StyleProps) => {
    switch (status) {
      case SystemHealthResponseStatus.Healthy:
        return { backgroundColor: "#27AA36" };
      case SystemHealthResponseStatus.Unhealthy:
        return { backgroundColor: "#DE3439" };
      case SystemHealthResponseStatus.Degraded ||
        SystemHealthResponseStatus.Unknown:
        return { backgroundColor: "#F5B500" };
      default:
        return { backgroundColor: "#DE3439" };
    }
  }
}));

interface SummaryStatusProps {
  status: SystemHealthResponseStatus;
}

/**
 * SummaryStratus renders status text with a coloured circle
 * @param status Summary status over all services, e.g. all healthy
 *   would be 'Operating normally'.
 */
const SummaryStatus: React.FC<SummaryStatusProps> = ({ status }) => {
  const classes = useStyles({ status });
  return (
    <>
      <Box component="div">
        <Tooltip title={status}>
          <div className={clsx(classes.circle, classes.filledCircle)} />
        </Tooltip>
        <Typography variant="subtitle2" component="span">
          <HealthStatusMap status={status} />
        </Typography>
      </Box>
    </>
  );
};

export default SummaryStatus;
