import { observable, action, runInAction, computed } from "mobx";

import authStore from "./AuthStore";
import {
  UsagesApiFp,
  PeriodUsageSummary,
  UsageResponse
} from "@stratus/console";
import { handleAPIError, timeout } from "../../util";
import { API_BASE } from "../../constants";

/**
 * UsageStore is a MobX domain store for retreiving service
 * usage information using the developer console usage API
 */
class UsageStore {
  @observable usage: PeriodUsageSummary[] = [];
  @observable currentPeriodUsage: PeriodUsageSummary | undefined = undefined;
  @observable billing: boolean = false;

  private readonly authStore = authStore;

  @action("toggle billing for display")
  toggleBilling = () => (this.billing = !this.billing);

  @action("retreive usage details")
  getUsage = async (periods: number = 1) => {
    let jwt = await this.authStore.getJwtBearer();
    if (jwt !== undefined) {
      try {
        const response = (await timeout(
          UsagesApiFp({
            basePath: API_BASE,
            apiKey: jwt
          }).getUsage(periods)(undefined, API_BASE),
          20
        )) as UsageResponse;
        if (response && response.items && response.items.length > 0) {
          runInAction(() => {
            this.usage = response.items!;
            this.setCurrentPeriodUsage(response.items![0]);
          });
        } else {
          throw new Error("Unexpected API response");
        }
      } catch (err) {
        await handleAPIError(err);
      }
    }
  };

  @action("set active period usage")
  private setCurrentPeriodUsage = (usage: PeriodUsageSummary) => {
    if (!this.currentPeriodUsage) {
      this.currentPeriodUsage = usage;
    }
  };

  @computed
  get storageTotal() {
    let total = 0;
    // many optional properties here from SDK, manual checks for
    // type safety
    this.usage &&
      this.usage.length > 0 &&
      this.usage[0].totalUsages &&
      this.usage[0].totalUsages.forEach(usage => {
        if (usage.type.split(".").length === 3) {
          const type = usage.type.split(".")[1];
          if (
            type.toLowerCase() === "storage" &&
            usage.unit &&
            usage.unit.toLowerCase() === "byte days"
          ) {
            if (this.billing) {
              total += usage.iCredit || 0;
            } else {
              total += usage.amount;
            }
          }
        }
      });
    return total;
  }

  @computed
  get transferTotal() {
    let total = 0;
    // many optional properties here from SDK, manual checks for
    // type safety
    this.usage &&
      this.usage.length > 0 &&
      this.usage[0].totalUsages &&
      this.usage[0].totalUsages.forEach(usage => {
        if (usage.type.split(".").length >= 3) {
          const type = usage.type.split(".")[1];
          if (type.toLowerCase() === "transfer") {
            if (this.billing) {
              total += usage.iCredit || 0;
            } else {
              total += usage.amount;
            }
          }
        }
      });
    return total;
  }

  @computed
  get computeTotal() {
    let total = 0;
    // many optional properties here from SDK, manual checks for
    // type safety
    this.usage &&
      this.usage.length > 0 &&
      this.usage[0].totalUsages &&
      this.usage[0].totalUsages.forEach(usage => {
        if (usage.type.split(".").length >= 2) {
          const type = usage.type.split(".")[1];
          if (
            type.toLowerCase() === "compute" &&
            usage.unit &&
            usage.unit.toLowerCase() === "minutes"
          ) {
            if (this.billing) {
              total += usage.iCredit || 0;
            } else {
              total += usage.amount;
            }
          }
        }
      });
    return total;
  }
}

const store = new UsageStore();
export default store;

export type UsageStoreModel = typeof store;
