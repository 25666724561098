import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { observer } from "mobx-react";
import RootStoreContext from "../../contexts/RootStoreContext";

/* if the route is protected, redirect to login, and redirect back to route once logged in */
const SmartRoute = ({ component, isProtected, location, ...rest }: any) => {
  const { authStore, uiStore } = useContext(RootStoreContext);
  //is a protected route and user is not logged in
  const redirect = isProtected && !authStore.isLoggedIn;
  const routeComponent = (props: any) =>
    redirect ? (
      <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
    ) : (
      React.createElement(component, props)
    );
  if (redirect) {
    uiStore.changeRoute("/login");
  }
  //only call change route when we have change our route
  else if (uiStore.currentRoute !== location.pathname) {
    uiStore.changeRoute(location.pathname);
  }
  return <Route {...rest} render={routeComponent} />;
};
export default observer(SmartRoute);
