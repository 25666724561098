import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import { WorkflowRun } from "@stratus/wes";
import WorkflowStatusChip from "../status/WorkflowStatusChip";
import { WorkflowRunStatus } from "@stratus/wes";

interface WorkflowRunDetailsListProps {
  workflowRun: WorkflowRun;
}

/**
 * WorkflowRunDetailsList takes a task run and presents a summary list of
 * key fields
 */
const WorkflowRunDetailsList: React.FC<WorkflowRunDetailsListProps> = ({
  workflowRun
}) => {
  const [t] = useTranslation();
  return (
    <>
      <Typography variant="h4">Run</Typography>
      <Typography variant="body2" component="div">
        <List dense>
          <ListItem>
            <strong>{t("task.details.id")}:</strong>&nbsp;{workflowRun.id}
          </ListItem>
          {workflowRun.status && (
            <ListItem>
              <strong>{t("workflows.details.status")}:</strong>&nbsp;
              <WorkflowStatusChip
                status={(workflowRun.status as unknown) as WorkflowRunStatus}
              />
            </ListItem>
          )}
          <ListItem>
            <strong>{t("task.details.created")}:</strong>&nbsp;
            {t("common:date", {
              date: new Date((workflowRun.timeCreated as unknown) as string)
            })}
          </ListItem>
          <ListItem>
            <strong>{t("task.details.modified")}:</strong>&nbsp;
            {t("common:date", {
              date: new Date((workflowRun.timeModified as unknown) as string)
            })}
          </ListItem>
        </List>
      </Typography>
    </>
  );
};

export default WorkflowRunDetailsList;
