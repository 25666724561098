import { makeStyles, Theme } from "@material-ui/core";

/**
 * Global CSS styles that can be imported and re-used
 * across components. Use alongside local `useStyles`
 * with: `const globalClasses = useGlobalStyles()`
 */
const useGlobalStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  paddedContainer: {
    paddingLeft: spacing(5),
    paddingRight: spacing(5),
    paddingTop: spacing(2)
  },
  tokenDisplay: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  tabBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    marginBottom: ".5rem"
  },
  rightFlex: {
    display: "flex",
    justifyContent: "flex-end"
  },
  supIcon: {
    fontSize: ".9rem",
    position: "relative",
    top: "-.5rem",
    opacity: 0.5
  },
  underAppBar: {
    position: "absolute",
    top: 56,
    [`${breakpoints.up("xs")} and (orientation: landscape)`]: {
      top: 48
    },
    [breakpoints.up("sm")]: {
      top: 64
    },
    minWidth: "100%"
  }
}));

export default useGlobalStyles;
