import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  Tab,
  Tabs,
  Button
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { FileResponse } from "@stratus/gds";
import { TaskRun } from "@stratus/tes";

import CodeBlock from "../layout/CodeBlock";
import TaskRunDetailsList from "./TaskRunDetailsList";
import TaskMetrics from "./metrics/TaskMetrics";
import { PodMetrics } from "./metrics/PodMetricsType";

interface TaskRunDetailsProps {
  taskDetails: TaskRun;
  logs: FileResponse[];
}

/**
 * TaskRunDetailsCard renders the details view for a TES task run,
 * also displaying logs pulled from GDS
 */
const TaskRunDetailsCard: React.FC<TaskRunDetailsProps> = ({
  taskDetails,
  logs
}) => {
  const [logContent, setLogContent] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [showMetricsGraph, setShowMetricsGraph] = useState(false);
  const [taskMetrics, setTaskMetrics] = useState([] as PodMetrics[]);
  const [activeLog, setActiveLog] = useState(logs[0]);
  const [t] = useTranslation();
  const [activeTab, setActiveTab] = useState(0);

  const metricsLog = logs.find(log =>
    log.name!.toLowerCase().startsWith("pod-metrics")
  );
  const filteredLogs = logs.filter(
    log => !log.name!.toLowerCase().startsWith("pod-metrics")
  );

  useEffect(() => {
    let active = true;
    const fetchMetricsLog = async () => {
      if (active && metricsLog && metricsLog.presignedUrl) {
        let metrics: PodMetrics[] = [];
        let content: string;

        try {
          const fileContent = await fetch(metricsLog.presignedUrl);
          content = await fileContent.text();
        } catch (e) {
          console.error("failed getting metrics");
          setShowMetricsGraph(false);
          return;
        }

        // try to parse as ndjson
        try {
          const rows = content.split(/\n|\n\r/).filter(Boolean);
          rows.forEach(row => {
            if (row.startsWith(`{"kind":"PodMetrics"`)) {
              metrics.push(JSON.parse(row));
            }
          });
          if (metrics.length) {
            setTaskMetrics(metrics);
            setShowMetricsGraph(true);
          } else {
            throw Error("metrics not in ndjson format");
          }
        } catch (err) {
          console.error(`failed parsing pod metrics as ndjson`);

          // early pod-metrics were not valid ndjson
          try {
            content = `[${content.replace(/}\s*{/g, "}, {")}]`;
            const metrics = JSON.parse(content);
            setTaskMetrics(metrics);
            setShowMetricsGraph(true);
          } catch (err) {
            console.error("couldn't parse pod metrics as JSON for graphing");
          }
        }
      }
    };
    fetchMetricsLog();
    return () => {
      active = false;
    };
  }, [metricsLog]);

  // get log files as they are selected
  useEffect(() => {
    let active = true;
    setIsLoaded(false);
    const fetchLogs = async () => {
      if (activeLog && activeLog.presignedUrl) {
        const logContent = await fetch(activeLog.presignedUrl);
        let content = await logContent.text();
        if (active) {
          setLogContent(content);
        }
        setIsLoaded(true);
      }
    };
    fetchLogs();
    return () => {
      active = false;
    };
  }, [activeLog]);

  const handleTabChange = (index: number) => {
    setActiveTab(index);
    setActiveLog(filteredLogs[index]);
  };

  return (
    <Card style={{ padding: 8 }}>
      <CardContent style={{ padding: "0 16px" }}>
        <Grid container>
          <Grid item xs={12} sm={5}>
            <TaskRunDetailsList task={taskDetails} />
          </Grid>
          {showMetricsGraph && (
            <Grid item xs={12} sm={7}>
              <Typography variant="body1">
                {t("task.details.resourceUsage")}
              </Typography>
              <TaskMetrics metrics={taskMetrics} />
            </Grid>
          )}
        </Grid>

        {filteredLogs && filteredLogs.length > 0 ? (
          <>
            <Typography variant="body1">{t("task.details.logs")}</Typography>
            <Tabs
              value={activeTab}
              onChange={(_, v) => handleTabChange(v)}
              variant="scrollable"
              scrollButtons="on"
              style={{ height: 24 }}
            >
              {filteredLogs.map(logFile => {
                return (
                  <Tab
                    label={logFile.name!.replace(/\.log$/, "")}
                    key={logFile.id}
                  />
                );
              })}
            </Tabs>

            <CodeBlock maxHeight={300} isLoaded={isLoaded}>
              {logContent}
            </CodeBlock>
          </>
        ) : (
          <Typography variant="h4">{t("task.details.nologs")}</Typography>
        )}
      </CardContent>
      <CardActions>
        {filteredLogs && filteredLogs[activeTab] !== undefined && (
          <Button
            href={filteredLogs[activeTab].presignedUrl}
            variant="contained"
          >
            {t("task.details.download")}
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default TaskRunDetailsCard;
