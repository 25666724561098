import React from "react";
import { useTranslation } from "react-i18next";

import { WorkflowRunStatus } from "@stratus/wes";

interface WorkflowRunStatusMapProps {
  status: WorkflowRunStatus;
}

const workflowStatus = WorkflowRunStatus;

/**
 * WorkflowRunStatusMap is a small component for taking the reported
 * workflow run status from the Workflow Execution Service and
 * returning a UI-friendly + localised string.
 * @param status workflow run status as returned by the API
 */
const WorkflowRunStatusMap: React.FC<WorkflowRunStatusMapProps> = ({
  status
}) => {
  const [t] = useTranslation();
  const statusString = status.toString();

  const statusMap = (status: string) => {
    switch (status) {
      case workflowStatus[workflowStatus.Succeeded]:
        return t("workflow.status.succeeded");
      case workflowStatus[workflowStatus.Aborted]:
        return t("workflow.status.aborted");
      case workflowStatus[workflowStatus.Aborting]:
        return t("workflow.status.aborting");
      case workflowStatus[workflowStatus.Failed]:
        return t("workflow.status.failed");
      case workflowStatus[workflowStatus.New]:
        return t("workflow.status.new");
      case workflowStatus[workflowStatus.Running]:
        return t("workflow.status.running");
      case workflowStatus[workflowStatus.Starting]:
        return t("workflow.status.starting");
      case workflowStatus[workflowStatus.TimedOut]:
        return t("workflow.status.timedout");
      default:
        return t("workflow.status.unknown");
    }
  };
  return <>{statusMap(statusString)}</>;
};

export default WorkflowRunStatusMap;
