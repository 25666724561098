import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Button,
  Theme,
  Grid,
  Hidden
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ResourceLinks from "./common/ResourceLinks";
import { navButtonListHref } from "../../constants/index";

const useStyles = makeStyles((theme: Theme) => ({
  demoSection: {
    background: "linear-gradient(to right, #383838, #4F5A67)",
    height: theme.spacing(9.5)
  },
  demo: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "24px"
    },
    color: "#FFF",
    fontSize: "18x",
    padding: theme.spacing(2),
    fontWeight: 500
  },
  divider: {
    height: "75%",
    borderLeft: "3px #FFB441 solid"
  },
  demoButton: {
    width: "180px",
    height: "40px",
    fontSize: "18px",
    fontWeight: 500,
    backgroundColor: "#328ED1",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#328ED1"
    }
  },
  title: {
    fontSize: "18px",
    fontWeight: 900,
    color: "#4F5A67"
  },
  footer: {
    background: "linear-gradient(to right, #383838, #4F5A67)",
    height: "48px"
  },
  linkBox: {
    margin: theme.spacing(3)
  }
}));

const Footer: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();

  const resourceLinks = navButtonListHref.map(link => (
    <Grid item key={link.title} className={classes.linkBox}>
      <Typography key={`Title_${link.title}`} className={classes.title}>
        {link.title}
      </Typography>
      <ResourceLinks key={`Links_${link.title}`} resources={link.resources} />
    </Grid>
  ));

  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Grid
        container
        className={classes.demoSection}
        justify="center"
        alignItems="center"
        wrap="nowrap"
      >
        <Hidden smDown>
          <Box mx={3} className={classes.divider}></Box>
          <Typography className={classes.demo}>
            {t("landing.demo.intro")}
          </Typography>
        </Hidden>
        <Button className={classes.demoButton}>
          {t("landing.demo.button")}
        </Button>
      </Grid>

      <Grid container justify="center">
        {resourceLinks}
      </Grid>

      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.footer}
      >
        <Typography style={{ color: "white" }}>
          {t("landing.copyright")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
