import * as React from "react";
import clsx from "clsx";
import {
  MenuItem,
  Box,
  Typography,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  makeStyles,
  Link
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { ResourceComponentProp, ResourceLink } from "../../types";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  openButton: {
    borderBottom: "2px #328ED1 solid"
  },
  // prevent jitter when border applied
  closedButton: {
    borderBottom: "2px #ffffff solid"
  },
  dropDown: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1.5)
  },
  subMenuTitle: {
    padding: theme.spacing(1)
  },
  dropDownText: {
    color: "#a3a3a3",
    textTransform: "uppercase"
  }
}));

interface NavDropdownProps {
  dropDownTitle: string;
  dropDownResources: ResourceComponentProp[];
}

const NavDropdown: React.FC<NavDropdownProps> = ({
  dropDownTitle,
  dropDownResources
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const menuItems = (navLinks: ResourceLink[]) =>
    navLinks.map((link, index) => (
      <MenuItem key={index}>
        <Link
          underline="none"
          variant="h6"
          color="initial"
          href={link.path}
          onClick={handleClose}
        >
          {link.name}
        </Link>
      </MenuItem>
    ));

  const subMenus = dropDownResources.map((resource, idx) => {
    return (
      <Box key={idx}>
        {resource.name && (
          <Typography className={classes.subMenuTitle} variant="h3">
            {resource.name}
          </Typography>
        )}
        {menuItems(resource.resourceLinks)}
      </Box>
    );
  });

  return (
    <div className={classes.root}>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={clsx(
          classes.dropDownText,
          open ? classes.openButton : classes.closedButton
        )}
        endIcon={<ArrowDropDownIcon />}
      >
        {dropDownTitle}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "center top" : "center bottom"
            }}
          >
            <Paper className={classes.dropDown}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {subMenus}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default NavDropdown;
