import React from "react";
import { useTranslation } from "react-i18next";

import { SystemHealthResponseStatus } from "../../constants/enums";

interface HealthStatusMapProps {
  status: SystemHealthResponseStatus;
}

/**
 * HealthStatusMap is a small component for taking the reported
 * health status from the console endpoint and returning a UI-
 * friendly + localised string.
 * @param status health status as returned by the API
 */
const HealthStatusMap: React.FC<HealthStatusMapProps> = ({ status }) => {
  const [t] = useTranslation();

  const statusMap = (status: SystemHealthResponseStatus) => {
    switch (status) {
      case SystemHealthResponseStatus.Healthy:
        return t("health.status.healthy");
      case SystemHealthResponseStatus.Unhealthy:
        return t("health.status.down");
      case SystemHealthResponseStatus.Degraded:
        return t("health.status.degraded");
      default:
        return t("health.status.unknown");
    }
  };
  return <>{statusMap(status)}</>;
};

export default HealthStatusMap;
