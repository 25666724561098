import React, { useContext } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { AppBar, Toolbar, Grid } from "@material-ui/core/";
import Hidden from "@material-ui/core/Hidden";
import { observer } from "mobx-react";

import NavButtonList from "./NavButtonList";
import NavDrawerIconButton from "./NavDrawerIconButton";
import IlmnLogo from "./IlmnLogo";
import DashboardLink from "./DashboardLink";
import UserDropdown from "./user/UserDropdown";
import RootStoreContext from "../../contexts/RootStoreContext";

const useStyles = makeStyles(({ palette, spacing, zIndex }: Theme) => ({
  root: {
    display: "flex"
  },
  spacer: {
    flexGrow: 1
  },
  nav: {
    position: "fixed",
    backgroundColor: "#fefefe",
    background: "transparent",
    color: palette.primary.main,
    zIndex: zIndex.drawer + 50,
    marginBottom: spacing(2),
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
  },
  dropDowns: {
    flexShrink: 1
  }
}));

const NavBar: React.FC = () => {
  const classes = useStyles();
  const { authStore, uiStore } = useContext(RootStoreContext);
  return (
    <div className={classes.root}>
      <AppBar className={classes.nav}>
        <Toolbar>
          <Grid container alignItems="center" wrap="nowrap">
            <NavDrawerIconButton />
            <IlmnLogo />
            <div className={classes.spacer}></div>
            <Hidden smDown>
              {uiStore.currentRoute !== "/" ? null : (
                <>
                  {authStore.isLoggedIn ? <DashboardLink /> : null}
                  <NavButtonList />
                </>
              )}
            </Hidden>
            <div className={classes.spacer}></div>
            <UserDropdown />
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default observer(NavBar);
