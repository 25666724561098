import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  ExpansionPanelActions,
  Button
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import TaskRunFilterForm from "./TaskRunFilterForm";
import RootStoreContext from "../../../contexts/RootStoreContext";

/**
 * TaskRunFiltersContainer wraps a the expanding set of filters
 * which can optionally be applied to the task run table.
 */
const TaskRunFiltersContainer: React.FC = () => {
  const { taskStore } = useContext(RootStoreContext);
  const [nameFilter, setNameFilter] = useState<string | undefined>(undefined);
  const [statusFilter, setStatusFilter] = useState<string | undefined>(
    undefined
  );

  const [t] = useTranslation();

  const handleApplyFiltersClick = (_?: React.MouseEvent) => {
    taskStore.setFilterStatus(statusFilter);
    taskStore.setFilterTaskName(nameFilter);
    taskStore.resetTaskLists();
  };

  return (
    <ExpansionPanel style={{ marginBottom: "2rem" }}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="task-run-filter"
        id="task-filters"
      >
        <Typography variant="h4">{t("tasks.filters.heading")}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ paddingBottom: 0 }}>
        <TaskRunFilterForm
          currentNameFilter={taskStore.filterName}
          nameSetter={setNameFilter}
          currentStatusFilter={taskStore.filterStatus}
          statusSetter={setStatusFilter}
          onSubmit={() => handleApplyFiltersClick()}
        />
      </ExpansionPanelDetails>
      <ExpansionPanelActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleApplyFiltersClick}
        >
          {t("tasks.filters.apply")}
        </Button>
      </ExpansionPanelActions>
    </ExpansionPanel>
  );
};

export default observer(TaskRunFiltersContainer);
