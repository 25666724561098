import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React from "react";
import { Button, Theme, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { API_DOCS, API_DOCS_ROOT } from "../../../constants";

const useStyles = makeStyles((theme: Theme) => ({
  linkButton: {
    //padding to match landing card
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  linkText: {
    fontWeight: 300,
    fontSize: "13px",
    color: "#328ED1"
  },
  linkIcon: {
    color: "#328ED1"
  }
}));

interface LandingCardLinkComponentProp {
  cardKey: string;
  linkName: string;
  overrideStyle?: any;
}
// We are hard coding these paths here for now. Maybe move them to helm chart and read in as environment variables?
const docHrefs: { [index: string]: string } = {
  GDS: API_DOCS + "/gds_introduction",
  WES: API_DOCS + "/wes_introduction",
  TES: API_DOCS + "/tes_introduction",
  ENS: API_DOCS + "/ens_introduction",
  START: API_DOCS + "/overview_getting-started",
  TUTORIALS: API_DOCS_ROOT + "/reference",
  COMMUNITY: API_DOCS,
  CHANGELOG: API_DOCS_ROOT + "/changelog",
  RNASEQ: API_DOCS + "/dragen-rna-seq-deseq2",
  ENRICHMENT:
    API_DOCS +
    "/dragen-enrichment-targeted-panel-alignment-and-variant-calling",
  SOMATIC: API_DOCS + "/tutorials_dragen-tn",
  GERMLINE: API_DOCS + "/tutorials_dragen-tutorial"
};
const LandingCardLink: React.FC<{ link: LandingCardLinkComponentProp }> = ({
  link
}) => {
  const classes = useStyles();
  const style = link.overrideStyle
    ? { ...classes, ...link.overrideStyle }
    : classes;
  return (
    <Button
      size="small"
      className={style.linkButton}
      endIcon={<ChevronRightIcon className={style.linkIcon} />}
    >
      <Link
        underline="none"
        className={style.linkText}
        href={docHrefs[link.cardKey]}
      >
        {link.linkName}
      </Link>
    </Button>
  );
};

export default LandingCardLink;
