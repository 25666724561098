import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import WorkflowContainer from "../components/workflows/WorkflowContainer";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start"
  }
});

/**
 * WorkflowView lays out the view for workflow monitoring
 */
const WorkflowView: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <WorkflowContainer />
    </Grid>
  );
};

export default WorkflowView;
