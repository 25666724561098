import AuthStore from "./domain/AuthStore";
import HealthStore from "./domain/HealthStore";
import TokenOptionsStore from "./domain/TokenOptionsStore";
import UIStore from "./UIStore";
import UsageStore from "./domain/UsageStore";
import UserStore from "./domain/UserStore";
import TaskStore from "./domain/TaskStore";
import DeviceStore from "./domain/DeviceStore";
import WorkflowStore from "./domain/WorkflowStore";

class RootStore {
  authStore = AuthStore;
  healthStore = HealthStore;
  taskStore = TaskStore;
  tokenStore = TokenOptionsStore;
  usageStore = UsageStore;
  deviceStore = DeviceStore;
  userStore = UserStore;
  workflowStore = WorkflowStore;
  uiStore = UIStore;
}

const rootStore = new RootStore();
export default rootStore;
