import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";

import ExpandableOptionSet from "./ExpandableOptionSet";
import RootStoreContext from "../../../contexts/RootStoreContext";

const ACLOptionForm: React.FC = () => {
  const [t] = useTranslation();
  const { tokenStore } = useContext(RootStoreContext);

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {t("tokens.advanced.acl.header")}
      </Typography>
      <Typography variant="body2">
        {t("tokens.advanced.acl.description")}
      </Typography>

      <ExpandableOptionSet max={5} collector={tokenStore.setACLs} label="acl" />
    </>
  );
};

export default observer(ACLOptionForm);
