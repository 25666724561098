import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import Grid from "@material-ui/core/Grid";

import HealthSummary from "./HealthSummary";
import PaddedDivider from "../layout/PaddedDivider";
import HealthDetails from "./HealthDetails";
import RootStoreContext from "../../contexts/RootStoreContext";
import useGlobalStyles from "../../styles/classes";
import ErrorSnackbar from "../errors/ErrorSnackbar";

/**
 * HealthContainer maps the layout for the service health view,
 * connecting with the MobX domain store for health status.
 */
const HealthContainer: React.FC = () => {
  const { healthStore } = useContext(RootStoreContext);
  const globalClasses = useGlobalStyles();
  const [error, setError] = useState("");

  useEffect(() => {
    (async () => {
      try {
        await healthStore.getHealth();
      } catch (err) {
        setError(err.message);
      }
    })();
  }, [healthStore]);

  return (
    <>
      <Grid item xs={12} className={globalClasses.paddedContainer}>
        <HealthSummary health={healthStore.systemHealth} />
      </Grid>

      <PaddedDivider />

      <Grid item xs={12} className={globalClasses.paddedContainer}>
        <HealthDetails health={healthStore.serviceHealth} />
      </Grid>

      {error !== "" && <ErrorSnackbar error={error} clearOnClose={setError} />}
    </>
  );
};

export default observer(HealthContainer);
