import React from "react";
import { Chip, makeStyles } from "@material-ui/core";
import clsx from "clsx";

import WorkflowStatusMap from "./WorkflowStatusMap";
import { WorkflowRunStatus } from "@stratus/wes";

interface StyleProps {
  status: string;
}

interface WorkflowStatusChipProps {
  status: WorkflowRunStatus;
}

const workflowStatus = WorkflowRunStatus;

const useStyles = makeStyles({
  root: {
    textTransform: "uppercase"
  },
  chip: ({ status }: StyleProps) => {
    switch (status) {
      case workflowStatus[workflowStatus.Succeeded]:
        return {
          color: "#186621",
          background: "#DDF8E0"
        };
      case workflowStatus[workflowStatus.Running]:
      case workflowStatus[workflowStatus.Starting]:
      case workflowStatus[workflowStatus.New]:
        return {
          background: "#F5B500",
          color: "#FCEEEE"
        };
      case workflowStatus[workflowStatus.Aborted]:
      case workflowStatus[workflowStatus.Aborting]:
      case workflowStatus[workflowStatus.Failed]:
      case workflowStatus[workflowStatus.TimedOut]:
        return { background: "#AE1B1F", color: "#FCEEEE" };
      default:
        return {};
    }
  }
});

/**
 * WorkflowStatusChip displays a workflow run status in a styled Chip component
 * @param status workflow status
 */
const WorkflowStatusChip: React.FC<WorkflowStatusChipProps> = ({ status }) => {
  const classes = useStyles({ status: status.toString() });

  return (
    <Chip
      label={<WorkflowStatusMap status={status} />}
      className={clsx(classes.root, classes.chip)}
    />
  );
};

export default WorkflowStatusChip;
