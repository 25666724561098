import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Select from "react-select";
import { ValueType } from "react-select/src/types";

import RootStoreContext from "../../../contexts/RootStoreContext";
import { makeStyles, Theme } from "@material-ui/core";

interface SelectOption {
  label: string;
  value: string;
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  select: {
    marginBottom: spacing(3),
    fontSize: "smaller"
  }
}));

/**
 * WorkgroupOptionContainer is a smart store-connected component
 * for choosing current workgroup ID (cwid) to apply to a generated
 * token.
 */
const WorkgroupOptionContainer: React.FC = () => {
  const [t] = useTranslation();
  const { tokenStore } = useContext(RootStoreContext);
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([] as SelectOption[]);

  useEffect(() => {
    let active = true;
    active &&
      (async () => {
        setIsLoading(true);
        try {
          await tokenStore.getWorkgroupIDs();
          const opts = tokenStore.workgroups.map(wg => {
            return {
              value: wg.id || "",
              label: wg.name || "Unnamed workgroup"
            };
          });
          setOptions(opts);
        } catch (err) {
          console.error(err);
          setOptions([]);
        }
        setIsLoading(false);
      })();
    return () => {
      active = false;
    };
  }, [tokenStore]);

  const handleChangeWorkgroup = (value: ValueType<SelectOption>) => {
    tokenStore.setWorkgroup(value ? (value as SelectOption).value : "");
  };

  const classes = useStyles();

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {t("tokens.advanced.cwid.header")}
      </Typography>
      <Typography variant="body2">
        {t("tokens.advanced.cwid.description")}
      </Typography>

      <Select
        className={classes.select}
        inputId="workgroup-select"
        isLoading={isLoading}
        isClearable={true}
        TextFieldProps={{
          label: t("tokens.advanced.cwid.current"), // "Current workgroup",
          InputLabelProps: {
            htmlFor: "workgroup-select",
            shrink: true
          }
        }}
        placeholder={t("tokens.advanced.cwid.select")} // "Select a workgroup"
        options={options}
        onChange={handleChangeWorkgroup}
      />
    </>
  );
};

export default observer(WorkgroupOptionContainer);
