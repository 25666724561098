import React, { useState, useRef } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
import { makeStyles, Theme } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles(({ spacing }: Theme) => ({
  wrapper: {
    display: "flex",
    paddingLeft: spacing(1),
    backgroundColor: "#e6e6e6",
    borderRadius: 4
  },
  minimalWrapper: {
    display: "flex",
    padding: 0,
    fontSize: ".7rem",
    "& input": {
      padding: 0,
      fontSize: "0.9rem",
      fontWeight: 400
    }
  },
  token: {
    flexGrow: 2,
    "& input": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    "& div": {
      marginLeft: 0
    }
  }
}));

interface TokenDisplayProps {
  token: string;
  minimal?: boolean;
}

/**
 * TokenDisplay renders a (long) JWT alongside a divider
 * and copy-to-clipboard control
 * @param token – the JWT string
 * @param minimal – if true, render a more basic container
 */
const TokenDisplay: React.FC<TokenDisplayProps> = ({
  token,
  minimal = false
}) => {
  const tokenInput = useRef<HTMLInputElement>(null);
  const classes = useStyles();
  const defaultTooltip = "Copy to clipboard";
  const [copyTooltip, setCopyTooltip] = useState(defaultTooltip);

  const handleCopyClick = () => {
    if (tokenInput.current === null) return;
    tokenInput.current.select();
    // copy + update tooltip
    document.execCommand("copy");
    setCopyTooltip("Copied!");
    // clear selection + reset tooltip
    tokenInput.current.selectionEnd = 0;
    tokenInput.current.blur();
    setTimeout(() => setCopyTooltip(defaultTooltip), 2000);
  };

  const handleFocus = () => {
    if (tokenInput.current === null) return;
    tokenInput.current.focus();
    // try to select all text by default
    tokenInput.current.setSelectionRange(0, tokenInput.current.value.length);
  };

  return (
    <div className={minimal ? classes.minimalWrapper : classes.wrapper}>
      <InputBase
        value={token}
        inputRef={tokenInput}
        readOnly
        className={classes.token}
        name="token"
        onFocus={handleFocus}
        endAdornment={
          <InputAdornment position="end">
            <Tooltip title={copyTooltip} leaveDelay={1000}>
              <IconButton
                aria-label="Copy"
                onClick={() => handleCopyClick()}
                size="small"
              >
                <FlipToFrontIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
      />
    </div>
  );
};

export default TokenDisplay;
