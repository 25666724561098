import { API_BASE } from "../../constants";
import {
  AccountsApiFp,
  AccountResponse,
  TokensApiFp,
  TokenDetailResponse
} from "@stratus/console";
import { timeout } from "../../util";
import { handleAPIError } from "../../util";
import authStore from "./AuthStore";

type userIdCache = {
  [key: string]: AccountResponse;
};

/**
 * UserStore is a MobX domain store for requesting and
 * storing user information, such as resolving IDs to
 * usernames
 * @class
 */
class UserStore {
  private readonly authStore = authStore;

  private knownTranslations: userIdCache = {};

  /**
   * resolveUsedId converts an IAP user GUID to
   * a human-readable user name for presentation.
   * This is memoized such that once initialised,
   * subsequent calls for the same user ID won't
   * call out the the API.
   * TODO: cache eviction, will this ever be live
   *       long enough?
   */
  resolveUserId = async (userId: string) => {
    if (userId in this.knownTranslations) {
      return this.knownTranslations[userId];
    }
    let jwt = await this.authStore.getJwtBearer();
    if (jwt !== undefined) {
      try {
        const response = (await timeout(
          AccountsApiFp({
            basePath: API_BASE,
            apiKey: jwt
          }).getAccount(userId)(undefined, API_BASE)
        )) as AccountResponse;
        this.knownTranslations[userId] = response;
        return response;
      } catch (err) {
        await handleAPIError(err);
      }
    }
  };

  /**
   * getActiveUser uses the current active JWT to
   * pull user details including username and active
   * workgroup (if any)
   */
  getActiveUser = async () => {
    let jwt = await this.authStore.getJwtBearer();
    if (jwt !== undefined) {
      try {
        const response = (await timeout(
          TokensApiFp({
            basePath: API_BASE,
            apiKey: jwt
          }).getTokenDetails()(undefined, API_BASE)
        )) as TokenDetailResponse;
        return response;
      } catch (err) {
        await handleAPIError(err);
      }
    }
  };
}

const store = new UserStore();
export default store;

export type UserStoreModel = typeof store;
