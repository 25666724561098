import React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme } from "@material-ui/core";

import { PeriodUsageSummary } from "@stratus/console";
import { useTranslation } from "react-i18next";
import { computeTypes, creditsLabel } from "../../../constants";
import SummaryHeader from "./SummaryHeader";
import { computeTransform } from "../../../util/usage";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(3),
    margin: theme.spacing(1)
  },
  value: {
    color: "#fff"
  },
  label: {
    color: "#fff",
    opacity: 0.8,
    paddingBottom: 0
  }
}));

interface ComputeSummaryProps {
  currentUsage: PeriodUsageSummary;
  billing: boolean;
}

/**
 * ComputeSummary presents a high-level overview
 * of compute usage, broken down into categories
 */
const ComputeSummary: React.FC<ComputeSummaryProps> = ({
  currentUsage,
  billing
}) => {
  const [t] = useTranslation();
  const classes = useStyles();

  // labels / formatting for any special case compute that
  // isn't just hours
  const computeLabelLookup: {
    [index: string]: { label: string; formatter: Function };
  } = {
    MemoryGB: {
      label: t("usage.summary.card.header.memory"),
      formatter: (s: number) => computeTransform(s, true)
    }
  };

  const usage = computeTypes.map(type => {
    const usage =
      currentUsage &&
      currentUsage.totalUsages &&
      currentUsage.totalUsages.filter(usage => {
        const usageParts = usage.type.split(".");
        return (
          usageParts[1].toLowerCase() === "compute" && usageParts[2] === type
        );
      });

    if (type in computeLabelLookup && !billing) {
      return {
        ...computeLabelLookup[type],
        amount: usage && usage.length && (usage[0].amount || 0)
      };
    }

    if (billing) {
      return {
        label: computeLabelLookup[type] ? computeLabelLookup[type].label : type,
        amount: usage && usage.length && (usage[0].iCredit || 0),
        formatter: (s: string) => `${parseFloat(s).toFixed(2)} ${creditsLabel}`
      };
    }

    return {
      label: type,
      amount: usage && usage.length && (usage[0].amount || 0),
      formatter: computeTransform
    };
  });

  const cardFills = [
    { start: "#82C5F3", end: "#2A8ED2" },
    { start: "#FFCA87", end: "#E59630" },
    { start: "#74E5E3", end: "#11B8B5" }
  ];

  return (
    <>
      <SummaryHeader period={currentUsage} />
      <Grid container>
        {usage.map((use, index) => {
          let start = "#fff";
          let end = "#fff";
          if (index < cardFills.length) {
            ({ start, end } = cardFills[index]);
          }
          return (
            <Grid item md={4} sm={6} xs={12} key={index}>
              <Card
                className={classes.card}
                style={{
                  backgroundImage: `linear-gradient(225deg, ${start} 0%, ${end} 100%)`
                }}
              >
                <Typography variant="h2" className={classes.value}>
                  {use.formatter(use.amount)}
                </Typography>
                <Typography variant="body2" className={classes.label}>
                  {use.label}
                </Typography>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default ComputeSummary;
