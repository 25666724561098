import { State, Choice, ActionState } from "./stateTypes";

export interface Edge {
  from: string;
  to: string;
}

export interface Graph {
  nodes: State[];
  edges: Edge[];
}

export type Node = State;

const startNode: State = {
  Type: "Start",
  Name: "Start"
};

const endNode: State = {
  Type: "End",
  Name: "End"
};

const parseStates = (stateJSON: any): State[] => {
  const stateList: State[] = [startNode, endNode];
  let foundEnd = false;

  for (let key in stateJSON.States) {
    stateList.push({
      ...stateJSON.States[key],
      // NB. state may contain its own Name field which could
      // differ from the object field name, setting this below
      // will overwrite if present and ensure its the state key
      Name: key
    });
    if (stateJSON.States[key].End) {
      foundEnd = true;
    }
  }

  if (!foundEnd) {
    console.error(`End state not found!`);
    // throw new Error('End state not found!');
  }

  return stateList;
};

const parseEdges = (start: string, states: State[]): Edge[] => {
  const edgeList: Edge[] = [];

  edgeList.push({
    from: startNode.Name,
    to: start
  });

  states.forEach(state => {
    // simple state transitions
    if (state.Next) {
      edgeList.push({
        from: state.Name,
        to: state.Next
      });
    }
    if (state.End) {
      edgeList.push({
        from: state.Name,
        to: endNode.Name
      });
    }

    // choice states use nested Next + Default
    if (state.Type === "Choice") {
      const choiceState = state as Choice;
      for (let choice of choiceState.Choices) {
        edgeList.push({
          from: state.Name,
          to: choice.Next
        });
      }
      if (choiceState.Default) {
        edgeList.push({
          from: state.Name,
          to: choiceState.Default
        });
      }
    }

    // action states may have a catch / next
    if (state.hasOwnProperty("Catch")) {
      const s = state as ActionState;
      for (let c of s.Catch!) {
        edgeList.push({
          from: state.Name,
          to: c.Next
        });
      }
    }

    if (
      state.Type === "Succeed" ||
      state.Type === "Fail" ||
      state.Type === "Action.Fail"
    ) {
      edgeList.push({
        from: state.Name,
        to: endNode.Name
      });
    }
  });

  return edgeList;
};

// // return names of states linking to the given state (parents)
// const linksTo = (state: State, edges: Edge[]): string[] => {
//     let links: string[] = [];
//     edges.forEach((edge) => {
//         if (edge.from === state.Name) links.push(edge.to)
//     });
//     return links;
// }

// // return names of states linking to from the given state (children)
// const linksFrom = (state: State, edges: Edge[]): string[] => {
//     let links: string[] = [];
//     edges.forEach((edge) => {
//         if (edge.to === state.Name) links.push(edge.from)
//     });
//     return links;
// }

/**
 * Currently ISL definitions arrive in JSON fields looking like this:
 *    "definition": "{\n  \"StartAt\": \"CreateVolume\" ... "
 * by removing the unescaped newlines we can parse as JSON
 */
const stripNewlines = (str: string) => {
  return str.replace(/\n/g, "");
};

const buildGraph = (graphDefinition: string): Graph => {
  // validated before this point, still try/catch
  let isl;

  try {
    isl = JSON.parse(stripNewlines(graphDefinition));
  } catch (e) {
    console.error(
      `Failed parsing workflow definition as JSON (${e}):\n${graphDefinition}`
    );
    throw new Error(`Failed parsing workflow definition`);
  }

  const stateList = parseStates(isl);
  const edgeList = parseEdges(isl.StartAt, stateList);

  return {
    nodes: stateList,
    edges: edgeList
  };
};

export default buildGraph;
