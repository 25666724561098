import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import TaskRunDetailsContainer from "../components/tasks/TaskRunDetailsContainer";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start"
  }
});

/**
 * TaskDetailsView lays out the view for an individual task run
 */
const TaskDetailsView: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <TaskRunDetailsContainer />
    </Grid>
  );
};

export default TaskDetailsView;
