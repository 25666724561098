import React from "react";
import prettyBytes from "pretty-bytes";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer
} from "recharts";

import SummaryCard from "./SummaryCard";
import { ProductUsage } from "@stratus/console";
import { GRAPH_PALETTE } from "../../../constants";
import { dataItem } from "../../../types";
import EmptyGraph, { EmptyGraphKey } from "./EmptyGraph";
import DonutLabel from "../charts/DonutLabel";
import { creditsLabel } from "../../../constants";

interface StorageCardProps {
  total: number;
  breakdown: ProductUsage[];
  title: string | JSX.Element;
  helpText?: string;
  unitType?: string;
  icredits?: boolean;
}

/**
 * StorageCard shows a high-level overview of storage
 * usage for the current period
 */
const StorageCard: React.FC<StorageCardProps> = ({
  total,
  breakdown,
  title,
  helpText,
  unitType,
  icredits
}) => {
  const emptyCard = (
    <SummaryCard
      headline={title}
      helpText={helpText}
      graphKey={EmptyGraphKey}
      graph={EmptyGraph}
    />
  );

  let data: dataItem[];
  if (breakdown.length > 0) {
    data = breakdown.map(usage => ({
      name: usage.type
        .split(".")
        .slice(2)
        .join(" "),
      value: icredits ? usage.iCredit || 0 : usage.amount
    }));
  } else {
    // no data
    return emptyCard;
  }

  if (data.every(b => b.value === 0)) {
    return emptyCard;
  }

  const StorageGraph = (
    <ResponsiveContainer width={"100%"} height={200}>
      <PieChart>
        <Pie
          dataKey="value"
          nameKey="name"
          data={data}
          innerRadius={80}
          outerRadius={100}
          fill="#8884d8"
          paddingAngle={0}
          isAnimationActive={false}
        >
          {data.map((_, index) => (
            <Cell
              fill={GRAPH_PALETTE[index % GRAPH_PALETTE.length]}
              key={index}
            />
          ))}
          <Label
            width={30}
            position="center"
            content={
              <DonutLabel
                amount={
                  icredits
                    ? total.toFixed(2)
                    : prettyBytes(total, { locale: true })
                }
                label={icredits ? creditsLabel : unitType || ""}
              />
            }
          />
        </Pie>
        <Tooltip
          separator=": "
          formatter={value =>
            icredits
              ? `${parseFloat(value.toString()).toFixed(2)} ${creditsLabel}`
              : `${prettyBytes(parseInt(value as string))}`
          }
        />
      </PieChart>
    </ResponsiveContainer>
  );

  const StorageLegend = (
    <Legend
      payload={data.map((item, index) => ({
        id: item.name,
        type: "circle",
        value: item.name,
        color: GRAPH_PALETTE[index % GRAPH_PALETTE.length]
      }))}
      layout="horizontal"
      verticalAlign="middle"
      align="center"
      wrapperStyle={{
        position: "inherit",
        maxWidth: "100%",
        marginTop: "1.5rem"
      }}
    />
  );

  return (
    <SummaryCard
      headline={title}
      helpText={helpText}
      graphKey={StorageLegend}
      graph={StorageGraph}
    />
  );
};

export default StorageCard;
