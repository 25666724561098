import React from "react";
import { useTranslation } from "react-i18next";
import { PeriodUsageSummary } from "@stratus/console";
import Grid from "@material-ui/core/Grid";
import { observer } from "mobx-react";

import StorageCard from "./summary/StorageCard";
import LoadingCard from "./summary/LoadingCard";
import SummaryHeader from "./summary/SummaryHeader";

interface UsageSummaryProps {
  currentUsage: PeriodUsageSummary;
  totalStorage: number;
  totalTransfer: number;
  isLoading: boolean;
  billing: boolean;
}

const UsageSummary: React.FC<UsageSummaryProps> = ({
  currentUsage,
  totalTransfer,
  totalStorage,
  isLoading,
  billing
}) => {
  const [t] = useTranslation();

  const storageUsage =
    currentUsage &&
    currentUsage.totalUsages &&
    currentUsage.totalUsages.filter(
      usage =>
        usage.type && usage.type.split(".")[1].toLowerCase() === "storage"
    );
  const transferUsage =
    currentUsage &&
    currentUsage.totalUsages &&
    currentUsage.totalUsages.filter(
      usage =>
        usage.type && usage.type.split(".")[1].toLowerCase() === "transfer"
    );

  return (
    <>
      <SummaryHeader period={currentUsage} />

      <Grid container>
        <Grid item xs={12} md={6}>
          {isLoading ? (
            <LoadingCard />
          ) : (
            <StorageCard
              title={t("usage.summary.card.header.storage")}
              helpText={t("usage.summary.card.help.storage")}
              unitType={t("usage.summary.card.used")}
              total={totalStorage}
              breakdown={storageUsage ? storageUsage : []}
              icredits={billing}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {isLoading ? (
            <LoadingCard />
          ) : (
            <StorageCard
              title={t("usage.summary.card.header.transfer")}
              unitType={t("usage.summary.card.transferred")}
              total={totalTransfer}
              breakdown={transferUsage ? transferUsage : []}
              icredits={billing}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default observer(UsageSummary);
