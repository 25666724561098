import React, { useContext } from "react";
import { observer } from "mobx-react";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core/";

import RootStoreContext from "../../contexts/RootStoreContext";
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from "@material-ui/icons/Menu";
const useStyles = makeStyles(({ palette, spacing, zIndex }: Theme) => ({
  menuButton: {
    marginRight: spacing(2)
  }
}));

const NavDrawerIconButton: React.FC = () => {
  const classes = useStyles();
  const { authStore, uiStore } = useContext(RootStoreContext);
  const handleDrawerToggle = () => {
    uiStore.drawerIsOpen ? uiStore.closeDrawer() : uiStore.openDrawer();
  };
  if (!authStore.isLoggedIn) return null;
  return (
    <Hidden smUp>
      <IconButton
        edge="start"
        className={classes.menuButton}
        color="primary"
        aria-label="Menu"
        onClick={() => handleDrawerToggle()}
      >
        <MenuIcon />
      </IconButton>
    </Hidden>
  );
};

export default observer(NavDrawerIconButton);
