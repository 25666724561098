import React from "react";
import { NavLink } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import LaunchIcon from "@material-ui/icons/Launch";

import { route, pageRef } from "../../routes";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { API_DOCS } from "../../constants";

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
  root: {
    textDecoration: "none",
    color: "inherit"
  },
  button: {
    paddingTop: 4,
    paddingBottom: 4
  },
  // style for an active nav link
  active: {
    "& span": {
      color: palette.primary.main
    }
  },
  linkText: {
    marginLeft: spacing(1)
  }
}));

interface NavDrawerLinksProps {
  renderRoutes: route[];
}

/**
 * NavDrawerLinks maps a list of route objects to react-router
 * links rendered as material-ui list items for use in a nav component
 */
const NavDrawerLinks: React.FC<NavDrawerLinksProps> = ({ renderRoutes }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <List>
      {renderRoutes.map((route, index) => {
        if (!route.displayInNav) {
          return null;
        }
        if (route.ref !== pageRef.docs) {
          return (
            <NavLink
              to={route.path}
              key={index}
              className={classes.root}
              activeClassName={classes.active}
            >
              <ListItem button key={index} className={classes.button}>
                {/* For i18n-parser, need to list all interpolations of the below:
                  t('nav.link.generatetoken')
                  t('nav.link.usage')
                  t('nav.link.servicehealth')
                  t('nav.link.docs')
              */}
                <ListItemText
                  primary={t("nav.link." + route.ref)}
                  className={classes.linkText}
                />
              </ListItem>
            </NavLink>
          );
        } else {
          // docs is not a navlink, no component attached
          return (
            <a
              href={API_DOCS}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.root}
              key={index}
            >
              <ListItem button className={classes.button}>
                <ListItemText className={classes.linkText}>
                  <>
                    {t("nav.link.docs")}&nbsp;
                    <LaunchIcon style={{ fontSize: "smaller" }} />
                  </>
                </ListItemText>
              </ListItem>
            </a>
          );
        }
      })}
    </List>
  );
};

export default NavDrawerLinks;
