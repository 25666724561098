import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles, Theme, Tooltip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import HelpIcon from "@material-ui/icons/Help";

import useGlobalStyles from "../../../styles/classes";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: "85%",
    padding: theme.spacing(2),
    paddingBottom: 0,
    marginBottom: "1rem"
  },
  title: {
    fontSize: "1rem"
  },
  emphasis: {
    fontSize: "1.8rem",
    marginBottom: "1rem"
  },
  legend: {
    ...theme.overrides!.MuiTypography!.body1,
    color: "#4F5A67",
    position: "inherit",
    fontSize: ".85rem"
  }
}));

interface SummaryCardProps {
  headline: string | JSX.Element;
  graphKey: JSX.Element;
  graph: JSX.Element;
  helpText?: string;
}

/**
 * SummaryCard is a HOC that presents usage summaries, it
 * constains the format of a summary to four components.
 * @param headline - title for this summary
 * @param graphKey - labelled key for summary graph
 * @param graph - graph as a JSX element
 * @param helpText - optional tooltip help
 */
const SummaryCard: React.FC<SummaryCardProps> = ({
  headline,
  graphKey,
  graph,
  helpText
}) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  // Format card title + optional help tooltip or render JSX as-is
  const header =
    typeof headline === "string" ? (
      <Typography variant="h3" className={classes.title} gutterBottom>
        {headline}
        {helpText && (
          <Tooltip title={helpText}>
            <HelpIcon className={globalClasses.supIcon} />
          </Tooltip>
        )}
      </Typography>
    ) : (
      headline
    );

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            {header}
          </Grid>

          <Grid item xs={12}>
            {graph}
          </Grid>

          <Grid item xs={12} style={{ minHeight: "5rem" }}>
            <section style={{ position: "inherit" }} className={classes.legend}>
              {graphKey}
            </section>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SummaryCard;
